import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Input, Select, Space, Switch, Typography } from "antd";
import { t } from "i18next";
import { RRule } from "rrule";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { ITemplateDetails } from "../../models/maintenances";
import FrequencyHandler from "./components/FrequencyHandler";
import { R_META_INFO_FREQUENCIES } from "./constants";
import { Action } from "./types";
import { useRRule } from "./useRRule";

type Props = {
  template: ITemplateDetails | undefined;
};

const RRuleComponent = ({ template }: Props) => {
  // Derived state
  const action: Action = !!template ? "update" : "create";

  // Hooks
  const rrule = useRRule();

  return (
    <div className="rrule-component" style={{ display: "flex", flexDirection: "column" }}>
      {action === "update" && (
        <Typography.Title level={5} style={{ paddingTop: 14 }}>
          {t(TRANSLATION_KEY.trigger)}
        </Typography.Title>
      )}

      {action === "update" && <Divider style={{ marginTop: 16 }} />}

      {/* Frequency */}
      <Space style={{ margin: "0px 0" }} size="middle">
        <Space>
          <Space
            style={{
              width: 280,
              marginRight: 12,
              fontWeight: 400,
              backgroundColor: "#fafafa",
              padding: "8px 16px",
              border: "1px solid #f0f0f0",
            }}
          >
            Status
          </Space>
          <Space direction="horizontal" size={12} align="center">
            <Typography.Text>Nektivno</Typography.Text>
            <Switch
              key={`checked-${template?.recurrence_enabled}`}
              checked={rrule.recurrenceMetaInformations.recurrence_enabled}
              onChange={(checked) =>
                rrule.onRecurrenceMetaInformationsChange("recurrence_enabled", checked)
              }
              style={{ marginInline: 12 }}
            />
            <Typography.Text>Aktivno</Typography.Text>
          </Space>
        </Space>
      </Space>

      {/* Frequency */}
      <div style={{ display: "flex", alignItems: "center", margin: "0px 0", width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Space
            style={{
              width: 280,
              minWidth: 280,
              marginRight: 12,
              fontWeight: 400,
              backgroundColor: "#fafafa",
              padding: "8px 16px",
              border: "1px solid #f0f0f0",
            }}
          >
            Vremenski interval
          </Space>
          <Space size={0}>
            <Button
              onClick={rrule.decrementInterval}
              shape="circle"
              type="text"
              size="small"
              icon={<MinusOutlined style={{ opacity: 0.35 }} />}
            />
            <Input
              style={{ width: 80, padding: "auto 0", textAlign: "center" }}
              value={rrule.rulesets[0].options.interval}
              bordered={false}
              onChange={(e) => rrule.setInterval(+e.target.value)}
            />
            <Button
              onClick={rrule.incrementInterval}
              shape="circle"
              type="text"
              size="small"
              icon={<PlusOutlined style={{ opacity: 0.35 }} />}
            />
          </Space>
          <Select
            style={{ width: "100%", minWidth: 120 }}
            onChange={(item) => rrule.setFrequency(+item)}
            placeholder="Select interval"
            defaultValue={rrule.defaultValues.freq}
            bordered={false}
          >
            {rrule.selectFreq.map((item, index) => (
              <Select.Option key={index}>{t(rrule.parsedFreq[item])}</Select.Option>
            ))}
          </Select>
        </div>
      </div>

      {/* Precision */}
      <Space
        direction={rrule.rulesets[0].options.freq === RRule.WEEKLY ? "vertical" : "horizontal"}
        style={{ margin: "0px 0" }}
      >
        <Space
          style={{
            width: 280,
            marginRight: 12,
            fontWeight: 400,
            backgroundColor: "#fafafa",
            padding: "8px 16px",
            border: "1px solid #f0f0f0",
          }}
        >
          Vremenska preciznost
        </Space>
        <FrequencyHandler freq={rrule.rulesets[0].options.freq} rrule={rrule} />
      </Space>

      {/* Dtstart */}
      <div style={{ display: "flex", alignItems: "center", margin: "0px 0", width: "100%" }}>
        <Space
          style={{
            width: 280,
            minWidth: 280,
            marginRight: 12,
            fontWeight: 400,
            backgroundColor: "#fafafa",
            padding: "8px 16px",
            border: "1px solid #f0f0f0",
          }}
        >
          Početak ponavljanja
        </Space>
        <DatePicker
          defaultValue={rrule.defaultValues.dtstart}
          format={rrule.dateFormat}
          onChange={(date) => {
            if (date) {
              rrule.setDtstart(date?.toDate());
            } else {
              rrule.resetDtstart();
            }
          }}
          style={{ minWidth: 258, width: "100%" }}
          allowClear={false}
          bordered={false}
        />
      </div>

      {/* Until */}
      {/* <Space style={{ margin: "0px 0" }}>
        <Space
          style={{
            width: 280,
            marginRight: 12,
            fontWeight: 400,
            backgroundColor: "#fafafa",
            padding: "8px 16px",
            border: "1px solid #f0f0f0",
          }}
        >
          Završetak ponavljanja
        </Space>
        <DatePicker
          defaultValue={rrule.defaultValues.until}
          format={rrule.dateFormat}
          onChange={(date) => {
            if (date) {
              rrule.setUntil(date?.toDate());
            } else {
              rrule.resetUntil();
            }
          }}
          style={{ width: 240 }}
          bordered
        />
      </Space> */}

      {/* Trigger before */}
      <div
        style={{
          margin: "0px 0",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Space
            style={{
              width: 280,
              minWidth: 280,
              marginRight: 12,
              fontWeight: 400,
              backgroundColor: "#fafafa",
              padding: "8px 16px",
              border: "1px solid #f0f0f0",
            }}
          >
            Stvoriti prije planiranog početka
          </Space>
          <Space size={0}>
            <Button
              onClick={() =>
                rrule.onEveryPeriodFieldsChange(
                  "every",
                  rrule.recurrenceMetaInformations.trigger_before_every - 1,
                  "recurrence_trigger_before_seconds",
                )
              }
              shape="circle"
              type="text"
              size="small"
              icon={<MinusOutlined style={{ opacity: 0.35 }} />}
            />
            <Input
              style={{ width: 80, padding: "auto 0", textAlign: "center" }}
              value={rrule.recurrenceMetaInformations.trigger_before_every}
              bordered={false}
              onChange={(e) =>
                rrule.onEveryPeriodFieldsChange(
                  "every",
                  +e.target.value,
                  "recurrence_trigger_before_seconds",
                )
              }
            />
            <Button
              onClick={() =>
                rrule.onEveryPeriodFieldsChange(
                  "every",
                  rrule.recurrenceMetaInformations.trigger_before_every + 1,
                  "recurrence_trigger_before_seconds",
                )
              }
              shape="circle"
              type="text"
              size="small"
              icon={<PlusOutlined style={{ opacity: 0.35 }} />}
            />
          </Space>
          <Select
            style={{ width: "100%", minWidth: 120 }}
            onChange={(item) =>
              rrule.onEveryPeriodFieldsChange(
                "period",
                R_META_INFO_FREQUENCIES[item],
                "recurrence_trigger_before_seconds",
              )
            }
            placeholder="Select recurrences trigger"
            defaultValue={rrule.recurrenceMetaInformations.trigger_before_period}
            value={t(rrule.recurrenceMetaInformations.trigger_before_period)}
            bordered={false}
          >
            {R_META_INFO_FREQUENCIES.map((item, index) => (
              <Select.Option key={index}>{t(item)}</Select.Option>
            ))}
          </Select>
        </div>
      </div>

      {/* Deadline */}
      {/* <Space style={{ margin: "0px 0" }} size="middle">
        <Space>
          <Space
            style={{
              width: 280,
              marginRight: 12,
              fontWeight: 400,
              backgroundColor: "#fafafa",
              padding: "8px 16px",
              border: "1px solid #f0f0f0",
            }}
          >
            Kranji rok izvršenja
          </Space>
          <Space size={0}>
            <Button
              onClick={() =>
                rrule.onEveryPeriodFieldsChange(
                  "every",
                  rrule.deadline.every - 1,
                  "deadline_seconds",
                )
              }
              shape="circle"
              type="text"
              size="small"
              icon={<MinusOutlined style={{ opacity: 0.35 }} />}
            />
            <Input
              style={{ width: 80, padding: "auto 0", textAlign: "center" }}
              value={rrule.deadline.every}
              bordered={false}
              onChange={(e) =>
                rrule.onEveryPeriodFieldsChange("every", +e.target.value, "deadline_seconds")
              }
            />
            <Button
              onClick={() =>
                rrule.onEveryPeriodFieldsChange(
                  "every",
                  rrule.deadline.every + 1,
                  "deadline_seconds",
                )
              }
              shape="circle"
              type="text"
              size="small"
              icon={<PlusOutlined style={{ opacity: 0.35 }} />}
            />
          </Space>
          <Select
            style={{ width: 180 }}
            onChange={(item) =>
              rrule.onEveryPeriodFieldsChange(
                "period",
                R_META_INFO_FREQUENCIES[item],
                "deadline_seconds",
              )
            }
            placeholder="Select recurrences deadline"
            defaultValue={rrule.deadline.period}
            value={t(rrule.deadline.period)}
          >
            {R_META_INFO_FREQUENCIES.map((item, index) => (
              <Select.Option key={index}>{t(item)}</Select.Option>
            ))}
          </Select>
        </Space>
      </Space> */}
    </div>
  );
};

export default RRuleComponent;
