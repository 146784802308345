import { message } from "antd";
import { t } from "i18next";
import moment from "moment";
import { type PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";
import { Options, RRule } from "rrule";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ITemplateDetails } from "../../models/maintenances";
import { updateTemplateDetailsXHR } from "../../store/reducers/maintenance/actionCreator";
import {
  INITIAL_TIMESTAMP,
  initialTriggerBefore,
  initialWeekTimestamps,
  parsedFreq,
  weekDays,
} from "./constants";
import {
  Action,
  Days,
  DefaultValues,
  MonthlySetup,
  RecurrenceMetaInformations,
  Rule,
  Timestamps,
  TriggerBefore,
  UpdateTemplateRRule,
  WeekTimestamps,
  YearlySetup,
} from "./types";
import {
  calculateHoursFromTimeString,
  calculateMinutesFromTimeString,
  calculateSecondsFromEveryAndPeriod,
  deletingLastTimestampFromDay,
  deletingLastWeekTimestamp,
  findDayWithInitialTimestamp,
  getByhour,
  parseByhour,
  parseByminute,
  parseRRuleString,
  removeHoursFromDateFormat,
  setInitialTimestamps,
  setInitialWeekTimestamps,
  setRule,
  weekDayHasTimestamps,
  weekHasNoTimestamps,
} from "./utils";

export const RRuleContextProvider: React.FC<
  PropsWithChildren<{ template: ITemplateDetails | undefined }>
> = ({ template, children }) => {
  // Hooks
  const dispatch = useAppDispatch();

  // Derived state
  const action: Action = template?.id ? "update" : "create";

  // State
  const userFormat =
    useAppSelector((state) => state.userReducer.user.account.date_format) || "DD.MM.YYYY - HH:mm";
  const dateFormat = removeHoursFromDateFormat(userFormat);

  // Constants
  // const until = moment().add(30, "days").toDate();

  // Initial values
  const initialRule: Rule = {
    freq: RRule.DAILY,
    interval: 1,
    bysecond: 0,
    dtstart: new Date(),
    byminute: parseByminute(calculateMinutesFromTimeString(INITIAL_TIMESTAMP)),
    byhour: parseByhour(calculateHoursFromTimeString(INITIAL_TIMESTAMP)),
    //! If freq is RRule.DAILY => byweekday, bymonthday, bymonth property should not exist
    // byweekday: findDayWithInitialTimestamp(initialWeekTimestamps), //? If initial frequency is weekly
    // byweekday: [RRule.MO.nth(3)],                                  //? If initial frequency is monthly - WeeklyDate rule
    // bymonthday: 1,                                                 //? If initial frequency is monthly - SpecificDate rule
    // bymonth: 1,                                                    //? If initial frequency is yearly  - SpecificDate rule
  };
  const initialRules: Rule[] = useMemo(() => setRule(template, initialRule), []);

  // Variables
  const [rules, setRules] = useState<Rule[]>(initialRules);
  const [timestamps, setTimestamps] = useState<Timestamps>(setInitialTimestamps(initialRules));
  const [weekTimestamps, setWeekTimestamps] = useState<WeekTimestamps>(
    setInitialWeekTimestamps(template, rules, initialWeekTimestamps),
  );
  const [weekdayNth, setWeekdayNth] = useState<number>(1);
  const [weekdayDay, setWeekdayDay] = useState<number>(RRule.MO.weekday);
  const freq = [RRule.YEARLY, RRule.MONTHLY, RRule.WEEKLY, RRule.DAILY];
  // const [deadline, setDeadline] = useState<Deadline>(template.deadline || initialDeadline);

  const recurrence_trigger_before_seconds =
    template?.recurrence_trigger_before_seconds ||
    calculateSecondsFromEveryAndPeriod(initialTriggerBefore.every, initialTriggerBefore.period);
  // const deadline_seconds = calculateSecondsFromEveryAndPeriod(
  //   initialDeadline.every,
  //   initialDeadline.period,
  // );
  const [recurrenceMetaInformations, setRecurrenceMetaInformations] =
    useState<RecurrenceMetaInformations>({
      recurrence_enabled: template?.recurrence_enabled || false,
      recurrence_trigger_before_seconds,
      trigger_before_every: template?.recurrence_meta.trigger_before_every || 0,
      trigger_before_period: template?.recurrence_meta.trigger_before_period || "days",
      rule_type: template?.recurrence_meta.rule_type || "SpecificDate",
    });

  // Default values
  const defaultValues: DefaultValues = {
    freq: t(parsedFreq[rules[0].freq!]),
    dtstart: moment(rules[0].dtstart, dateFormat),
    // until: moment(rules[0].until, dateFormat),
    interval: rules[0].interval,
  };

  // Effects
  useUpdateEffect(() => {
    // Not saving data on create action
    if (action === "create") {
      return;
    }

    // Update template on rule change
    let recurrence_rule = parseFormRule();

    let { recurrence_enabled, recurrence_trigger_before_seconds, ...meta } =
      recurrenceMetaInformations;

    let body: UpdateTemplateRRule = {
      recurrence: {
        rule: recurrence_rule,
        enabled: recurrence_enabled,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dtstart: rules[0].dtstart as any,
        // TODO: Revise this part
        trigger_before_seconds: calculateSecondsFromEveryAndPeriod(
          meta.trigger_before_every,
          meta.trigger_before_period,
        ),
        meta: meta as RecurrenceMetaInformations,
      },
    };

    updateTemplateDetailsXHR(
      {
        id: template?.id,
        body,
        errorCallback: (error) => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback(data) {
          const [dtstart, rules] = parseRRuleString(
            data.results!.recurrence_rule!,
            data.results!.recurrence_dtstart,
          );

          if (rules[0].options.freq === RRule.WEEKLY) {
            setWeekTimestamps(getByhour(rules.map((item) => item.options)));
            return;
          }

          setTimestamps(parseByhour(rules[0].options.byhour));
        },
      },
      dispatch,
    );
  }, [rules, action]);

  // Methods
  const addRule = (options?: Rule) => {
    setRules([...rules, { ...rules[0], ...options }]);
  };

  const removeRule = (index: number) => {
    const updatedRules = [...rules];
    updatedRules.splice(index, 1);
    setRules(updatedRules);
  };

  const parseFormRule = () => {
    let parsed = [...rules].map((rule) => {
      let { dtstart, ...rest } = rule;
      return rest;
    });
    return parsed
      .map(({ bynweekday, ...rest }) => new RRule(rest))
      .map((rule) => rule.toString())
      .join("\n");
  };

  const setupFrequency = (index: number) => {
    // Reinitializing state
    setTimestamps(parseByhour(initialRule.byhour));
    setWeekdayDay(RRule.MO.weekday);
    setWeekdayNth(1);
    // If switching to daily mode, keep only the first rule and reset byweekday and byminute
    if (freq[index] === RRule.DAILY) {
      setRules((prevRules) => {
        const updatedRules = prevRules.slice(0, 1);
        return updatedRules.map((rule) => ({
          ...rule,
          freq: freq[index],
          byhour: initialRule.byhour,
          byminute: initialRule.byminute,
          byweekday: undefined,
          bymonthday: undefined,
          bymonth: undefined,
        }));
      });
    } else if (freq[index] === RRule.WEEKLY) {
      // If switching to weekly mode, reset byweekday and byminute and keep the first rule's interval, until, and dtstart
      setWeekTimestamps(initialWeekTimestamps);
      setRules((prevRules) => [
        {
          ...initialRule,
          freq: freq[index],
          byweekday: findDayWithInitialTimestamp(initialWeekTimestamps),
          interval: prevRules[0].interval,
          // until: prevRules[0].until,
          dtstart: prevRules[0].dtstart,
          bymonthday: undefined,
          bymonth: undefined,
        },
      ]);
    } else if (freq[index] === RRule.MONTHLY) {
      // If switching to monthly mode, reset byweekday and byminute and keep the first rule's interval, until, and dtstart
      setRules((prevRules) => [
        {
          ...initialRule,
          freq: freq[index],
          interval: prevRules[0].interval,
          // until: prevRules[0].until,
          dtstart: prevRules[0].dtstart,
          bymonthday: 1,
          bymonth: undefined,
        },
      ]);
    } else if (freq[index] === RRule.YEARLY) {
      setRules((prevRules) => [
        {
          ...initialRule,
          freq: freq[index],
          interval: prevRules[0].interval,
          // until: prevRules[0].until,
          dtstart: prevRules[0].dtstart,
          bymonthday: 1,
          bymonth: 1,
        },
      ]);
    }
  };

  const setFrequency = (freqIndex: number) => {
    const reversedIndex = freq.length - 1 - freqIndex;
    setupFrequency(reversedIndex);
  };

  const setInterval = (interval: number) => {
    if (typeof interval === "number") {
      const updatedRules = rules.map((rule) => ({
        ...rule,
        interval: interval <= 0 ? 1 : interval,
      }));
      setRules(updatedRules);
    }
  };

  const incrementInterval = () => {
    const updatedRules = rules.map((rule) => {
      if (rule.interval) {
        return { ...rule, interval: rule.interval + 1 };
      }
      return rule;
    });
    // Save freq_interval to recurrenceMetaInformations
    setRules(updatedRules);
  };

  const decrementInterval = () => {
    const updatedRules = rules.map((rule) => {
      if (rule.interval && rule.interval > 1) {
        return { ...rule, interval: rule.interval - 1 };
      }
      return rule;
    });
    // Save freq_interval to recurrenceMetaInformations
    setRules(updatedRules);
  };

  const setDtstart = (dtstart: Date) => {
    const updatedRules = rules.map((rule) => ({ ...rule, dtstart }));
    setRules(updatedRules);
  };

  const resetDtstart = () => {
    const updatedRules = rules.map((rule) => ({ ...rule, dtstart: initialRule.dtstart }));
    setRules(updatedRules);
  };

  const setUntil = (until: Date) => {
    const updatedRules = rules.map((rule) => ({ ...rule, until }));
    setRules(updatedRules);
  };

  const resetUntil = () => {
    const updatedRules = rules.map((rule) => ({ ...rule, until: initialRule.until }));
    setRules(updatedRules);
  };

  const monthlySetup = (props: MonthlySetup) => {
    if (props.type === "SpecificDate") {
      // Update rules and remove byweekday property
      setWeekdayDay(0);
      setWeekdayNth(1);
      setRules((prevRules) => {
        const updatedRules = prevRules.map((rule) => {
          const { byweekday, ...rest } = rule;
          return {
            ...rest,
            freq: RRule.MONTHLY,
            bymonthday: props.bymonthday || rest.bymonthday || 1,
            byhour: props.byhour || rest.byhour || initialRule.byhour,
            byminute: props.byminute || rest.byminute || initialRule.byminute,
          };
        });
        return updatedRules;
      });
    } else if (props.type === "WeeklyDate") {
      // Update rules and remove bymonthday property
      let byweekday: Options["byweekday"] =
        props.byweekday === 0
          ? [RRule.MO.nth(props.nth || weekdayNth)]
          : [weekDays[props.byweekday || weekdayDay].nth(props.nth || weekdayNth)];
      setRules((prevRules) => {
        const updatedRules = prevRules.map((rule) => {
          const { bymonthday, ...rest } = rule;
          return {
            ...rest,
            freq: RRule.MONTHLY,
            byweekday,
            byhour: props.byhour || rest.byhour || initialRule.byhour,
            byminute: props.byminute || rest.byminute || initialRule.byminute,
          };
        });
        return updatedRules;
      });
    }
  };

  const yearlySetup = (props: YearlySetup) => {
    if (props.type === "SpecificDate") {
      setWeekdayDay(0);
      setWeekdayNth(1);
      setRules((prevRules) => {
        const updatedRules = prevRules.map((rule) => {
          const { byweekday, ...rest } = rule;
          return {
            ...rest,
            freq: RRule.YEARLY,
            bymonth: props.bymonth || rest.bymonth || 1,
            bymonthday: props.bymonthday || rest.bymonthday || 1,
            byhour: props.byhour || rest.byhour || initialRule.byhour,
            byminute: props.byminute || rest.byminute || initialRule.byminute,
          };
        });
        return updatedRules;
      });
    } else if (props.type === "YearlyDate") {
      let byweekday: Options["byweekday"] =
        props.byweekday === 0
          ? [RRule.MO.nth(props.nth || weekdayNth)]
          : [weekDays[props.byweekday || weekdayDay].nth(props.nth || weekdayNth)];
      setRules((prevRules) => {
        const updatedRules = prevRules.map((rule) => {
          const { bymonthday, ...rest } = rule;
          return {
            ...rest,
            freq: RRule.YEARLY,
            byweekday,
            bymonth: props.bymonth || rest.bymonth || 1,
            byhour: props.byhour || rest.byhour || initialRule.byhour,
            byminute: props.byminute || rest.byminute || initialRule.byminute,
          };
        });
        return updatedRules;
      });
    }
  };

  const setWeekTime = (timestamp: string, timestampIndex: number, currentDay: Days = 0) => {
    let timestamps: WeekTimestamps = { ...weekTimestamps };
    let rulesList: Rule[] = [...rules];

    // Initial timestamp
    if (weekHasNoTimestamps(weekTimestamps)) {
      // Adding new timestamp
      timestamps[currentDay] = [calculateHoursFromTimeString(timestamp)];
      setWeekTimestamps(timestamps);
      // Setting rules
      let newRules: Rule[] = rulesList;
      newRules[0] = {
        ...rulesList[0],
        byhour: timestamps[currentDay],
        byweekday: weekDays[currentDay].weekday,
      };
      setRules(newRules);
    } else {
      // Adding new timestamp to day that has timestamps
      if (weekDayHasTimestamps(weekTimestamps, currentDay)) {
        let existingTimestamps = timestamps[currentDay];
        let newTimestamps: Timestamps = [...existingTimestamps!];
        newTimestamps[timestampIndex] = calculateHoursFromTimeString(timestamp);
        timestamps[currentDay] = newTimestamps;
        setWeekTimestamps(timestamps);
        // Setting rules
        let newRules: Rule[] = [...rulesList];
        let ruleIndex = rulesList.findIndex(
          (rule) => rule.byweekday === weekDays[currentDay].weekday,
        );
        if (ruleIndex !== -1) {
          newRules[ruleIndex] = {
            ...rulesList[ruleIndex],
            byhour: timestamps[currentDay],
          };
        }
        setRules(newRules);
      }
      // Adding new timestamp to day that has no timestamps - creating new rule
      else {
        // Creating new rule
        let newTimestamps: Timestamps = [calculateHoursFromTimeString(timestamp)];
        timestamps[currentDay] = newTimestamps;
        setWeekTimestamps(timestamps);

        // Adding new rule
        addRule({ byweekday: weekDays[currentDay].weekday, byhour: timestamps[currentDay] });
      }
    }
  };

  const clearWeekTime = (timestampIndex: number, day: Days) => {
    let timestamps: WeekTimestamps = { ...weekTimestamps };
    let timestampsOfSelectedDay = timestamps[day];

    // Deleting last timestamp from week
    if (deletingLastWeekTimestamp(timestamps)) {
      setupFrequency(RRule.WEEKLY);
      return;
    }

    // Deleting last timestamp from selected day
    if (deletingLastTimestampFromDay(timestamps, day)) {
      // Update weekTimestamps
      timestampsOfSelectedDay = [];
      timestamps[day] = timestampsOfSelectedDay;
      setWeekTimestamps(timestamps);
      // Remove rule
      let newRules: Rule[] = [...rules];
      let ruleIndex = newRules.findIndex((rule) => rule.byweekday === weekDays[day].weekday);
      if (ruleIndex !== -1) {
        newRules.splice(ruleIndex, 1);
      }
      setRules(newRules);
      return;
    }

    // Deleting timestamp from selected day
    if (timestampsOfSelectedDay) {
      timestampsOfSelectedDay.splice(timestampIndex, 1);
      timestamps[day] = timestampsOfSelectedDay;
      setWeekTimestamps(timestamps);
      // Update rule
      let newRules: Rule[] = [...rules];
      let ruleIndex = newRules.findIndex((rule) => rule.byweekday === weekDays[day].weekday);
      if (ruleIndex !== -1) {
        newRules[ruleIndex] = { ...newRules[ruleIndex], byhour: timestamps[day] };
      }
      setRules(newRules);
    }
  };

  const setTime = (time: string, index?: number | undefined, ruleIndex: number = 0) => {
    let rule: Rule = rules[ruleIndex];
    let newRules: Rule[] = [...rules];
    let hours = rule.byhour;
    let newTimestamps: Timestamps = [...(timestamps as any)];
    let calculatedHours = calculateHoursFromTimeString(time);
    // Creating or updating new timestamps
    if (hours) {
      let tmpHours: any;
      // Updating
      if (index !== undefined) {
        let m = parseByhour(hours) as number[];
        m.splice(index, 1, calculatedHours);
        tmpHours = `${m}`.split(",").map((item) => Number(item));
      }
      // Creating
      else {
        tmpHours = `${rule.byhour},${calculatedHours}`.split(",").map((item) => Number(item));
      }
      // Setting state
      rule = { ...rule, byhour: tmpHours };
      newRules[ruleIndex] = rule;
      newTimestamps = tmpHours;
      setRules(newRules);
      setTimestamps(newTimestamps);
    }
    // Creating first timestamp
    else {
      let tmpHours = [calculatedHours];
      rule = { ...rule, byhour: tmpHours };
      newRules[ruleIndex] = rule;
      newTimestamps = tmpHours;
      setRules(newRules);
      setTimestamps(newTimestamps);
    }
  };

  const clearTime = (index: number, ruleIndex: number = 0) => {
    let rule: Rule = rules[ruleIndex];
    let newRules: Rule[] = [...rules];
    let newTimestamps: Timestamps = [...(timestamps as any)];

    let hours = rule.byhour;

    if (hours) {
      let m = parseByhour(hours) as number[];
      m.splice(index, 1);
      let byhour = parseByhour(m);
      newRules[ruleIndex] = { ...rule, byhour };
      newTimestamps = byhour;
      setRules(newRules);
      setTimestamps(newTimestamps);
    }
  };

  const updateTemplateRRule = (data: Partial<UpdateTemplateRRule["recurrence"]>) => {
    if (!template) {
      return;
    }
    let body: UpdateTemplateRRule = {} as UpdateTemplateRRule;

    // Update rrule first time - after creating template without recurrence rule
    if (!template?.recurrence_rule) {
      let recurrence_rule = parseFormRule();
      let { recurrence_enabled, recurrence_trigger_before_seconds, ...meta } =
        recurrenceMetaInformations;

      body = {
        recurrence: {
          rule: recurrence_rule,
          enabled: recurrence_enabled,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          dtstart: rules[0].dtstart as any,
          trigger_before_seconds: recurrence_trigger_before_seconds,
          meta: meta as RecurrenceMetaInformations,
          ...data,
        },
      };
    } else {
      body = {
        recurrence: {
          rule: template.recurrence_rule,
          enabled: template.recurrence_enabled,
          timezone: template.recurrence_timezone,
          dtstart: template.recurrence_dtstart,
          trigger_before_seconds: template.recurrence_trigger_before_seconds,
          meta: template.recurrence_meta,
          ...data,
        },
      };
    }

    updateTemplateDetailsXHR(
      {
        id: template?.id,
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        body,
      },
      dispatch,
    );
  };

  function onRecurrenceMetaInformationsChange(
    key: keyof RecurrenceMetaInformations,
    value: string | boolean | number | null,
    skipUpdateTemplate: boolean = false,
  ) {
    let newMetaInformations = {
      ...recurrenceMetaInformations,
      [key]: value,
    } as RecurrenceMetaInformations;
    setRecurrenceMetaInformations(newMetaInformations);

    if (!skipUpdateTemplate) {
      updateTemplateRRule({
        ...(key === "recurrence_enabled" ? { enabled: value as boolean } : {}),
        ...(key === "recurrence_trigger_before_seconds"
          ? { trigger_before_seconds: value as number }
          : {}),
        meta: newMetaInformations,
      });
    }
  }

  function onEveryPeriodFieldsChange(
    key: keyof TriggerBefore,
    value: string | number | null,
    type: keyof Pick<RecurrenceMetaInformations, "recurrence_trigger_before_seconds">,
  ) {
    let newSeconds = 0;
    let newValue = value;
    let every = recurrenceMetaInformations.trigger_before_every;
    let period = recurrenceMetaInformations.trigger_before_period;
    let triggerBeforeType: "trigger_before_every" | "trigger_before_period" =
      key === "every" ? "trigger_before_every" : "trigger_before_period";

    if (key === "every") {
      if (Number(newValue) < 0) {
        newValue = 0;
      }
      every = newValue as number;
      period = recurrenceMetaInformations.trigger_before_period;
      newSeconds = calculateSecondsFromEveryAndPeriod(every, period);
    } else if (key === "period") {
      every = recurrenceMetaInformations.trigger_before_every;
      period = newValue as string;
      newSeconds = calculateSecondsFromEveryAndPeriod(every, period);
    }

    if (type === "recurrence_trigger_before_seconds") {
      // Update recurrenceMetaInformations
      onRecurrenceMetaInformationsChange("recurrence_trigger_before_seconds", newSeconds, true);
      onRecurrenceMetaInformationsChange(triggerBeforeType, newValue, true);

      // Update template
      updateTemplateRRule({
        trigger_before_seconds: newSeconds,
        meta: {
          ...recurrenceMetaInformations,
          recurrence_trigger_before_seconds: newSeconds,
          [triggerBeforeType]: newValue,
        },
      });
    } else {
      // // Update deadline
      // setDeadline((previousValue) => ({
      //   ...previousValue,
      //   [key]: newValue,
      // }));
      // // Update template
      // updateTemplateRRule({
      //   deadline_seconds: newSeconds,
      //   deadline: {
      //     ...deadline,
      //     [key]: newValue,
      //   },
      // });
    }

    // Update template
  }

  function handleRRuleOnNewTemplate() {
    let recurrence_rule = parseFormRule();
    let { recurrence_enabled, recurrence_trigger_before_seconds, ...meta } =
      recurrenceMetaInformations;

    let trigger_before_seconds = calculateSecondsFromEveryAndPeriod(
      meta.trigger_before_every as number,
      meta.trigger_before_period as string,
    );
    let body: UpdateTemplateRRule = {
      recurrence: {
        rule: recurrence_rule,
        enabled: recurrence_enabled,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dtstart: rules[0].dtstart as any,
        trigger_before_seconds: trigger_before_seconds ?? recurrence_trigger_before_seconds,
        meta: meta as RecurrenceMetaInformations,
      },
    };
    return body;
  }

  return (
    <RRuleContext.Provider
      value={{
        // Getters
        action,
        rulesets: rules.map((rule) => new RRule(rule)),
        defaultValues,
        dateFormat,
        freq,
        parsedFreq,
        selectFreq: [...freq].reverse(),
        timestamps,
        weekTimestamps,
        weekdayNth,
        weekdayDay,
        recurrenceMetaInformations,
        // deadline,
        // triggerBefore,
        // Setters
        addRule,
        removeRule,
        setFrequency,
        parseFormRule,
        setInterval,
        incrementInterval,
        decrementInterval,
        setDtstart,
        resetDtstart,
        setUntil,
        resetUntil,
        setWeekTime,
        clearWeekTime,
        setTime,
        clearTime,
        monthlySetup,
        setWeekdayNth,
        setWeekdayDay,
        yearlySetup,
        updateTemplateRRule,
        onRecurrenceMetaInformationsChange,
        onEveryPeriodFieldsChange,
        handleRRuleOnNewTemplate,
      }}
    >
      {children}
    </RRuleContext.Provider>
  );
};

// #region Context

export type RRuleContextType = {
  action: Action;
  rulesets: RRule[];
  defaultValues: DefaultValues;
  dateFormat: string;
  freq: number[];
  parsedFreq: Record<number, string>;
  selectFreq: number[];
  timestamps: Timestamps[] | Timestamps;
  weekTimestamps: WeekTimestamps;
  weekdayNth: number;
  weekdayDay: number;
  recurrenceMetaInformations: RecurrenceMetaInformations;
  // deadline: Deadline;
  // triggerBefore: TriggerBefore;
  addRule: (options?: Rule) => void;
  removeRule: (index: number) => void;
  setFrequency: (freqIndex: number) => void;
  parseFormRule: () => string;
  setInterval: (interval: number) => void;
  incrementInterval: () => void;
  decrementInterval: () => void;
  setDtstart: (dtstart: Date) => void;
  resetDtstart: () => void;
  setUntil: (until: Date) => void;
  resetUntil: () => void;
  setWeekTime: (timestamp: string, timestampIndex: number, currentDay: Days) => void;
  clearWeekTime: (timestampIndex: number, day: Days) => void;
  setTime: (time: string, index?: number | undefined, ruleIndex?: number) => void;
  clearTime: (index: number, ruleIndex?: number) => void;
  monthlySetup: (props: MonthlySetup) => void;
  setWeekdayNth: (nth: number) => void;
  setWeekdayDay: (day: number) => void;
  yearlySetup: (props: YearlySetup) => void;
  updateTemplateRRule: (data: Partial<UpdateTemplateRRule["recurrence"]>) => void;
  onRecurrenceMetaInformationsChange: (
    key: keyof RecurrenceMetaInformations,
    value: string | boolean | number | null,
    skipUpdateTemplate?: boolean,
  ) => void;
  onEveryPeriodFieldsChange: (
    key: keyof TriggerBefore,
    value: string | number | null,
    type: keyof Pick<RecurrenceMetaInformations, "recurrence_trigger_before_seconds">,
  ) => void;
  handleRRuleOnNewTemplate: () => UpdateTemplateRRule;
};

export const RRuleContext = createContext<RRuleContextType>({} as RRuleContextType);

export const useRRule = () => {
  const context = useContext(RRuleContext);
  if (!context) {
    throw new Error("useRRuleContext must be used within a ColorModeContextProvider");
  }
  return context;
};

// #endregion
