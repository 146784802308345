import React, { useEffect, useMemo, useState } from "react";

// Styles
import { Divider, Input, List, Select, Space, Typography } from "antd";
import styles from "../styles.module.css";
import { motion } from "framer-motion";

// Calendar
import { Draggable } from "@fullcalendar/interaction";

// Translation
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import {
  IMaintenance,
  IMaintenanceCategory,
  IMaintenanceType,
  MaintenanceEvent,
  MaintenanceStatusTypes,
} from "../../../../models/maintenances";
import { DefaultOptionType } from "antd/lib/select";
import {
  debounce,
  filter,
  getCategories,
  getStatuses,
  getTypes,
} from "../../../../helpers/functions";
import { IFilters } from "..";

interface IProps {
  dropEvents: IMaintenance[];
  set_dropEventsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialFilters: Partial<IFilters> = {
  maintenance_type: [],
  maintenance_category: [],
  statuses: [],
};

const DropEvents: React.FC<IProps> = ({ dropEvents, set_dropEventsVisible }) => {
  // Variables
  const types = getTypes(dropEvents);
  const categories = getCategories(dropEvents);
  const statuses = getStatuses(dropEvents);
  const [search, set_search] = useState<string>("");
  const [filters, set_filters] = useState<Partial<IFilters>>(initialFilters);

  // Methods
  useEffect(() => {
    let draggableEl: HTMLElement = document.getElementById("external-events")!;
    const draggable = new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: (eventEl) => {
        return {
          title: eventEl.getAttribute("title"),
          id: eventEl.getAttribute("key"),
          backgroundColor: eventEl.getAttribute("backgroundColor"),
          create: false,
        };
      },
    });
    return () => draggable.destroy();
  }, []);

  const setFilters = (value: any, key: keyof IFilters) => {
    const _key = key as keyof typeof filters;
    const _filters: Partial<IFilters> = { ...filters };
    _filters[_key] = value;
    set_filters(_filters);
  };

  const debounceOnSearch = (value: string) => set_search(value);
  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);

  let filteredEvents = useMemo(() => {
    return filter(dropEvents as unknown as MaintenanceEvent[], filters, initialFilters);
  }, [dropEvents, filters, initialFilters]);

  if (search !== "") {
    filteredEvents = filteredEvents.filter((event) =>
      // @ts-ignore
      event.description.toLowerCase().includes(search.toLowerCase()),
    );
  }

  return (
    <div id="external-events">
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2, ease: "anticipate" }}
        className={styles.dropEventsContainer}
      >
        <Input
          placeholder={t(TRANSLATION_KEY.searchByName)}
          style={{ marginBottom: 12, width: 320 }}
          onChange={(e) => setSearch(e.target.value)}
        />

        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 4 }}
          transition={{ duration: 0.3, ease: "anticipate" }}
          className={styles.dropEventsHeader}
        >
          {/* Filters */}
          <Select
            onChange={(e) => setFilters(e, "maintenance_type")}
            placeholder={t(TRANSLATION_KEY.maintenanceTypes)}
            defaultValue={[]}
            style={{ width: "30%", overflow: "auto" }}
            allowClear={true}
            mode="multiple"
            maxTagCount="responsive"
            showSearch
            optionFilterProp="name"
            filterOption={(input, option: DefaultOptionType | any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {types.map((item: IMaintenanceType, index: number) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>

          <Select
            onChange={(e) => setFilters(e, "maintenance_category")}
            placeholder={t(TRANSLATION_KEY.selectCategory)}
            defaultValue={[]}
            style={{ width: "30%", overflow: "auto" }}
            allowClear={true}
            mode="multiple"
            maxTagCount="responsive"
            showSearch
            optionFilterProp="name"
            filterOption={(input, option: DefaultOptionType | any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categories.map((item: IMaintenanceCategory, index: number) => (
              <Select.Option key={index} value={item.id}>
                {t(item.trans_key || item.name)}
              </Select.Option>
            ))}
          </Select>

          <Select
            onChange={(e) => setFilters(e, "statuses")}
            placeholder={t(TRANSLATION_KEY.selectStatus)}
            defaultValue={[]}
            style={{ width: "30%", overflow: "auto" }}
            allowClear={true}
            mode="multiple"
            maxTagCount="responsive"
            showSearch
            optionFilterProp="name"
            filterOption={(input, option: DefaultOptionType | any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {statuses.map((item: MaintenanceStatusTypes, index: number) => (
              <Select.Option key={index} value={item}>
                {t(item)}
              </Select.Option>
            ))}
          </Select>
        </motion.div>

        <Divider style={{ margin: "12px 0", marginTop: 16 }} />
        <motion.div layout className={styles.dropEventsListContainer}>
          <List
            itemLayout="horizontal"
            size="small"
            dataSource={filteredEvents}
            renderItem={(item, index) => (
              <motion.div
                className={`fc-event ${styles.dropEventItem}`}
                id={item.id?.toString()}
                // @ts-ignore

                title={item.description}
                key={item.id}
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 4 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 + index * 0.05, ease: "anticipate" }}
                style={{
                  cursor: "move",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                  {/* Order number and maintenance type */}
                  <Space>
                    <Typography.Text style={{ width: 32 }}>#{item.order_number}</Typography.Text>
                    <Typography.Text type="secondary">
                      {item.maintenance_type?.name}
                    </Typography.Text>
                  </Space>
                  {/* Name */}
                  <Typography.Text strong style={{ marginBottom: 12 }}>
                    {/* @ts-ignore */}
                    {item.description}
                  </Typography.Text>
                  {/* Additional informations */}
                  <div className="spaceBetweenRow" style={{ alignItems: "flex-start" }}>
                    <Space align="start">
                      <Space direction="vertical" size={0}>
                        <Typography.Text style={{ fontSize: 14, marginBottom: -6 }}>
                          {item.asset?.name}
                        </Typography.Text>
                        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                          {item.location?.name}
                        </Typography.Text>
                      </Space>
                    </Space>

                    <Space align="start">
                      <Space direction="vertical" size={0}>
                        <Typography.Text
                          style={{ fontSize: 14, marginBottom: -6, textAlign: "right" }}
                        >
                          {t(item.status)}
                        </Typography.Text>
                        <Typography.Text type="secondary"></Typography.Text>
                      </Space>
                    </Space>
                  </div>
                </div>
              </motion.div>
            )}
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default DropEvents;
