import React, { useCallback, useMemo } from "react";

// UI
import { Select, TreeSelect } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import css from "../styles.module.css";

// Redux
import { useAppSelector } from "../../../../hooks";

// Rest
import { t } from "i18next";
import { IFilters } from "..";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { ILocation, SelectTreeItem } from "../../../../models";
import { IAsset } from "../../../../models/asset";
import { IMaintenanceCategory, IMaintenanceType } from "../../../../models/maintenances";
import { ITreeSelectItem } from "../../../../models/parts";
import { ISupplier } from "../../../../models/supplier";
import { IAccount } from "../../../../models/user";

interface IProps {
  filters: IFilters;
  set_filters: React.Dispatch<React.SetStateAction<IFilters>>;
  from: "asset" | "executor_accounts" | "executor_suppliers" | undefined;
  filtersDisabled: boolean;
  setFiltersDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const Filters: React.FC<IProps> = ({
  filters,
  set_filters,
  from,
  filtersDisabled,
  setFiltersDisabled,
}) => {
  // Variables
  const { maintenanceCategories, maintenancesTypes } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const { locations } = useAppSelector((state) => state.settingsReducer);
  const { accounts, accountsStatus } = useAppSelector((state) => state.accountsReducer);
  const { assetList, getAssetStatus } = useAppSelector((state) => state.assetReducer);
  const { suppliers, getSuppliersStatus } = useAppSelector((state) => state.supplierReducer);

  // Methods
  const setFilters = useCallback(
    (value: any, key: keyof IFilters) => {
      setFiltersDisabled(true);
      set_filters((prevFilters) => ({
        ...prevFilters,
        [key]: value,
      }));
    },
    [set_filters],
  );

  const createTreeSelect = useCallback((data: ITreeSelectItem[], parentValue: string | null) => {
    const map = new Map<string | null, SelectTreeItem[]>();
    data.forEach((item) => {
      if (!map.has(item.parent_id)) {
        map.set(item.parent_id, []);
      }
      map.get(item.parent_id)!.push({ ...item, children: [] });
    });

    const buildTree = (parent: string | null): SelectTreeItem[] =>
      (map.get(parent) || []).map((item) => ({
        ...item,
        children: buildTree(item.value),
      }));

    return buildTree(parentValue);
  }, []);

  // Data
  const tree = useMemo(
    () =>
      createTreeSelect(
        assetList.map((item: IAsset) => ({
          title: item.name,
          value: item.id.toString(),
          parent_id: item.parent_id?.toString() || null,
          disabled: false,
        })),
        null,
      ),
    [assetList],
  );

  return (
    <div className={css.buttonsContainer} id="calendar-filters">
      <TreeSelect
        disabled={from === "asset" || filtersDisabled}
        loading={getAssetStatus === "loading" || filtersDisabled}
        onChange={(e) => setFilters(e, "asset")}
        value={filters.asset}
        style={{ overflow: "auto", height: 32 }}
        maxTagCount="responsive"
        multiple={true}
        allowClear={true}
        placeholder={t(TRANSLATION_KEY.selectAsset)}
        treeData={tree}
        className={css.filterDropdown}
        filterTreeNode={(input, option) => {
          let name: string = option.title?.toString() || "";
          return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      />
      <Select
        disabled={filtersDisabled}
        onChange={(e) => setFilters(e, "maintenance_type")}
        placeholder={t(TRANSLATION_KEY.maintenanceTypes)}
        defaultValue={[]}
        style={{ overflow: "auto" }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        className={css.filterDropdown}
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {maintenancesTypes.map((item: IMaintenanceType, index: number) => (
          <Select.Option key={index} value={item.id}>
            {t(item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        disabled={filtersDisabled}
        onChange={(e) => setFilters(e, "maintenance_category")}
        placeholder={t(TRANSLATION_KEY.selectCategory)}
        defaultValue={[]}
        style={{ overflow: "auto" }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        className={css.filterDropdown}
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {maintenanceCategories.map((item: IMaintenanceCategory, index: number) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        disabled={filtersDisabled}
        onChange={(e) => setFilters(e, "maintenance_location")}
        placeholder={t(TRANSLATION_KEY.selectLocation)}
        defaultValue={[]}
        style={{ overflow: "auto", height: 34 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        className={css.filterDropdown}
        optionFilterProp="name"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {locations.map((item: ILocation, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>

      <Select
        disabled={from === "executor_accounts" || filtersDisabled}
        loading={accountsStatus === "loading"}
        value={filters.executor_account}
        style={{ overflow: "auto", height: 34 }}
        allowClear
        placeholder={t(TRANSLATION_KEY.employee)}
        mode="multiple"
        showSearch
        className={css.filterDropdown}
        optionFilterProp="name"
        maxTagCount="responsive"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => setFilters(e, "executor_account")}
      >
        {accounts.map((item: IAccount, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>

      <Select
        disabled={from === "executor_suppliers" || filtersDisabled}
        loading={getSuppliersStatus === "loading"}
        value={filters.executor_supplier}
        style={{ overflow: "auto", height: 34 }}
        allowClear
        placeholder={t(TRANSLATION_KEY.supplier)}
        mode="multiple"
        showSearch
        className={css.filterDropdown}
        optionFilterProp="name"
        maxTagCount="responsive"
        filterOption={(input, option: DefaultOptionType | any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => setFilters(e, "executor_supplier")}
      >
        {suppliers.map((item: ISupplier, index: number) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default Filters;
