import { RRule, Weekday } from "rrule";
import { Days, Deadline, TriggerBefore, WeekTimestamps } from "./types";
import { calculateHoursFromTimeString } from "./utils";

export const RRULE_TIME_FORMAT: string = "HH:mm";
export const INITIAL_TIMESTAMP: string = "07:00";

export const initialWeekTimestamps: Readonly<WeekTimestamps> = Object.freeze({
  "0": [calculateHoursFromTimeString(INITIAL_TIMESTAMP)],
  "1": [],
  "2": [],
  "3": [],
  "4": [],
  "5": [],
  "6": [],
});

export const weekDays: { [Day in Days]: Weekday } = {
  "0": RRule.MO,
  "1": RRule.TU,
  "2": RRule.WE,
  "3": RRule.TH,
  "4": RRule.FR,
  "5": RRule.SA,
  "6": RRule.SU,
};

export const parsedFreq = {
  0: "years",
  1: "months",
  2: "weeks",
  3: "days",
};

export const initialTriggerBefore: TriggerBefore = {
  every: 0,
  period: "days",
};

export const initialDeadline: Deadline = {
  every: 1,
  period: "weeks",
};

export const DAYS_IN_MONTH: Array<number> = new Array(31).fill(0).map((_, i) => i + 1);
export const WEEKS_IN_MONTH: Array<number> = new Array(5).fill(0).map((_, i) => i + 1);
export const R_META_INFO_FREQUENCIES = ["hours", "days", "weeks", "months", "years"];
