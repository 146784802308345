import { Column, ColumnConfig, Datum, Pie, PieConfig } from "@ant-design/charts";

import {
  Card,
  Col,
  Divider,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Statistic,
  Table,
  Typography,
} from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Link } from "react-router-dom";
import AnalyticsCard from "../../../../components/Cards/AnalyticsCard";
import PreviewButton from "../../../../components/PreviewButton";
import { DONUT_CHART_CONFIG, LAYOUT_GRIDS, TRANSLATION_KEY } from "../../../../helpers/consts";
import { parseAnalyticsInfo, parseCosts, renderStatistic } from "../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IApiResponse, ILoadMoreParams, ITableColumn } from "../../../../models";
import api, { failedQueue, isRefreshing } from "../../../../services";
import AssetModalPreview from "../../../assetdetails/components/AssetModalPreview";
import MaintenanceModalPreview from "../../../maintenance/components/ModalPreview";
import Filters, { IFilters } from "./components/Filters";
import Loader from "../../components/Loader";
import { IUser } from "../../../../models/user";
import { RootState } from "../../../../store";
import { connect } from "react-redux";
import { useWindowSize } from "react-use";
import { IItemTranslatetProps } from "./Reportonemaintenance";
import LoadMoreButton from "../../../../components/LoadMoreButton";

interface IProps {}

interface IReport {
  total_costs: number;
  material_costs: number;
  work_costs: number;
  avg_cost_per_maintenance: number;
  purchase_asset_value: number;
  maintenance_costs_vs_purchase_asset_value: number | null;
  costs_by_maintenance_categories: ICostByCategory[];
  costs_by_maintenance_type: ICostByType[];
}

interface ICostByCategory {
  maintenance_cateogries: IItemTranslatetProps[];
  total_costs: number;
}

interface ICostByType {
  maintenance_type: IItemTranslatetProps;
  total_costs: number;
  color: string;
}

interface IMaintenanceCosts {
  order_number: string;
  id: number;
  description: string;
  total_cost: number;
  material_costs: number;
  work_costs: number;
  is_active: boolean;
  asset: {
    id: number;
    name: string;
    path: string | null;
    is_active: boolean;
  };
}

const LIMIT_SIZE = 90;

const Reportthreemaintenance: React.FC<IProps> = () => {
  // Hooks
  const { width } = useWindowSize();

  // Variables
  const [loading, set_loading] = useState(false);
  const [reportData, set_reportData] = useState<IReport>();
  const [maintenanceCosts, setMaintenanceCosts] = useState<IMaintenanceCosts[]>([]);
  const [selectedMaintenance, set_selectedMaintenance] = useState<number>(-1);

  const [loadMoreParams, set_loadMoreParams] = useState<ILoadMoreParams>({
    offset: 0,
    limit: LIMIT_SIZE,
  });
  const [loadMoreButtonVisible, set_loadMoreButtonVisible] = useState<boolean>(true);
  const [filtersForLoadMore, set_filtersForLoadMore] = useState<IFilters>({
    assets: [],
    asset_categories: [],
    start: "",
    end: "",
    executor_account: [],
    executor_supplier: [],
    calendarType: "month",
    clients: [],
  });
  const [selectedAsset, set_selectedAsset] = useState<number>(-1);
  const [costsLoading, set_costsLoading] = useState(false);
  const { maintenancesTypes } = useAppSelector((state) => state.maintenanceReducer);
  const { user } = useAppSelector((state: RootState) => state.userReducer);

  const [currency] = useState(user?.account.company.currency);

  const getData = async (filters: IFilters) => {
    set_loading(true);
    let token = await localStorage.getItem("token");

    try {
      let res = await api.get<IApiResponse<IReport>>("/maintenance/analytics/maintenance_costs/", {
        headers: { Authorization: "Bearer " + token },
        params: {
          assets: filters.assets.join("|"),
          asset_categories: filters.asset_categories.join("|"),
          executor_account: filters.executor_account?.join("|"),
          executor_supplier: filters.executor_supplier?.join("|"),
          end: filters.end,
          start: filters.start,
          clients: filters.clients?.join("|"),
        },
      });
      set_reportData(res.data.results);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getData(filters));
        }
        return;
      }
      console.log(error);
      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_loading(false);
  };

  const getMaintenanceCosts = async (filters: IFilters, loadMoreParams: ILoadMoreParams) => {
    set_costsLoading(true);
    if (loadMoreParams.offset === 0) {
      set_loadMoreButtonVisible(true);
    }

    let token = await localStorage.getItem("token");

    try {
      let response = await api.get<IApiResponse<IMaintenanceCosts[]>>(
        `/maintenance/analytics/maintenance_list/`,
        {
          headers: { Authorization: "Bearer " + token },
          params: {
            offset: loadMoreParams.offset,
            limit: loadMoreParams.limit,
            assets: filters.assets.join("|"),
            asset_categories: filters.asset_categories.join("|"),
            end: filters.end,
            start: filters.start,
            executor_account: filters.executor_account?.join("|"),
            executor_supplier: filters.executor_supplier?.join("|"),
            clients: filters.clients?.join("|"),
          },
        },
      );

      if (response.data.results) {
        if (loadMoreParams.offset === 0) {
          setMaintenanceCosts(response.data.results);
        } else {
          setMaintenanceCosts([...maintenanceCosts, ...response.data.results]);
        }
        set_filtersForLoadMore(filters);
        if (response.data.results.length < loadMoreParams.limit) {
          set_loadMoreButtonVisible(false);
        }
        set_loadMoreParams({
          offset: loadMoreParams.offset + loadMoreParams.limit,
          limit: loadMoreParams.limit,
        });
      }
      set_costsLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getMaintenanceCosts(filters, loadMoreParams));
        }
        return;
      }
      console.log(error);
      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_costsLoading(false);
  };

  const donutConfig: PieConfig = {
    ...DONUT_CHART_CONFIG,
    radius: width < 1400 ? 0.7 : 0.85,
    data:
      reportData?.costs_by_maintenance_type.map((x) => ({
        ...x,
        maintenance_type: `${t(
          x.maintenance_type?.name || x.maintenance_type?.trans_key || TRANSLATION_KEY.other,
        )}`,
      })) || [],
    angleField: "total_costs",
    colorField: "maintenance_type",
    color: ({ maintenance_type }) => {
      let color = "";
      color =
        maintenancesTypes.find(
          (x) => t(x.name || x.trans_key || TRANSLATION_KEY.other) === maintenance_type,
        )?.color || "";
      return color;
    },
    meta: {
      value: {
        formatter: (v) => `${v}`,
      },
    },
    label: {
      ...DONUT_CHART_CONFIG.label,
      content: (originData) => {
        return parseCosts(originData.total_costs) + currency;
      },
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.maintenance_type : t(TRANSLATION_KEY.total);
          return renderStatistic(d, text, {
            fontSize: 22,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "32px",
        },
        customHtml: (container, view, datum, data: any) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${parseCosts(datum.total_costs.toFixed(2))} ${currency}`
            : `${parseCosts(data.reduce((r, d) => r + d.total_costs, 0).toFixed(2))} ${currency}`;
          return renderStatistic(width, text, {
            fontSize: 24,
          });
        },
      },
    },
  };

  const columnConfig: Partial<ColumnConfig> = {
    tooltip: {
      formatter: (datum: Datum) => {
        return {
          name: t(TRANSLATION_KEY.totalCosts),
          value: `${parseCosts(datum.total_costs)} ${currency}`,
        };
      },
    },
    yAxis: {
      label: {
        formatter: (v: string) => {
          return `${parseCosts(v)} ${currency}`;
        },
      },
    },
  };

  const COLUMNS: ITableColumn<IMaintenanceCosts>[] = [
    {
      title: t(TRANSLATION_KEY.maintenanceId),
      dataIndex: "order_number",
      visible: true,
      render: (text: string, value: IMaintenanceCosts) => `#${value.order_number}`,
    },
    {
      title: t(TRANSLATION_KEY.description),
      dataIndex: "description",
      visible: true,
      render: (text: string, value: IMaintenanceCosts) => {
        return (
          <PreviewButton
            isActive={value.is_active}
            title={value.description}
            id={value.id}
            url={`/app/maintenances/${value.id}`}
            containerStyle={{ minWidth: "unset" }}
            onClick={() => {
              unstable_batchedUpdates(() => {
                set_selectedMaintenance(value.id);
              }, []);
            }}
          />
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.asset),
      dataIndex: "asset",
      visible: true,
      render: (text: string, value: IMaintenanceCosts) => {
        if (!value.asset)
          return <Typography.Text>{t(TRANSLATION_KEY.withoutAsset)}</Typography.Text>;
        return (
          <Space direction="vertical" size={0}>
            <PreviewButton
              isActive={value.asset.is_active}
              title={value.asset?.name}
              id={value.asset.id}
              url={`/app/asset-details/${value.asset?.id}`}
              permissionsRequired={["view_asset"]}
              containerStyle={{ minWidth: "unset" }}
              onClick={() => {
                unstable_batchedUpdates(() => {
                  set_selectedAsset(value.asset?.id);
                }, []);
              }}
            />
            {value.asset?.path && (
              <Typography.Text
                type="secondary"
                style={{ maxWidth: 320 }}
                ellipsis={{ tooltip: value?.asset.path }}
              >
                {value?.asset.path}
              </Typography.Text>
            )}
          </Space>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.materialCosts),
      dataIndex: "material_costs",
      visible: true,
      render(text: string, value: IMaintenanceCosts) {
        return <span>{`${parseCosts(value.material_costs.toString())} ${currency}`}</span>;
      },
    },
    {
      title: t(TRANSLATION_KEY.workingCosts),
      dataIndex: "work_costs",
      visible: true,
      render(text: string, value: IMaintenanceCosts) {
        return <span>{`${parseCosts(value.work_costs.toString())} ${currency}`}</span>;
      },
    },
    {
      title: t(TRANSLATION_KEY.totalCosts),
      dataIndex: "total_cost",
      visible: true,
      render(text: string, value: IMaintenanceCosts) {
        return <span>{`${parseCosts(value.total_cost.toString())} ${currency}`}</span>;
      },
    },
  ];

  return (
    <div>
      {/* Spinner */}
      <Loader isLoading={costsLoading} />

      {/* Filters */}
      <Filters
        onChange={(f) => {
          getData(f);
          getMaintenanceCosts(f, {
            offset: 0,
            limit: LIMIT_SIZE,
          });
        }}
      />

      {/* Cards */}
      <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }}>
        <Col xs={12} xxl={6}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.totalCostsOfMaintenances)}
            value={`${parseCosts(reportData?.total_costs.toString()) || 0} ${currency}`}
          />
        </Col>
        <Col xs={12} xxl={6}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.materialCostsOfMaintenances)}
            value={`${parseCosts(reportData?.material_costs.toString()) || 0} ${currency}`}
          />
        </Col>
        <Col xs={12} xxl={6}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.workCostsOfMaintenances)}
            value={`${parseCosts(reportData?.work_costs.toString()) || 0} ${currency}`}
          />
        </Col>
        <Col xs={12} xxl={6}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.avgCostPerMaintenance)}
            value={`${
              parseCosts(reportData?.avg_cost_per_maintenance.toString()) || 0
            } ${currency}`}
          />
        </Col>
      </Row>

      {/* Charts */}
      <Row gutter={[24, 24]}>
        {/* Column chart */}
        <Col {...{ ...LAYOUT_GRIDS, xxl: 12, xl: 12 }}>
          <div className="white-container" style={{ height: "100%", maxHeight: 480 }}>
            {/* Title */}
            <Typography.Title level={5} style={{ paddingTop: 12 }}>
              {t(TRANSLATION_KEY.maintenanceCostCategory)}
            </Typography.Title>
            {/* Divider */}
            <div style={{ marginTop: 12, marginBottom: 12 }} />
            {/* Chart */}
            <div style={{ maxHeight: 572 }}>
              <Column
                {...columnConfig}
                data={
                  reportData
                    ? reportData?.costs_by_maintenance_categories.map((x) => ({
                        ...x,
                        maintenance_cateogries:
                          x.maintenance_cateogries
                            .map((z) => t(z.trans_key || "") || t(z.name || ""))
                            .join(" ") || t(TRANSLATION_KEY.other),
                      }))
                    : []
                }
                maxColumnWidth={60}
                xField={"maintenance_cateogries"}
                yField="total_costs"
                label={{
                  content: (originData) => {
                    return parseCosts(originData.total_costs) + currency;
                  },
                }}
              />
            </div>
          </div>
        </Col>
        {/* Pie chart */}
        <Col {...{ ...LAYOUT_GRIDS, xxl: 12, xl: 12 }}>
          <div className="white-container" style={{ height: "100%", maxHeight: 480 }}>
            {/* Title */}
            <Typography.Title level={5} style={{ paddingTop: 12 }}>
              {t(TRANSLATION_KEY.maintenanceCostType)}
            </Typography.Title>
            {/* Divider */}
            <div style={{ marginTop: 12, marginBottom: 12 }} />
            {/* Chart */}
            <div style={{ maxHeight: 572 }}>
              <Pie {...donutConfig} />
            </div>
          </div>
        </Col>
      </Row>

      {/* Cards */}
      <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }}>
        <Col xs={12} lg={12}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.byCostsOfAsset)}
            value={`${parseCosts(reportData?.purchase_asset_value.toString() || "0")} ${currency}`}
          />
        </Col>
        <Col xs={12} lg={12}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.costsVsPurchaseAssetValue)}
            value={`${parseAnalyticsInfo(
              reportData?.maintenance_costs_vs_purchase_asset_value?.toString() || "0",
              2,
              true,
            )}%`}
          />
        </Col>
      </Row>

      {/* Table */}
      <div style={{ marginTop: 24 }}>
        <Table pagination={false} dataSource={maintenanceCosts} columns={COLUMNS} />
        {loadMoreButtonVisible && (
          <LoadMoreButton
            loading={false}
            onClick={() => {
              getMaintenanceCosts(filtersForLoadMore, loadMoreParams);
            }}
          >
            {t(TRANSLATION_KEY.loadMore)}
          </LoadMoreButton>
        )}
      </div>

      {/* Preview modal */}
      <Modal
        visible={selectedAsset !== -1 || selectedMaintenance !== -1}
        onCancel={() => {
          set_selectedAsset(-1);
          set_selectedMaintenance(-1);
        }}
        footer={null}
        centered
        width="800px"
        closable={false}
        destroyOnClose
      >
        {selectedAsset !== -1 && <AssetModalPreview id={selectedAsset} />}
        {selectedMaintenance !== -1 && <MaintenanceModalPreview id={selectedMaintenance} />}
      </Modal>
    </div>
  );
};

export default Reportthreemaintenance;
