import moment, { Moment } from "moment";
import { IFilters } from ".";
import { IMaintenance, MaintenanceEvent } from "../../../models/maintenances";

export function parseDate(date: Moment | string | null) {
  return moment(date).format("YYYY-MM-DD HH:mm");
}

export function parseStartDate(date: Moment | string | null, days_substracted = 0) {
  return moment(date).subtract(days_substracted, "d").format("YYYY-MM-DD");
}

export function parseEndDate(date: Moment | string | null, days_added = 0) {
  return moment(date).add(days_added, "d").format("YYYY-MM-DD");
}

export function transformFiltersIntoQueryParams(filters: IFilters) {
  return {
    asset: filters.asset.join(",") || null,
    maintenance_type: filters.maintenance_type.join(",") || null,
    maintenance_category: filters.maintenance_category.join(",") || null,
    maintenance_location: filters.maintenance_location.join(",") || null,
    executor_account: filters.executor_account.join(",") || null,
    executor_supplier: filters.executor_supplier.join(",") || null,
  };
}

export function transformEventIntoMaintenance(event: MaintenanceEvent): IMaintenance {
  return {
    id: event.id!,
    held_reason: null,
    description: event.order_description,
    location: event.location,
    planned_start: event.planned_start,
    planned_end: event.planned_end,
    real_start: null,
    real_end: null,
    status: event.status,
    time_needed: null,
    working_time: "",
    is_late: false,
    is_active: false,
    created_at: "",
    schedule: null,
    reported_by: null!,
    closed_by: null,
    closed_at: null,
    executors: [],
    client: null,
    rent_order: null,
    status_change_ts: "",
    search: "",
    order_number: event.order_number?.toString() || "",
    asset: {
      base_buy_price: 0,
      base_currency: "",
      avatar: "",
      path: event.asset?.path || "",
      created_at: "",
      id: event.asset?.id || 0,
      custom_id: event.asset?.custom_id || "",
      name: event.asset?.name || "",
      parent: event.asset?.parent || null,
      description: "",
      autopilot: false,
      type: null,
      is_active: event.asset?.is_active || true,
      parent_id: 0,
      buy_price: 0,
      relevant_links: [],
      supplier: null,
      category: { id: 0, name: "", static: false, trans_key: "" },
      location: null,
      buy_price_currency: "",
      status: "",
      files: [],
      custom_fields: [],
      can_report_order: false,
      client_owner: null,
      custom_fields_v2: {},
      has_rent_orders: false,
      rented_to_client: null,
    },
    deadline: "",
    custom_fields: null,
    maintenance_categories: event.maintenance_categories,
    maintenance_type: event.maintenance_type,
  };
}

export function transformMaintenanceIntoEvent(
  maintenance: IMaintenance,
  event_type: MaintenanceEvent["event_type"] = "actual_order",
): MaintenanceEvent {
  return {
    event_type,
    id: maintenance.id,
    order_number: parseInt(maintenance.order_number),
    order_description: maintenance.description,
    order_template_id: 0,
    asset: maintenance.asset
      ? {
          id: maintenance.asset?.id,
          name: maintenance.asset?.name,
          path: maintenance.asset?.path,
          parent: maintenance.asset?.parent,
          is_active: maintenance.asset?.is_active,
          custom_id: maintenance.asset?.custom_id,
        }
      : null,
    location: maintenance.location || null,
    day_name: "",
    planned_start: maintenance.planned_start,
    planned_end: maintenance.planned_end,
    status: maintenance.status,
    maintenance_categories: maintenance.maintenance_categories,
    maintenance_type: maintenance.maintenance_type,
  };
}
