import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAssetWithMaintenances,
  ILoggedTechnician,
  IMaintenanceByCategoryType,
  ITopCosts,
} from "../../../models/dashboard";
import { IMaintenance } from "../../../models/maintenances";
import {
  CountPerCategory,
  CountPerType,
} from "../../../pages/analytics/pages/maintenance/Reportonemaintenance";
import { t } from "i18next";

interface IDashboardReducer {
  top_costs: ITopCosts[];
  orders_without_report: IMaintenance[];
  orders_without_report_count: number | undefined;
  loggedTechnicians: ILoggedTechnician[];
  maintenancesByCategoryType: IMaintenanceByCategoryType;
}

const initialState = {
  orders_without_report_count: undefined,
  top_costs: [],
  orders_without_report: [],
  loggedTechnicians: [],
  maintenancesByCategoryType: {
    categories: [],
    types: [],
  },
} as IDashboardReducer;

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    set_top_costs: (state, action: PayloadAction<ITopCosts[]>) => {
      state.top_costs = action.payload;
    },
    set_orders_without_report: (state, action: PayloadAction<IMaintenance[]>) => {
      state.orders_without_report = action.payload;
    },

    set_loggedTechnicians(state, action: PayloadAction<ILoggedTechnician[]>) {
      state.loggedTechnicians = action.payload;
    },
    set_orders_without_report_count(state, action: PayloadAction<number | undefined>) {
      state.orders_without_report_count = action.payload;
    },

    set_categoriesTypes(state, action: PayloadAction<IMaintenance[]>) {
      let categories: CountPerCategoryDashboard[] = [];
      let allCategories: string[] = [];
      let types: CountPerTypeDashboard[] = [];
      let allTypes: string[] = [];
      let payload: IMaintenance[] = [...action.payload];
      let data: IMaintenance[] = [];

      // Create new array with sorted maintenance categories
      payload.forEach((maintenance) => {
        let unsorted_maintenance_categories = maintenance.maintenance_categories
          ? [...maintenance.maintenance_categories]
          : null;
        let sorted_maintenance_categories = unsorted_maintenance_categories?.sort((a, b) =>
          a["name"].localeCompare(b["name"]),
        );
        data.push({
          ...maintenance,
          maintenance_categories: sorted_maintenance_categories || null,
        });
      });

      data.forEach((x) => {
        let nameCategory: string =
          x.maintenance_categories?.map((x) => t(x.trans_key || x.name)).join(" ") || "other"; // kreiraj zasebno naziv kategorije jer mogu biti vise kategorija
        allCategories.push(nameCategory);
        allTypes.push(t(x.maintenance_type?.trans_key || x.maintenance_type?.name || "other"));
        if (categories.filter((fil) => fil.name === nameCategory).length === 0) {
          // ubaci zasebno katgorije u nizz
          categories.push({
            name: nameCategory,
            count: 0, // sumiraj koliko zasebne kategorije ima unutar liste odrzavanja
            percentage: 0,
          });
        }
        let typeName = t(x.maintenance_type?.trans_key || x.maintenance_type?.name || "other");
        if (types.filter((fil) => fil.name === typeName).length === 0) {
          types.push({
            name: typeName,
            count: 0,
            percentage: 0,
            color: x.maintenance_type?.color || "",
          });
        }

        categories.forEach((x) => {
          x.count = allCategories.filter((y) => y === x.name).length;
        });

        types.forEach((x) => {
          let sum = allTypes.filter((y) => y === x.name).length;
          x.count = sum;
          x.percentage = +((sum / allTypes.length) * 100).toFixed(0);
        });
      });

      //set state
      state.maintenancesByCategoryType = {
        categories,
        types,
      };
    },
  },
});

export default dashboardSlice.reducer;

export type CountPerTypeDashboard = {
  count: number;
  name: string | null;
  percentage: number;
  color: string;
};

export type CountPerCategoryDashboard = {
  count: number;
  name: string | null;
  percentage: number;
};
