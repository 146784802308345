import { Radio, RadioChangeEvent, Select, Space, TimePicker } from "antd";
import moment from "moment";
import { useState } from "react";
import { DAYS_IN_MONTH, INITIAL_TIMESTAMP, RRULE_TIME_FORMAT } from "../constants";
import { calculateHoursFromTimeString, convertHoursToMoment } from "../utils";
import { RRuleContextType } from "../useRRule";

export type YearlyRuleType = "SpecificDate" | "YearlyDate";

interface IProps {
  rrule: RRuleContextType;
}

const YearFrequency = ({ rrule }: IProps) => {
  // Constants
  const pickerWidth: number = 90;
  const pickerHeight: number = 32;

  // Variables
  const [localRule, setLocalRule] = useState<YearlyRuleType>("SpecificDate");
  const [localTimestamps, setLocalTimestamps] = useState<{ [Rule in YearlyRuleType]: string }>({
    SpecificDate:
      rrule.timestamps && rrule.timestamps.length > 0
        ? convertHoursToMoment(rrule.timestamps[0] as number).format(RRULE_TIME_FORMAT)
        : INITIAL_TIMESTAMP,
    YearlyDate: INITIAL_TIMESTAMP,
  });
  const [localBymonth, setLocalBymonth] = useState<{ [Rule in YearlyRuleType]: number }>({
    SpecificDate: Array.isArray(rrule.rulesets[0].options.bymonth)
      ? rrule.rulesets[0].options.bymonth[0]
      : DAYS_IN_MONTH[0],
    YearlyDate: 1,
  });

  // Methods
  const handleTimepickerChange = (
    date: moment.Moment | null,
    dateString: string,
    rule: YearlyRuleType,
  ) => {
    if (date) {
      rrule.setTime(dateString, 0);
      rrule.yearlySetup({ type: rule, bymonth: localBymonth[rule] });
      setLocalRule(rule);
      updateTimestamps(rule, dateString);
      updateBymonth(rule);
    }
  };

  const updateBymonth = (rule: YearlyRuleType, value?: number) => {
    if (rule === "SpecificDate") {
      setLocalBymonth((prevValue) => ({
        SpecificDate: value || prevValue.SpecificDate,
        YearlyDate: 1,
      }));
    } else {
      setLocalBymonth((prevValue) => ({
        SpecificDate: 1,
        YearlyDate: value || prevValue.YearlyDate,
      }));
    }
  };

  const updateTimestamps = (rule: YearlyRuleType, value?: string) => {
    if (rule === "SpecificDate") {
      setLocalTimestamps((prevValue) => ({
        SpecificDate: value || prevValue.SpecificDate,
        YearlyDate: INITIAL_TIMESTAMP,
      }));
    } else {
      setLocalTimestamps((prevValue) => ({
        SpecificDate: INITIAL_TIMESTAMP,
        YearlyDate: value || prevValue.YearlyDate,
      }));
    }
  };

  const onRuleChange = (e: RadioChangeEvent) => {
    let newValue: YearlyRuleType = e.target.value;
    setLocalTimestamps({
      SpecificDate: INITIAL_TIMESTAMP,
      YearlyDate: INITIAL_TIMESTAMP,
    });
    updateBymonth(newValue);
    rrule.yearlySetup({
      type: newValue,
      byhour: calculateHoursFromTimeString(INITIAL_TIMESTAMP),
      bymonth: localBymonth[newValue],
      bymonthday: newValue === "SpecificDate" ? localBymonth.SpecificDate : undefined,
    });
    handleRuleChange(newValue);
    updateBymonth(newValue);
  };

  const handleRuleChange = (rule: YearlyRuleType) => {
    setLocalRule(rule);
  };
  return (
    <Space
      align="center"
      direction="vertical"
      style={{ paddingInline: 8, paddingBottom: 8, paddingTop: 4 }}
    >
      <Radio.Group onChange={onRuleChange} value={localRule}>
        <Space direction="vertical">
          {/* Top local rule */}
          <Space align="center" style={{ height: pickerHeight }}>
            {/* Current rule indicator - //! Deprecated */}
            {/* <Radio value="SpecificDate"></Radio> */}
            {/* Days in month picker */}
            <Select
              onChange={(bymonthday) => {
                rrule.yearlySetup({
                  type: "SpecificDate",
                  bymonthday: bymonthday
                    ? Array.isArray(bymonthday)
                      ? bymonthday[0]
                      : bymonthday
                    : DAYS_IN_MONTH[0],
                  byhour: calculateHoursFromTimeString(localTimestamps.SpecificDate),
                  bymonth: localBymonth.SpecificDate,
                });
                handleRuleChange("SpecificDate");
                updateTimestamps("SpecificDate");
                updateBymonth("SpecificDate");
              }}
              value={rrule.rulesets[0].origOptions.bymonthday || DAYS_IN_MONTH[0]}
              defaultValue={DAYS_IN_MONTH[0]}
              style={{ width: 72 }}
            >
              {DAYS_IN_MONTH.map((day) => (
                <Select.Option key={day} value={day}>
                  {day}
                </Select.Option>
              ))}
            </Select>
            {/* Months in year picker */}
            <Select
              defaultValue={+moment.months()[0]}
              style={{ width: 120 }}
              onChange={(bymonth) => {
                rrule.yearlySetup({
                  type: "SpecificDate",
                  bymonth,
                  byhour: calculateHoursFromTimeString(localTimestamps.SpecificDate),
                });
                handleRuleChange("SpecificDate");
                updateTimestamps("SpecificDate");
                updateBymonth("SpecificDate", bymonth);
              }}
              value={localBymonth.SpecificDate || +moment.months()[0]}
            >
              {moment.months().map((month, index) => (
                <Select.Option key={month} value={index + 1}>
                  {month}
                </Select.Option>
              ))}
            </Select>
            {/* Timestamp picker */}
            <TimePicker
              format={RRULE_TIME_FORMAT}
              allowClear={false}
              showMinute={false}
              value={convertHoursToMoment(
                calculateHoursFromTimeString(localTimestamps.SpecificDate),
              )}
              onChange={(date, dateString) =>
                handleTimepickerChange(date, dateString, "SpecificDate")
              }
              style={{
                minWidth: pickerWidth,
                maxWidth: pickerWidth,
                height: pickerHeight,
                backgroundColor: "#f0f0f0",
                borderRadius: "40px",
              }}
            />
          </Space>

          {/* Bottom local rule - //! Deprecated */}
          {/* <Space align="center" style={{ height: pickerHeight }}>
            <Radio value="YearlyDate"></Radio>
            <Select
              onChange={(nth) => {
                rrule.setWeekdayNth(nth);
                rrule.yearlySetup({
                  type: "YearlyDate",
                  nth,
                  byhour: calculateHoursFromTimeString(localTimestamps.YearlyDate),
                  bymonth: localBymonth.YearlyDate,
                });
                handleRuleChange("YearlyDate");
                updateTimestamps("YearlyDate");
                updateBymonth("YearlyDate");
              }}
              value={rrule.weekdayNth}
              style={{ width: 72 }}
            >
              {WEEKS_IN_MONTH.map((week) => (
                <Select.Option key={week} value={week}>
                  {week}
                </Select.Option>
              ))}
            </Select>
            <Select
              onChange={(byweekday) => {
                rrule.setWeekdayDay(byweekday);
                rrule.yearlySetup({
                  type: "YearlyDate",
                  byweekday,
                  byhour: calculateHoursFromTimeString(localTimestamps.YearlyDate),
                  bymonth: localBymonth.YearlyDate,
                });
                handleRuleChange("YearlyDate");
                updateTimestamps("YearlyDate");
                updateBymonth("YearlyDate");
              }}
              value={rrule.weekdayDay}
              style={{ width: 140 }}
            >
              {moment.weekdays(true).map((day, index) => (
                <Select.Option key={day} value={index}>
                  {day}
                </Select.Option>
              ))}
            </Select>
            <Select
              defaultValue={+moment.months()[0]}
              style={{ width: 120 }}
              onChange={(bymonth) => {
                rrule.yearlySetup({
                  type: "YearlyDate",
                  bymonth,
                  byhour: calculateHoursFromTimeString(localTimestamps.YearlyDate),
                });
                handleRuleChange("YearlyDate");
                updateTimestamps("YearlyDate");
                updateBymonth("YearlyDate", bymonth);
              }}
              value={localBymonth.YearlyDate || +moment.months()[0]}
            >
              {moment.months().map((month, index) => (
                <Select.Option key={month} value={index + 1}>
                  {month}
                </Select.Option>
              ))}
            </Select>
            <TimePicker
              format={RRULE_TIME_FORMAT}
              allowClear={false}
              showMinute={false}
              value={convertHoursToMoment(calculateHoursFromTimeString(localTimestamps.YearlyDate))}
              onChange={(date, dateString) =>
                handleTimepickerChange(date, dateString, "YearlyDate")
              }
              style={{
                minWidth: pickerWidth,
                maxWidth: pickerWidth,
                height: pickerHeight,
                backgroundColor: "#f0f0f0",
                borderRadius: "40px",
              }}
            />
          </Space> */}
        </Space>
      </Radio.Group>
    </Space>
  );
};

export default YearFrequency;
