import { EventContentArg } from "@fullcalendar/react";
import React from "react";
import { Link } from "react-router-dom";

// Moment
import moment from "moment";
import "moment/locale/hr";

// UI
import { Badge, Button, Popover, Space, Tag, Typography } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { MaintenanceEvent } from "../../../../models/maintenances";
import css from "../styles.module.css";

const RenderEvent: React.FC<EventContentArg> = (eventContent) => {
  // Variables
  const maintenance = eventContent.event._def.extendedProps.maintenance as MaintenanceEvent;

  // Content
  const content = maintenance && (
    <div className={css.eventContainer}>
      <div style={{ width: 324, display: "flex", flexDirection: "column" }}>
        {/* Maintenance type */}
        {maintenance.maintenance_type && (
          <Space size="small">
            <Badge
              color={maintenance.maintenance_type.color}
              style={{ transform: "scale(2)", marginLeft: 6 }}
            />
            <Typography.Text style={{ fontSize: 12, fontWeight: 500, opacity: 0.85 }}>
              {t(maintenance.maintenance_type.name || "")}
            </Typography.Text>
          </Space>
        )}
        {/* Title */}
        <Typography.Paragraph className={css.title}>
          {maintenance.order_description}
        </Typography.Paragraph>

        <div className="spaceBetweenRow" style={{ alignItems: "flex-start" }}>
          <Space align="start">
            {/* <SettingTwoTone  twoToneColor="#2d3b51" style={{ marginTop: 4 }} /> */}
            <Space direction="vertical" size={0}>
              <Typography.Text style={{ fontSize: 14, marginBottom: -6 }}>
                {maintenance.asset?.name}
              </Typography.Text>
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                {maintenance.location?.name}
              </Typography.Text>
            </Space>
          </Space>

          <Space align="start">
            <Space direction="vertical" size={0}>
              <Typography.Text style={{ fontSize: 12, marginBottom: -6, textAlign: "right" }}>
                {t(maintenance.status)}
              </Typography.Text>
              <Typography.Text type="secondary"></Typography.Text>
            </Space>
            {/* <SyncOutlined twoToneColor="#2d3b51" style={{ marginTop: 4 }} /> */}
          </Space>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: 6, marginTop: 18 }}>
          {maintenance.maintenance_categories?.map((item, index) => (
            <Tag
              key={index}
              style={{
                padding: "2px 10px",
                border: "none",
                borderRadius: "40px",
                backgroundColor: "#ccc",
                color: "#333",
                fontWeight: 400,
                marginBottom: 6,
              }}
            >
              {t(item.trans_key || item.name)}
            </Tag>
          ))}
        </div>

        {/* Group */}
        <div className="spaceBetweenRow" style={{ alignItems: "center", marginBottom: 8 }}>
          <p className={css.groupLabel} style={{ margin: 0 }}>
            {t(TRANSLATION_KEY.startTime)}
          </p>
          <p className={css.groupValue} style={{ margin: 0 }}>
            {moment(maintenance.planned_start).format("LLL")}
          </p>
        </div>

        {/* Divider */}
        <div className={css.divider} />
        <div className={css.eventContainerButtons}>
          {maintenance.event_type === "actual_order" && (
            <Button className={css.eventButton} type="primary">
              <Link
                target="_blank"
                to={`/app/maintenances/${maintenance.id}`}
                // state={{ openModal: true }}
              >
                {t(TRANSLATION_KEY.maintenance)}
              </Link>
            </Button>
          )}
          <Button
            className={css.eventButton}
            style={{ width: maintenance.event_type === "virtual_order" ? "100%" : "48%" }}
            type="primary"
          >
            <Link
              target="_blank"
              to={`/app/asset-details/${eventContent.event._def.extendedProps.asset_id}/`}
            >
              {t(TRANSLATION_KEY.asset)}
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <Popover
      placement="left"
      content={content}
      title={
        <div style={{ padding: "10px 0" }}>
          {maintenance && maintenance?.order_number === null
            ? t(TRANSLATION_KEY.maintenance)
            : `${t(TRANSLATION_KEY.maintenance)} #${maintenance?.order_number}`}
        </div>
      }
    >
      {maintenance && (
        <div
          className={css.eventDisplay}
          style={{
            backgroundColor: maintenance.maintenance_type?.color || "#d3d3d375",
          }}
        >
          <p
            style={{ color: !maintenance.maintenance_type ? "#000000c2" : undefined }}
            className={css.eventTime}
          >
            {moment(maintenance.planned_start).format("HH:mm")}
          </p>
          <p
            style={{ color: !maintenance.maintenance_type ? "#000000c2" : undefined }}
            className={css.eventTitle}
          >
            {maintenance.asset?.custom_id || "#000"}
          </p>
          <p
            style={{ color: !maintenance.maintenance_type ? "#000000c2" : undefined }}
            className={css.eventDescription}
          >
            {maintenance.order_description}
          </p>
        </div>
      )}
    </Popover>
  );
};

export default RenderEvent;
