import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "./hooks";
import { meXHR, setMqttClient } from "./store/reducers/user/actionCreators";

// Components
import "./App.css";
import moment from "moment";
import AppWraper from "./AppWraper";
import Loading from "./components/Loading";
import { ConfigProvider, message, Spin } from "antd";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

// Locale
import { I18N_LANGUAGES, Languages, MOMENT_LANGUAGES, TRANSLATION_KEY } from "./helpers/consts";
import { Locale } from "antd/es/locale-provider";
import i18next, { t } from "i18next";
import hr from "antd/es/locale/hr_HR";
import en from "antd/es/locale/en_US";
import rs from "antd/es/locale/sr_RS";
import de from "antd/es/locale/de_DE";
import "moment/locale/hr";
import "moment/locale/sr";
import "moment/locale/de";
import { userSlice } from "./store/reducers/user";
import { getCurrenciesXHR } from "./store/reducers/settings/actionCreator";
import TermsOfService from "./components/TermsOfService";

type Locales = {
  [language in Languages]: Locale;
};

function App() {
  // Variables
  const currentRoute = window.location.pathname;
  const dispatch = useAppDispatch();
  const { meStatus, user, loadingLanguage } = useAppSelector((state) => state.userReducer);
  const { getCustomFieldsV2Status } = useAppSelector((state) => state.settingsReducer);

  // Methods
  useEffect(() => {
    let responsiveRoutes = [
      "/open-order",
      "/login",
      "/registration",
      "/registrationwithtoken",
      "/reset_password",
    ];
    document.body.classList.toggle(
      "responsive-width",
      responsiveRoutes.some((route) => currentRoute.startsWith(route)),
    );
  }, [currentRoute]);

  useEffect(() => {
    // Fetch user
    let isOpenOrder =
      window.location.pathname.includes("open-order") ||
      window.location.pathname.includes("requestpanel"); // Can't use useLocation hook outside Router
    if (!isOpenOrder) {
      meXHR(
        {
          successCallback: (data) => {
            if (data.results) {
              setMqttClient({ body: data.results }, dispatch);
              dispatch(userSlice.actions.setLoadingLanguage(true));
              getCurrenciesXHR({}, dispatch);
              i18next
                .changeLanguage(I18N_LANGUAGES[data.results.language!])
                .then(() => {
                  dispatch(userSlice.actions.setLoadingLanguage(false));
                })
                .catch(() => {
                  dispatch(userSlice.actions.setLoadingLanguage(false));
                });
            } else {
            }
          },
          errorCallback: (error) => {
            dispatch(userSlice.actions.setLoadingLanguage(false));
          },
        },
        dispatch,
      );
    } else {
      // Reseting meStatus to ""
      dispatch(userSlice.actions.meFail(""));
      getCurrenciesXHR({}, dispatch);
    }

    // Update english weekdays - monday first
    moment.updateLocale("en", { week: { dow: 1 } });
    // Update german weekdays - monday first
    moment.updateLocale("de", { week: { dow: 0 } });
  }, []);

  useEffect(() => {
    // Configure moment.js
    if (user.account.date_format) moment.defaultFormat = user.account.date_format;
    else moment.defaultFormat = "DD.MM.YYYY - HH:mm";
  }, [user.account]);

  // Locale
  const locales: Locales = { en, hr, bs: hr, rs, de };

  useEffect(() => {
    // Change moment locale
    let language = MOMENT_LANGUAGES[user.account.language!];
    moment.locale(language);
    if (!loadingLanguage) {
      dispatch(userSlice.actions.setLoadingLanguage(true));
      i18next
        .changeLanguage(I18N_LANGUAGES[user.account.language!])
        .then(() => {
          dispatch(userSlice.actions.setLoadingLanguage(false));
        })
        .catch(() => {
          dispatch(userSlice.actions.setLoadingLanguage(false));
        });
    }
  }, [user?.account?.language]);

  // Themes
  const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
  };

  const termsOfService = useMemo(() => {
    if (user) {
      return (
        <TermsOfService
          visible={!user.account.accepted_terms}
          footerVisible
          title={t(TRANSLATION_KEY.termsOfServiceModalTitle)}
        />
      );
    }
  }, [user.account.accepted_terms]);

  // Loading
  if (meStatus === "loading") {
    return <Loading />;
  }

  return (
    <ConfigProvider locale={locales[MOMENT_LANGUAGES[user.account.language!]]}>
      <Spin spinning={loadingLanguage}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
          <AppWraper />
          {/* Terms of service */}
          {termsOfService || null}
        </ThemeSwitcherProvider>
      </Spin>
    </ConfigProvider>
  );
}

export default App;
