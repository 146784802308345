import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { INITIAL_TIMESTAMP } from "../constants";
import { RRuleContextType } from "../useRRule";
import TimePickerChip from "./TimePickerChip";

interface IProps {
  rrule: RRuleContextType;
}

const DayFrequency = ({ rrule: { timestamps, action, setTime, clearTime } }: IProps) => {
  // Constants
  const pickerHeight: number = 32;

  // Variables
  const dayTimestamps: number[] = (timestamps ?? []) as number[];
  const initialIndex = dayTimestamps ? (dayTimestamps?.length > 0 ? 0 : undefined) : undefined;
  const [firstTimestamp, ...otherTimestamps] = dayTimestamps;

  // Methods
  function addNewTimestamp() {
    setTime(INITIAL_TIMESTAMP, otherTimestamps.length + 1);
  }

  return (
    <Space
      align="center"
      style={{
        height: pickerHeight,
      }}
    >
      <div
        style={{
          overflowX: "auto",
          overflowY: "hidden",
          width: "100%",
          maxWidth: 276,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "max-content",
          }}
        >
          <TimePickerChip
            value={firstTimestamp !== undefined ? firstTimestamp : undefined}
            onChange={(_, dateString) => setTime(dateString, initialIndex)}
            timePickerProps={{ allowClear: false }}
          />
          {otherTimestamps &&
            otherTimestamps.map((timestamp, index) => (
              <TimePickerChip
                key={index}
                value={timestamp}
                onChange={(date, dateString) => {
                  if (date) {
                    setTime(dateString, index + 1);
                  } else {
                    clearTime(index + 1);
                  }
                }}
              />
            ))}
        </div>
      </div>
      <AnimatePresence>
        {timestamps && timestamps.length > 0 && (
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ ease: "anticipate" }}
            style={{ maxHeight: pickerHeight }}
          >
            <Button
              onClick={addNewTimestamp}
              shape="circle"
              type="ghost"
              icon={<PlusOutlined style={{ opacity: 0.35 }} />}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Space>
  );
};

export default DayFrequency;
