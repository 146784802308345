import React, { useEffect, useState } from "react";

// Antd
import { Drawer, message } from "antd";

// Rest
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import {
  createPartExportFileXHR,
  getPartsXHR,
} from "../../../store/reducers/warehouse/actionCreator";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import Table from "../components/Table";
import { ICursor } from "../../../models";
import { unstable_batchedUpdates } from "react-dom";
import { IPart } from "../../../models/parts";
import AssetManagement from "../../part/components/AssetManagment";
import { getExcelFileXHR } from "../../../store/reducers/settings/actionCreator";

export interface ILocalFilters {
  search: string;
  warehouse: string[];
  asset: string[];
}

const AllParts: React.FC = () => {
  // Hooks

  const dispatch = useDispatch();

  // Variables

  const { parts, getPartsStatus } = useAppSelector((state) => state.warehouseReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const [assetManagment, set_assetManagment] = useState<
    { part: IPart; asset: IPart["assets"] } | undefined
  >();

  const [localFilters, set_localFilters] = useState<ILocalFilters>({
    search: "",
    warehouse: [],
    asset: [],
  });

  const [exportLoading, set_exportLoading] = useState<boolean>(false);

  const [localQueryParams, set_localQueryParams] = useState<ICursor>({ ...parts.cursor });

  // Methods
  useEffect(() => getParts(localQueryParams, localFilters, false), []);

  function getParts(queryParams: ICursor, filters: ILocalFilters, mergeData: boolean) {
    if (queryParams.cursor === null && !!parts.data.length) {
      return;
    }
    let tmp: Omit<ILocalFilters, "warehouse" | "asset"> &
      ICursor & { warehouse: string; assets: string } = {
      ...queryParams,
      warehouse: filters.warehouse.join("|"),
      search: filters.search,
      assets: filters.asset.join("|"),
    };
    getPartsXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data) => {
          if (data.results) {
            unstable_batchedUpdates(() => {
              set_localQueryParams(data.results?.cursor!);
              set_localFilters(filters);
            });
          }
        },
        queryParams: tmp,
        mergeData,
      },
      dispatch,
    );
  }

  const exportToExcel = () => {
    createPartExportFileXHR(
      {
        body: {
          warehouse: localFilters.warehouse.join("|"),
          search: localFilters.search,
          assets: localFilters.asset.join("|"),
        },
        successCallback: (data) => {
          getExcelFileXHR(
            {
              queryParams: {
                uid: data.results?.uid,
              },
              successCallback: (data) => {
                set_exportLoading(false);

                const byteCharacters = atob(data.results?.file_content || "");
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const url = window.URL.createObjectURL(
                  new Blob([byteArray], {
                    type: "application/xlsx",
                  }),
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${t(TRANSLATION_KEY.parts)}.xlsx`);
                document.body.appendChild(link);
                link.click();
              },
              errorCallback: (error) => {
                set_exportLoading(false);
                message.error(t(TRANSLATION_KEY.errorOnGetData));
              },
            },
            dispatch,
          );
        },
        errorCallback: (error) => {
          set_exportLoading(false);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
  };

  const memoData = React.useMemo(() => {
    return {
      ...parts,
      cursor: localQueryParams,
    };
  }, [parts, localQueryParams]);

  return (
    // TESTIRAT DALI RADI SA LISTOM KAK TREBA sa filterima i to
    <>
      <Table
        user={user}
        exportLoading={exportLoading}
        exportToExcel={exportToExcel}
        searchByInfo={t(TRANSLATION_KEY.searchGoesByNameCodeCategory)}
        getParts={(filters: ILocalFilters) => {
          getParts(parts.cursor, filters, false);
        }}
        cursor={localQueryParams}
        setFilters={set_localFilters}
        filters={localFilters}
        data={memoData}
        loading={getPartsStatus === "loading"}
        onLoadMore={() => {
          getParts(localQueryParams, localFilters, true);
        }}
        setAssetManagment={(part: IPart, asset: IPart["assets"]) => {
          set_assetManagment({ part, asset });
        }}
      />
      <Drawer
        width={540}
        visible={!!assetManagment}
        onClose={() => set_assetManagment(undefined)}
        destroyOnClose={true}
        title={t(TRANSLATION_KEY.userAsset) + " " + assetManagment?.part.name}
      >
        {assetManagment && (
          <AssetManagement part={assetManagment.part} asset={assetManagment?.asset || []} />
        )}
      </Drawer>
    </>
  );
};

export default AllParts;
