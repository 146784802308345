import React, { useEffect, useMemo, useState } from "react";
import { FileExclamationOutlined, PauseCircleOutlined, PauseOutlined } from "@ant-design/icons";
import { Card, Drawer, Input, List, Modal, Statistic } from "antd";
import { t } from "i18next";
import PartItem from "../../../warehouse/components/PartItem";
import MaintenanceListItem from "./MaintenanceListItem";
import { IMaintenance } from "../../../../models/maintenances";
import { useAppSelector } from "../../../../hooks";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import MaintenanceModalPreview from "../../../maintenance/components/ModalPreview";

interface IProps {}

const PausedOrders: React.FC<IProps> = ({}) => {
  // Variables
  const [drawerVisible, set_drawerVisible] = useState(false);
  const [search, set_search] = useState<string>("");
  const [orders, set_orders] = useState<IMaintenance[]>([]);
  const [selectedMaintenance, set_selectedMaintenance] = useState<number | undefined>(undefined);

  const { liveMaintenances } = useAppSelector((state) => state.maintenanceReducer);

  useEffect(() => {
    set_orders(liveMaintenances.filter((order) => order.status === "held"));
  }, [liveMaintenances]);

  // Data
  let filteredData = useMemo(() => {
    if (search) {
      return orders.filter(
        (item) =>
          item?.description.toLowerCase().includes(search) ||
          item?.order_number.toLowerCase().includes(search),
      );
    }
    return orders;
  }, [orders, search]);

  return (
    <>
      <Card
        className="dashboard-card"
        onClick={() => set_drawerVisible(true)}
        style={{ width: "100%", minWidth: 240, cursor: "pointer" }}
      >
        <Statistic
          title={t(TRANSLATION_KEY.pausedOrders)}
          value={orders.length}
          prefix={<PauseCircleOutlined />}
        />
      </Card>

      <Drawer
        forceRender
        visible={drawerVisible}
        onClose={() => set_drawerVisible(false)}
        closable
        title={t(TRANSLATION_KEY.pausedOrders)}
        width={700}
      >
        {/* {data} */}
        <List
          itemLayout="horizontal"
          dataSource={filteredData}
          header={
            <Input.Search
              placeholder={t(TRANSLATION_KEY.searchByName)}
              onChange={(e) => set_search(e.target.value.toLowerCase())}
              style={{ marginBottom: 10, marginTop: -12 }}
              allowClear
              enterButton
            />
          }
          renderItem={(item: IMaintenance) => (
            <MaintenanceListItem
              item={item}
              containerStyle={{ padding: "10px 4px" }}
              buttonText={t(TRANSLATION_KEY.confirm)}
              onPreview={() => {
                set_selectedMaintenance(item.id);
              }}
            />
          )}
        />
      </Drawer>

      <Modal
        visible={!!selectedMaintenance}
        onCancel={() => {
          set_selectedMaintenance(undefined);
        }}
        footer={null}
        centered
        width="800px"
        closable={false}
        destroyOnClose
      >
        <MaintenanceModalPreview id={selectedMaintenance} />
      </Modal>
    </>
  );
};

export default PausedOrders;
