import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import moment from "moment";

// Antd
import { message, Modal, Typography } from "antd";

// Rest
import MaintenancesTable from "../../../components/MaintenancesTable";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";
import { getMaintenanceListXHR } from "../../../store/reducers/maintenance/actionCreator";
import CalendarMaintenances from "../../maintenances/Calendar";
import { ICursor } from "../../../models";
import { unstable_batchedUpdates } from "react-dom";
import { useParams } from "react-router-dom";
import { debounce, filter } from "../../../helpers/functions";
import { MAINTENANCES_LIST_LIMIT } from "../../maintenances/table";
import { CalendarTypes } from "../../../components/DatePickerCustom";
interface ILocalFilters {
  from: string | undefined;
  to: string | undefined;
  search: string;
  offset: number;
  limit: number;
  order_by: "-created_at";
}

const Maintenances: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();

  // Variables
  const [showCalendar, set_showCalendar] = useState<boolean>(false);
  const { id } = useParams();
  const { maintenanceList, getMaintenanceListStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const { account } = useAppSelector((state) => state.accountsReducer);
  const [showLoadMore, set_showLoadMore] = useState<boolean>(true);
  const [filters, set_filters] = useState<ILocalFilters>({
    search: "",
    order_by: "-created_at",
    from: moment().startOf("month").toISOString(true),
    offset: 0,
    limit: MAINTENANCES_LIST_LIMIT,
    to: moment().endOf("month").toISOString(true),
  });

  const getMaintenanceList = (filters: ILocalFilters, mergeData: boolean) => {
    if (!mergeData) {
      set_showLoadMore(true);
    }

    getMaintenanceListXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          order_by: filters.order_by,
          created_at__gte: filters.from?.toString(),
          created_at__lte: filters.to?.toString(),
          limit: filters.limit,
          offset: filters.offset,
          order__order_number__icontains: filters.search,
          description__icontains: filters.search,
          executors__account__in: id,
          status: "open,assigned,running,held,completed,requested,closed",
        },
        mergeData,
        successCallback: (data) => {
          if (data.results && data.results.length < MAINTENANCES_LIST_LIMIT) {
            set_showLoadMore(false);
          }
          if (data.results) {
            unstable_batchedUpdates(() => {
              set_filters({ ...filters, offset: filters.offset + (data.results?.length || 0) });
            }, []);
          }
        },
      },
      dispatch,
    );
  };

  function handleOnDateChanged(
    start: string | undefined,
    end: string | undefined,
    calendarType: CalendarTypes | undefined,
  ) {
    getMaintenanceList(
      {
        ...filters,
        offset: 0,
        from: start,
        to: end,
      },
      false,
    );
  }

  function handleLoadMore() {
    getMaintenanceList(filters, true);
  }

  const debounceOnSearch = (obj: ILocalFilters) => {
    getMaintenanceList(obj, false); // if any filter paramaters change strat load more from start
  };

  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);

  function handleOnSearch(search: string) {
    let obj: ILocalFilters = { ...filters, search: search };
    setSearch(obj);
  }

  return (
    <div className="white-container">
      {/* Title */}
      <div className="spaceBetweenRow">
        <Typography.Title level={5} style={{ paddingTop: 8, marginBottom: -2 }}>
          {t(TRANSLATION_KEY.maintenances)}
        </Typography.Title>
      </div>

      {/* Table */}
      <MaintenancesTable
        start={filters.from || ""}
        end={filters.to || ""}
        data={maintenanceList}
        showLoadMore={showLoadMore}
        loading={getMaintenanceListStatus === "loading"}
        onDateChanged={handleOnDateChanged}
        onLoadMore={handleLoadMore}
        onSearch={handleOnSearch}
        showOpenCalendarButton={false}
        handleOpenCalendar={() => set_showCalendar(true)}
        working_data_show={true}
      />

      {/* Calendar */}
      <Modal
        title={account?.name}
        visible={showCalendar}
        onCancel={() => set_showCalendar(false)}
        footer={null}
        width="90vw"
        centered
        style={{ overflow: "hidden" }}
      >
        <CalendarMaintenances executor_accounts={[account?.id || -1]} fromModal />
      </Modal>
    </div>
  );
};

export default Maintenances;
