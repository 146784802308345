import { message, Modal, Space, Table, TableColumnType, Typography } from "antd";
import axios from "axios";
import { t } from "i18next";
import { useState } from "react";
import LoadMoreButton from "../../../../components/LoadMoreButton";
import PreviewButton from "../../../../components/PreviewButton";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { parseCosts } from "../../../../helpers/functions";
import { IApiResponse, ILoadMoreParams } from "../../../../models";
import { failedQueue, isRefreshing } from "../../../../services";
import AssetModalPreview from "../../../assetdetails/components/AssetModalPreview";
import Loader from "../../components/Loader";
import Filters, { IFilters } from "./components/Filters";
import { Link } from "react-router-dom";

type Props = {};

type TableData = {
  id: number;
  name: string;
  work_expense: number;
  part_expense: number;
  other_expense: number;
  total_expense: number;
};

// Constants
const LIMIT_SIZE = 30;

const ByClientReport = (props: Props) => {
  // State
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [generalLoading, set_generalLoading] = useState(false);
  const [loadMoreFilters, setl_loadMoreFilters] = useState<
    IFilters & { offset: number; limit: number }
  >({
    maintenance_categories: [],
    end: "",
    start: "",
    maintenance_type: [],
    calendarType: "month",
    executors: [],
    offset: 0,
    limit: LIMIT_SIZE,
  });

  const [loadMoreButtonVisible, set_loadMoreButtonVisible] = useState<boolean>(true);
  const [assetloading, set_assetLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableData[]>([]);

  // Functions
  const fetchTableData = async (
    filters: IFilters & { offset: number; limit: number },
    mergeData: boolean,
  ) => {
    set_assetLoading(true);
    if (!mergeData) {
      set_loadMoreButtonVisible(true);
    }
    let token = await localStorage.getItem("token");
    let params = {
      maintenance_categories: filters.maintenance_categories.join("|"),
      end: filters.end,
      start: filters.start,
      maintenance_type: filters.maintenance_type.join("|"),
      offset: filters.offset,
      limit: filters.limit,
      executors: filters.executors?.join("|"),
    };

    axios
      .get<IApiResponse<TableData[]>>(
        process.env.REACT_APP_HOST_BACKEND + "/api/clients/analytics/by_client_analytics/",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          params,
        },
      )
      .then((response) => {
        if (!response.data.results) {
          return;
        }
        if (response.data.results.length < filters.limit) {
          set_loadMoreButtonVisible(false);
        }
        setl_loadMoreFilters({ ...filters, offset: filters.offset + response.data.results.length });

        if (!mergeData) {
          setTableData(response.data.results);
        } else {
          // Merge the new data with the old data
          setTableData([...tableData, ...response.data.results]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          if (isRefreshing) {
            failedQueue.push(() => fetchTableData(filters, mergeData));
          }
          return;
        }
        console.log(error);
        message.error(t(TRANSLATION_KEY.errorOnGetData));
      })
      .finally(() => {
        set_assetLoading(false);
      });
  };

  // Columns
  let COLUMNS: TableColumnType<TableData>[] = [
    {
      dataIndex: "name",
      title: t(TRANSLATION_KEY.name),
      render: (text: string, value: TableData) => {
        if (value.id && value.name) {
          return (
            <Link to={`/app/clients/${value.id}`} target="_blank">
              <Typography.Link>{value.name}</Typography.Link>
            </Link>
          );
        }
      },
    },
    {
      dataIndex: "total_expense",
      title: t(TRANSLATION_KEY.totalCosts),
      render: (text: string, value: TableData) => parseCosts(text),
    },
    {
      dataIndex: "work_expense",
      title: t(TRANSLATION_KEY.workExpense),
      render: (text: string, value: TableData) => parseCosts(text),
    },
    {
      dataIndex: "part_expense",
      title: t(TRANSLATION_KEY.partExpense),
      render: (text: string, value: TableData) => parseCosts(text),
    },
    {
      dataIndex: "other_expense",
      title: t(TRANSLATION_KEY.expenses),
      render: (text: string, value: TableData) => parseCosts(text),
    },
  ];

  return (
    <>
      {/* Spinner */}
      <Loader isLoading={generalLoading} />

      {/* Filters */}
      <Filters
        onChange={(f) => {
          fetchTableData({ ...f, offset: 0, limit: loadMoreFilters.limit }, false);
        }}
      />

      <div style={{ marginTop: 24 }}>
        <Table dataSource={tableData} loading={assetloading} pagination={false} columns={COLUMNS} />

        {loadMoreButtonVisible && (
          <LoadMoreButton
            loading={assetloading}
            onClick={() => {
              fetchTableData(loadMoreFilters, true);
            }}
          >
            {t(TRANSLATION_KEY.loadMore)}
          </LoadMoreButton>
        )}
      </div>

      {/* Asset preview */}
      <Modal
        visible={modalVisible}
        onCancel={() => set_modalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AssetModalPreview id={selectedAsset} />
      </Modal>
    </>
  );
};

export default ByClientReport;
