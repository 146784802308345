import { PlusOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import { motion } from "framer-motion";
import moment from "moment";
import { INITIAL_TIMESTAMP } from "../constants";
import { Days } from "../types";
import TimePickerChip from "./TimePickerChip";
import { RRuleContextType } from "../useRRule";

interface IProps {
  rrule: RRuleContextType;
}

const WeekFrequency = ({ rrule }: IProps) => {
  // Constants
  const pickerHeight: number = 32;

  return (
    <Space
      direction="vertical"
      size={12}
      style={{ paddingInline: 8, paddingBottom: 12, paddingTop: 4 }}
    >
      {Object.entries(rrule.weekTimestamps).map(([day, timestamps]) => {
        let currentDay = day as unknown as Days;
        return (
          <Space key={currentDay} align="center" style={{ height: pickerHeight }}>
            {/* Weekday */}
            <Typography style={{ textTransform: "capitalize", width: 100 }}>
              {moment.weekdays(true)[currentDay]}
            </Typography>

            {/* Weekday timestamps */}
            <div
              style={{
                overflowX: "auto",
                overflowY: "hidden",
                width: "100%",
                maxWidth: 460,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "max-content",
                }}
              >
                <Space direction="horizontal" size={2}>
                  {timestamps?.map((timestamp, index) => (
                    <TimePickerChip
                      key={index}
                      value={timestamp}
                      onChange={(date, dateString) => {
                        if (date) {
                          rrule.setWeekTime(dateString, index, currentDay);
                        } else {
                          rrule.clearWeekTime(index, currentDay);
                        }
                      }}
                    />
                  ))}
                </Space>
              </div>
            </div>

            {/* New timestamp button */}
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ ease: "anticipate" }}
              style={{ maxHeight: pickerHeight }}
            >
              <Button
                onClick={() =>
                  rrule.setWeekTime(INITIAL_TIMESTAMP, timestamps?.length || 0, currentDay)
                }
                shape="circle"
                type="ghost"
                icon={<PlusOutlined style={{ opacity: 0.35 }} />}
              />
            </motion.div>
          </Space>
        );
      })}
    </Space>
  );
};

export default WeekFrequency;
