import moment from "moment";
import { Options, RRule } from "rrule";
import { DATE_FORMATS_WITHOUT_HOURS } from "../../helpers/consts";
import { ITemplateDetails } from "../../models/maintenances";
import { INITIAL_TIMESTAMP, initialWeekTimestamps, RRULE_TIME_FORMAT } from "./constants";
import { Days, Frequency, Rule, Timestamps, WeekTimestamps } from "./types";

/**
 * ! DEPRECATED DOCS - Function now returns 0
 * -----------------------------------------------------------------------------------------------------------------
 * Calculates the total minutes from a time string in HH:mm format.
 *
 * @param {string} timeString - The time string in HH:mm format.
 * @returns {number} The total minutes calculated from the time string, limited to 1440 minutes (24 hours).
 * @throws {Error} Throws an error if the time string is not in the expected format.
 *
 * @example
 * // Returns 90
 * calculateMinutesFromTimeString('01:30');
 *
 * @example
 * // Returns 255
 * calculateMinutesFromTimeString('04:15');
 *
 * @example
 * // Returns 822
 * calculateMinutesFromTimeString('13:42');
 */
export function calculateMinutesFromTimeString(timeString: string): number {
  return 0;
  // const [hours, minutes] = timeString.split(":").map(Number);
  // if (isNaN(hours) || isNaN(minutes)) {
  //   throw new Error("Invalid time string format");
  // }

  // const totalMinutes = hours * 60 + minutes;

  // //Limit to 1440 minutes (24 hours)
  // return Math.min(totalMinutes, 1440);
}

/**
 * Calculates the hours from a time string in the format "HH:MM".
 *
 * @param {string} timeString - The time string in the format "HH:MM".
 * @returns {number} The hours extracted from the time string.
 *
 * @example
 * // Returns 8
 * calculateHoursFromTimeString("08:30");
 *
 * @example
 * // Returns 15
 * calculateHoursFromTimeString("15:45");
 *
 * @example
 * // Returns 23
 * calculateHoursFromTimeString("23:15");
 */
export function calculateHoursFromTimeString(timeString: string): number {
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours;
}

/**
 * Converts total minutes to a time string in HH:mm format.
 *
 * @param {number} totalMinutes - The total minutes to convert.
 * @returns {string} The time string in HH:mm format.
 * @throws {Error} Throws an error if the total minutes are not within a valid range (0 to 1440).
 *
 * @example
 * // Returns '01:30'
 * convertMinutesToTimeString(90);
 *
 * @example
 * // Returns '04:15'
 * convertMinutesToTimeString(255);
 *
 * @example
 * // Returns '13:42'
 * convertMinutesToTimeString(822);
 */
export function convertMinutesToTimeString(totalMinutes: number): string {
  if (totalMinutes < 0 || totalMinutes > 1440) {
    throw new Error("Total minutes must be between 0 and 1440");
  }

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const hoursString = hours.toString().padStart(2, "0");
  const minutesString = minutes.toString().padStart(2, "0");

  return `${hoursString}:${minutesString}`;
}

/**
 * Converts total minutes to a Moment object using the specified time format.
 *
 * @param {number} minutes - The total minutes to convert.
 * @returns {moment.Moment} A Moment object representing the time derived from the total minutes.
 * @throws {Error} Throws an error if the total minutes are not within a valid range (0 to 1440).
 *
 * @example
 * // Returns a Moment object representing '01:30'
 * convertMinutesToMoment(90);
 *
 * @example
 * // Returns a Moment object representing '04:15'
 * convertMinutesToMoment(255);
 *
 * @example
 * // Returns a Moment object representing '13:42'
 * convertMinutesToMoment(822);
 */
export function convertMinutesToMoment(minutes: number): moment.Moment {
  return moment(convertMinutesToTimeString(minutes), RRULE_TIME_FORMAT);
}

/**
 * Converts total hours to a Moment object using the specified time format.
 *
 * @param {number} hours - The total hours to convert.
 * @returns {moment.Moment} A Moment object representing the time derived from the total hours.
 *
 * @example
 * // Returns a Moment object representing '08:00'
 * convertHoursToMoment(8);
 *
 * @example
 * // Returns a Moment object representing '13:00'
 * convertHoursToMoment(13);
 *
 * @example
 * // Returns a Moment object representing '23:00'
 * convertHoursToMoment(23);
 */
export function convertHoursToMoment(hours: number): moment.Moment {
  return moment(`${hours.toString().padStart(2, "0")}:00`, RRULE_TIME_FORMAT);
}

export function convertMomentToHours(moment: moment.Moment): number {
  return moment.hours();
}

/**
 * Gets the current time zone offset in hours.
 *
 * The time zone offset represents the difference between Coordinated Universal Time (UTC)
 * and the local time, measured in hours. Positive values indicate time zones ahead of UTC,
 * and negative values indicate time zones behind UTC.
 *
 * @returns {number} The current time zone offset in hours.
 *
 * @example
 * // Returns -2
 * getCurrentTimeZoneOffset();
 */
export function getCurrentTimeZoneOffset(): number {
  const date = new Date();
  const offsetInMinutes = date.getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;

  return offsetInHours;
}

/**
 * Parses the "byminute" property from the options, ensuring it is an array of numbers.
 *
 * The "byminute" property represents the minutes within the hour for which the recurrence is valid.
 * This function checks if the input is a valid "byminute" property and returns it as an array of numbers,
 * or returns `undefined` if the input is null, undefined, or an invalid type.
 *
 * @param {Options["byminute"]} byminute - The "byminute" property from the options.
 * @returns {Timestamps} An array of numbers representing the "byminute" property or undefined if invalid.
 *
 * @example
 * // Returns [15]
 * parseByminute({ byminute: 15 });
 *
 * @example
 * // Returns undefined
 * parseByminute(null);
 *
 * @example
 * // Returns [0, 15, 30]
 * parseByminute({ byminute: [0, 15, 30] });
 */
export function parseByminute(byminute: Options["byminute"] | undefined): Timestamps {
  if (byminute === null || byminute === undefined) {
    return undefined;
  }

  if (typeof byminute === "number") {
    return [byminute];
  }

  if (Array.isArray(byminute) && byminute.every((num) => typeof num === "number")) {
    return byminute;
  }

  return undefined;
}

/**
 * Parses the "byhour" property from the options, ensuring it is an array of numbers.
 *
 * The "byhour" property represents the hours within the day for which the recurrence is valid.
 * This function checks if the input is a valid "byhour" property and returns it as an array of numbers,
 * or returns `undefined` if the input is null, undefined, or an invalid type.
 *
 * @param {Options["byhour"]} byhour - The "byhour" property from the options.
 * @returns {Timestamps} An array of numbers representing the "byhour" property or undefined if invalid.
 *
 * @example
 * // Returns [12]
 * parseByhour(12);
 *
 * @example
 * // Returns undefined
 * parseByhour(null);
 *
 * @example
 * // Returns [8, 15, 20]
 * parseByhour([8, 15, 20]);
 */
export function parseByhour(byhour: Options["byhour"] | undefined): Timestamps {
  if (byhour === null || byhour === undefined) {
    return undefined;
  }

  if (typeof byhour === "number") {
    return [byhour];
  }

  if (Array.isArray(byhour) && byhour.every((num) => typeof num === "number")) {
    return byhour;
  }

  return undefined;
}

/**
 * Removes the time component (hours, minutes, and seconds) from a given date format.
 *
 * @param {string} dateTimeFormat - The original date format that may include time information.
 * @returns {string} The date format without the time component.
 *
 * @example
 * // Returns "DD.MM.YYYY"
 * removeHoursFromDateFormat("DD.MM.YYYY - HH:mm");
 *
 * @example
 * // Returns "MM.DD.YYYY"
 * removeHoursFromDateFormat("MM.DD.YYYY - HH:mm");
 */
export function removeHoursFromDateFormat(dateTimeFormat: string): string {
  const formatWithoutHours = DATE_FORMATS_WITHOUT_HOURS[dateTimeFormat];
  return formatWithoutHours || dateTimeFormat;
}

/**
 * Checks if a specific day in the WeekTimestamps has timestamps.
 *
 * @param {WeekTimestamps} weekTimestamps - The WeekTimestamps object representing timestamps for each day.
 * @param {Days} day - The day for which to check if timestamps exist.
 * @returns {boolean} - Returns true if the specified day has timestamps, otherwise false.
 *
 * @example
 * // Assuming Days enum is defined as: 0 | 1 | 2 | 3 | 4 | 5 | 6;
 * const timestamps = {
 *   0: [10, 20, 30],
 *   1: [15, 25],
 *   // ...
 * };
 * const hasTimestamps = weekDayHasTimestamps(timestamps, 0);
 * // Result: true
 */
export function weekDayHasTimestamps(weekTimestamps: WeekTimestamps, day: Days): boolean {
  const timestampsForDay = weekTimestamps[day];
  return timestampsForDay !== undefined && timestampsForDay.length > 0;
}

/**
 * Checks if a week has no timestamps for any day.
 *
 * @param weekTimestamps - The weekTimestamps object representing timestamps for each day.
 * @returns `true` if the week has no timestamps for any day, `false` otherwise.
 */
export function weekHasNoTimestamps(weekTimestamps: WeekTimestamps): boolean {
  /**
   * Iterates over the values of `weekTimestamps` and checks if any day has timestamps.
   *
   * @param timestamps - The timestamps for a specific day.
   * @returns `true` if the day has timestamps, `false` otherwise.
   */
  for (const timestamps of Object.values(weekTimestamps)) {
    if (timestamps && timestamps.length > 0) {
      return false; // Week has timestamps
    }
  }
  return true; // Week has no timestamps
}

/**
 * Checks if deleting the last timestamp from any day in the weekTimestamps object.
 *
 * @param weekTimestamps - The weekTimestamps object representing timestamps for each day.
 * @returns True if deleting the last timestamp from any day, otherwise false.
 */
export function deletingLastWeekTimestamp(weekTimestamps: WeekTimestamps): boolean {
  const daysWithTimestamps = Object.keys(weekTimestamps).filter(
    (day) => weekTimestamps[day].length > 0,
  );

  // Return true if there's exactly one day with timestamps and that day has only one timestamp
  return daysWithTimestamps.length === 1 && weekTimestamps[daysWithTimestamps[0]].length === 1;
}

/**
 * Checks if deleting the last timestamp from a specific day in the weekTimestamps object.
 *
 * @param weekTimestamps - The weekTimestamps object representing timestamps for each day.
 * @param day - The day for which to check if the last timestamp is being deleted.
 * @returns True if deleting the last timestamp from the specified day, otherwise false.
 */
export function deletingLastTimestampFromDay(weekTimestamps: WeekTimestamps, day: Days): boolean {
  return weekTimestamps[day]!.length === 1;
}

/**
 * Finds the day of the week that has the initial timestamp.
 *
 * @param {WeekTimestamps} weekTimestamps - The week timestamps object.
 * @returns {Days | undefined} - The day of the week (0-6) or undefined if not found.
 */
export const findDayWithInitialTimestamp = (weekTimestamps: WeekTimestamps): Days | undefined => {
  /**
   * The initial timestamp in minutes.
   * @type {number}
   */
  const initialTimestampMinutes: number = calculateHoursFromTimeString(INITIAL_TIMESTAMP);

  for (const day in weekTimestamps) {
    /**
     * The timestamps for the current day.
     * @type {number[]}
     */
    const timestamps: number[] = weekTimestamps[day] || [];

    if (timestamps.includes(initialTimestampMinutes)) {
      return Number(day) as Days;
    }
  }

  return undefined; // return undefined if not found
};

/**
 * Calculates the total number of seconds based on the specified frequency and interval.
 * @param every The interval at which the action occurs. Must be a non-negative integer.
 * @param period The frequency unit of the interval. It should be one of "hours", "days", "weeks", "months", or "years".
 * @returns The total number of seconds corresponding to the given interval and frequency.
 * @throws {Error} If the 'every' parameter is negative or if an invalid 'period' is specified.
 */
export function calculateSecondsFromEveryAndPeriod(every: number, period: Frequency): number {
  if (every < 0) {
    throw new Error("Invalid 'every' value. It should be non-negative.");
  }

  let totalSeconds = 0;
  switch (period) {
    case "hours":
      totalSeconds = every * 3600; // 1 hour = 3600 seconds
      break;
    case "days":
      totalSeconds = every * 86400; // 1 day = 86400 seconds
      break;
    case "weeks":
      totalSeconds = every * 604800; // 1 week = 604800 seconds
      break;
    case "months":
      totalSeconds = every * 2629746; // Approximation for 1 month = 2629746 seconds
      break;
    case "years":
      totalSeconds = every * 31556952; // Approximation for 1 year = 31556952 seconds
      break;
    default:
      throw new Error("Invalid period specified");
  }

  return totalSeconds;
}

//
/**
 * Parses a string of RRule into dtstart and an array of rules.
 *
 * @param {string} rruleString - The string of RRule to parse.
 * @returns {[string, RRule[]]} An array containing the dtstart and an array of RRule objects.
 * @throws {Error} Throws an error if the RRule string is not in the expected format.
 *
 * @example
 * // Returns ['2022-01-01T00:00:00Z', [RRule.fromString('FREQ=DAILY;INTERVAL=1'), RRule.fromString('FREQ=WEEKLY;INTERVAL=2')]]
 * parseRRuleString('DTSTART:20220101T000000Z\nRRULE:FREQ=DAILY;INTERVAL=1\nRRULE:FREQ=WEEKLY;INTERVAL=2');
 */
export function parseRRuleString(rruleString: string, dtstart: string): [Date, RRule[]] {
  const lines = rruleString.split("\n");
  // const dtstartLine = lines.find((line) => line.startsWith("DTSTART:"));
  // if (!dtstartLine) {
  //   throw new Error("Invalid RRule string: missing DTSTART");
  // }
  // // Create date from string that looks like this: DTSTART:20240327T131106Z
  // let year = Number(dtstartLine.substring(8, 12));
  // let month = Number(dtstartLine.substring(12, 14));
  // let day = Number(dtstartLine.substring(14, 16));
  // const dtstart = datetime(year, month, day);
  const parsedDtstart = new Date(dtstart);
  const rruleLines = lines.filter((line) => line.startsWith("RRULE:"));
  const rules = rruleLines.map((line) => RRule.fromString(line.substring(6)));
  rules.map((rule) => {
    if (rule.options.bymonth) {
      // @ts-expect-error
      rule.options.bymonth = rule.options.bymonth[0];
    }
    if (rule.options.bymonthday) {
      // @ts-expect-error
      rule.options.bymonthday = rule.options.bymonthday[0];
    }
    if (rule.options.bynmonthday) {
      // @ts-expect-error
      rule.options.bynmonthday = rule.options.bynmonthday[0];
    }
    if (rule.options.byweekday) {
      // @ts-expect-error
      rule.options.byweekday = rule.options.byweekday[0];
    }
    if (rule.options.byweekno) {
      // @ts-expect-error
      rule.options.byweekno = rule.options.byweekno[0];
    }
    if (rule.options.byyearday) {
      // @ts-expect-error
      rule.options.byyearday = rule.options.byyearday[0];
    }
    if (rule.options.byhour) {
      rule.options.byhour = rule.options.byhour.map((hour) => Number(hour));
    }
    if (rule.options.bysecond) {
      // @ts-expect-error
      rule.options.bysecond = Number(rule.options.bysecond);
    }
  });
  return [parsedDtstart, rules];
}

// Generate function that sets rule from template if there is template using parseRRuleString function - else set initialRule
export const setRule = (template: ITemplateDetails | undefined, initialRule: Rule): Rule[] => {
  if (template?.recurrence_rule) {
    const [dtstart, rules] = parseRRuleString(
      template.recurrence_rule,
      template.recurrence_dtstart,
    );
    const newRules: Rule[] = rules.map((rule) => {
      return {
        ...rule.options,
        dtstart,
      };
    });
    return newRules;
  }
  return [initialRule] as Rule[];
};

export const getByhour = (rules: Rule[]): WeekTimestamps | any => {
  // Map through initialWeekTimestamps
  let weekTimestamps: WeekTimestamps = {
    "0": [],
    "1": [],
    "2": [],
    "3": [],
    "4": [],
    "5": [],
    "6": [],
  };
  Object.keys(initialWeekTimestamps).map((day) => {
    let rule = rules.find((rule) => {
      if (rule.byweekday != null) {
        return rule.byweekday === Number(day);
      }
      return false;
    });
    if (rule) {
      weekTimestamps[day] = rule.byhour;
    }
  });
  return weekTimestamps;
};

export const setInitialWeekTimestamps = (
  template: ITemplateDetails | undefined,
  rules: Rule[],
  initialWeekTimestamps: WeekTimestamps,
): WeekTimestamps => {
  if (!template) return initialWeekTimestamps;
  if (!template.recurrence_rule) return initialWeekTimestamps;
  return getByhour(rules);
};

export const setInitialTimestamps = (rules: Rule[]): Timestamps => {
  const byhour = rules[0]?.byhour ?? 0;
  return parseByhour(byhour);
};
