import { Col, Row } from "antd";
import CheckList from "../../components/checklist";
import Executors from "./Executors";
import Materials from "../Materials";
import UserReports from "../UserReports";
import React from "react";
import { IMaintenanceDetails } from "../../../../models/maintenances";
import { ILayoutGrids } from "../../../../models";
import Times from "../Times";

type IProps = {
  isOpenOrder?: boolean;
  grids: ILayoutGrids;
  maintenanceDetails: IMaintenanceDetails;
  block?: boolean;
};

const MaintenanceSpecContent: React.FC<IProps> = ({
  maintenanceDetails,
  isOpenOrder = false,
  grids,
  block,
}) => {

  return (
    <div style={{ width: block ? "calc(100vw - 20px)" : "100%" }}>
      {isOpenOrder && <div style={{ height: 20 }} />}

      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12} xl={12}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <Times maintenanceDetails={maintenanceDetails} />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={12}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <Executors isOpenOrder={isOpenOrder} maintenanceDetails={maintenanceDetails} />
          </div>
        </Col>
        <Col xs={24} xl={12} xxl={isOpenOrder ? 12 : 8}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <CheckList isOpenOrder={isOpenOrder} maintenance={maintenanceDetails} />
          </div>
        </Col>
        <Col xs={24} xl={12} xxl={isOpenOrder ? 12 : 8}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <Materials maintenanceDetails={maintenanceDetails} isOpenOrder={isOpenOrder} />
          </div>
        </Col>
        <Col xs={24} xl={24} xxl={isOpenOrder ? 24 : 8}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            <UserReports isOpenOrder={isOpenOrder} maintenanceDetails={maintenanceDetails} />
          </div>
        </Col>
      </Row>

      <div style={{ height: 120 }} />
    </div>
  );
};

export default MaintenanceSpecContent;
