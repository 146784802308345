import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  Alert,
  Button,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  TableColumnType,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import { Filter, IUser, TableView, TableViewCategories } from "../../models/user";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { failedQueue, isRefreshing } from "../../services";
import user, { userSlice } from "../../store/reducers/user";
import { updateViewXHR } from "../../store/reducers/user/actionCreators";
import moment from "moment";
import { store } from "../../store";
import { FilterDropdownProps } from "antd/lib/table/interface";
import NumberFilter, { SearchValue } from "../TableCustomFilters/NumberFilter";
import {
  CompanyCustomFieldsV2,
  CustomFieldV2,
  ModelPropertyCustomFieldsNames,
} from "../../models/settings";
import PreviewButtonGroup from "../PreviewButton/Group";
import ViewForm from "../../componentsform/ViewForm";
import DateTimeFilter from "../TableCustomFilters/DateTimeFilter";
import { FormType } from "../../componentsform/CustomFieldsFormV2";
import DatePickerCustom, { CalendarTypes } from "../DatePickerCustom";
const api = require("../../services").default;

export type ColumnFilterType =
  | "NumberFilter"
  | "SelectFilter"
  | "DateTimeFilter"
  | "SearchFilter"
  | "BooleanFilter";

export type CustomTableColumn = {
  title: string;
  visible: 1 | 2 | 3;
  dataIndex: string;
  columnRenderComponent: string | null;
  filterComponent: ColumnFilterType | null;
  onCellFlag: boolean;
  objectKey: ModelPropertyCustomFieldsNames | null;
};

interface IProps {
  viewCategory: TableViewCategories;
  views: Array<TableView>;
  onChange: (value: string) => void;
  setColumns: (_c: CustomTableColumn[], activeIndex: number) => void;
  hideDivider?: boolean;
}

const ViewTabs: React.FC<IProps> = ({ viewCategory, views, onChange, setColumns, hideDivider }) => {
  const [activeKey, setActiveKey] = React.useState<string>(views[0]?.name);
  const [saveFilters, set_saveFilters] = React.useState<boolean>(false);
  const [delLoading, set_delLoading] = React.useState<number>(-1);
  const [view, set_view] = React.useState<TableView>();
  const dispatch = useAppDispatch();
  const _onChange = (activeKey: string) => {
    onChange(activeKey);
    setActiveKey(activeKey);
  };

  // if(!user.account.views || !user.account.views[viewCategory]) {
  //   return null;
  // }

  const onDelete = async (id: number) => {
    set_delLoading(id);
    const token = await localStorage.getItem("token");

    try {
      let response = await api.delete(`settings/view/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });
      dispatch(
        userSlice.actions.deleteViewById({
          id: id,
          typeCategory: viewCategory,
        }),
      );

      set_delLoading(-1);

      setActiveKey(views[0]?.name);
    } catch (error: any) {
      //lkl
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDelete(id));
        }
        return;
      }
      set_delLoading(-1);
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
  };

  return (
    <>
      <Modal
        title={view?.name ? t(TRANSLATION_KEY.editView) : t(TRANSLATION_KEY.addView)}
        destroyOnClose={true}
        onCancel={() => set_view(undefined)}
        visible={!!view}
        footer={null}
        closable={true}
      >
        {view && (
          <ViewForm
            viewCategory={viewCategory}
            view={view}
            onClose={(view) => {
              setActiveKey(view.name);
              setColumns(view.table_structure, 0);
              set_view(undefined);
            }}
          />
        )}
      </Modal>
      {!hideDivider && <Divider style={{ margin: "12px 0" }} />}
      <Tabs
        style={{ display: "none" }}
        onChange={_onChange}
        activeKey={activeKey}
        tabBarExtraContent={{
          left: (
            <Space style={{ marginRight: 21 }}>
              <Button
                type="link"
                onClick={() => {
                  set_view({
                    name: "",
                    id: 0,
                    filters: [],
                    table_structure: [],
                    view_type: "by_user",
                  });
                }}
                icon={
                  <PlusCircleOutlined
                    style={{
                      fontSize: 21,
                    }}
                  />
                }
              />
            </Space>
          ),
        }}
      >
        {views
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          ?.map((view, i) => (
            <Tabs.TabPane
              key={view.name}
              tab={
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography.Text>{view.name}</Typography.Text>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      icon={<EditOutlined />}
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        set_view(view);
                      }}
                    />
                    <Popconfirm
                      onVisibleChange={(visible) => {
                        let el = document.getElementsByTagName("body")[0];

                        if (visible) {
                          if (el) {
                            el.classList.add("ant-popover-open");
                          }
                        } else {
                          if (el) {
                            el.classList.remove("ant-popover-open");
                          }
                        }
                      }}
                      title={t(TRANSLATION_KEY.continueWithAction)}
                      onConfirm={(e) => {
                        onDelete(view.id);
                      }}
                    >
                      <Button
                        style={{ display: views.length === 1 ? "none" : "block" }}
                        onClick={(e) => e.stopPropagation()}
                        loading={delLoading === view.id}
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Space>
                </Space>
              }
            />
          ))}
      </Tabs>
    </>
  );
};

export default ViewTabs;

export const customTableColumnRender = (
  dynamic: ColumnFilterType,
  columnKey: string,
  user: IUser,
  modelKey: ModelPropertyCustomFieldsNames | null,
) => {
  if (modelKey === null) {
    return (text: string, value: any) => {
      //do ovoga nikad neće doći kada se radi o dinamičnoj koloni ali je potrebno zbog tipova errora
      return "-";
    };
  }
  if (dynamic === "DateTimeFilter") {
    // na osnovu filtera zanam koji komponent da vratim
    return (text: string, value: any) => {
      return value[modelKey][columnKey]?.value
        ? moment(value[modelKey][columnKey]?.value as string).format(
            user.account.date_format || "DD.MM.YYYY HH:mm",
          )
        : "-";
    };
  } else if (dynamic === "SelectFilter") {
    return (text: string, value: any) => {
      if (value[modelKey][columnKey]?.value === undefined) {
        return "-";
      }
      let _val = (value[modelKey][columnKey]?.value as string[]) || [];

      if (typeof _val !== "object" && !Array.isArray(_val)) {
        _val = [];
      }
      try {
        return _val ? (
          <PreviewButtonGroup title={""} limit={2}>
            {_val?.map((v, i) => (
              <React.Fragment key={i}>
                <Tag style={{ marginBottom: 6 }}>{v}</Tag>
                {(i + 1) % 5 === 0 && i !== 0 && <div style={{ width: "100%" }} />}
              </React.Fragment>
            ))}
          </PreviewButtonGroup>
        ) : (
          "-"
        );
      } catch (error) {
        console.log(error);
        return "-";
      }
    };
  } else if (dynamic === "BooleanFilter") {
    return (text: string, value: any) => {
      if (value[modelKey][columnKey]?.value === undefined) {
        return "-";
      }
      let unique = value[modelKey][columnKey]?.value as boolean;

      if (unique === undefined || unique === null) {
        return "-";
      }

      return unique ? (
        <Tag color="green">{t(TRANSLATION_KEY.yes)}</Tag>
      ) : (
        <Tag color="red">{t(TRANSLATION_KEY.no)}</Tag>
      );
    };
  } else {
    return (text: string, value: any) => {
      return (
        <Typography.Text
          style={{ maxWidth: 120 }}
          ellipsis={{
            tooltip: value[modelKey][columnKey]?.value,
          }}
        >
          {value[modelKey][columnKey]?.value || "-"}
        </Typography.Text>
      );
    };
  }
};

export const getFilterComponent = (
  //rzp
  value: CustomTableColumn,
  dropDownProps: FilterDropdownProps,
  viewIndex: number,
  tmpfilter: Filter[],
  onChange: (value: Filter[], filterType: ColumnFilterType, columnKey: string) => void,
  customFieldsDefinitions: CustomFieldV2[],
  viewCategory: TableViewCategories,
) => {
  if (value.filterComponent === "NumberFilter") {
    //number filter ne uzima u obzir da li je undefined i
    return (
      <NumberFilterBridge
        filterComponent={value.filterComponent}
        viewCategory={viewCategory}
        onChange={onChange}
        columnDefinition={value}
      />
    );
  } else if (value.filterComponent === "SelectFilter") {
    return (
      <SelectFilterBridge
        filterComponent={value.filterComponent}
        viewCategory={viewCategory}
        onChange={onChange}
        columnDefinition={value}
        customFieldsDefinitions={customFieldsDefinitions}
      />
    );
  } else if (value.filterComponent === "DateTimeFilter") {
    return (
      <DateTimeFilterBridge
        filterComponent={value.filterComponent}
        viewCategory={viewCategory}
        onChange={onChange}
        columnDefinition={value}
      />
    );
  } else if (value.filterComponent === "BooleanFilter") {
    return (
      <BooleanFilterBridge
        filterComponent={value.filterComponent}
        viewCategory={viewCategory}
        onChange={onChange}
        columnDefinition={value}
      />
    );
  } else if (value.filterComponent === "SearchFilter") {
    return (
      <SearchFilterBridge
        filterComponent={value.filterComponent}
        viewCategory={viewCategory}
        onChange={onChange}
        columnDefinition={value}
      />
    );
  }

  return <Alert message="filters" type="error" />;
};

export const saveTableDefinition = (
  tableDefinition: Array<
    TableView & {
      updated: number;
      active: boolean;
    }
  >,
  definitionFromBackend: TableView | undefined, // potreban orginal tablestructure sa backenda jer u tableDefinition su promjene gdje je title preveden
  viewToUpdate: TableViewCategories,
  hideSuccesAlert?: boolean,
) => {
  if (definitionFromBackend === undefined) {
    message.error(t(TRANSLATION_KEY.errorOnSaveData));
    return;
  }
  let tmpView = tableDefinition.find((x) => x.active);
  if (!tmpView) {
    message.error(t(TRANSLATION_KEY.errorOnGetData));
    return;
  }
  let table_structure_with_titles_with_trans_key = tmpView.table_structure.map((x) => {
    return {
      ...x,
      title:
        definitionFromBackend.table_structure.find((c) => c.dataIndex === x.dataIndex)?.title || "", // without translate
    };
  });
  let body: TableView = {
    table_structure: table_structure_with_titles_with_trans_key,
    name: tmpView.name,
    id: tmpView.id,
    filters: tmpView.filters,
    view_type: tmpView.view_type,
  };
  updateViewXHR(
    {
      errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
      id: tmpView.id,
      body,
      viewToUpdate,
      successCallback: (res) => {
        if (hideSuccesAlert) return;
        message.success(t(TRANSLATION_KEY.successfulChanged));
      },
    },
    store.dispatch,
  );
};

export function filterColumns<T>(arr: Array<Partial<CustomTableColumn> & TableColumnType<T>>) {
  let filtred = arr.filter((x) => x.visible === 1 || x.visible === 3);

  // items with actions shoud be always on end of columns of table
  let actions = arr.filter((x) => x.dataIndex === "actions");
  if (actions.length > 0) {
    filtred = filtred.filter((x) => x.dataIndex !== "actions");
    filtred = [...filtred, ...actions];
  }
  return filtred;
}

export const getFilter = (filters: Filter[], valueOfFilterKey: string, dynamicColumn: boolean) => {
  let value: Filter[] = [];
  if (dynamicColumn) {
    value = filters.filter((f) => f.name === valueOfFilterKey);
  } else {
    value = filters.filter((f) => f.name === valueOfFilterKey);
  }

  if (value.length === 1) {
    return value[0].value;
  } else if (value.length > 1) {
    let from = value.find((x) => x.lookap === "gte");
    let to = value.find((x) => x.lookap === "lte");
    return { from, to };
  }

  return undefined;
};

export const getQueryParams = (filters: Filter[]) => {
  let params: Record<string, string> = {};
  filters.forEach((f) => {
    let paramKey: string | undefined = getParamKey(f);
    if (!paramKey) {
      return;
    }

    if (f.value || f.value === 0 || f.value === false) {
      if (Array.isArray(f.value)) {
        params[paramKey] = f.value.join(",");
      } else {
        params[paramKey] = f.value.toString();
      }
    }
  });

  return params;
};

export const getParamKey = (filter: Filter): string | undefined => {
  if (filter.dynamicColumn) {
    return (
      (filter.objectPrefix || "") +
      "__" +
      filter.cf_id +
      (filter.lookap ? "__" + filter.lookap : "")
    );
  } else {
    return filter.name + (filter.lookap ? `__${filter.lookap}` : "");
  }
};

export const getFiltersForView = (
  user: IUser,
  viewCategory: TableViewCategories,
  staticFilters: Filter[],
) => {
  //staticFilters su filteri koji se ne mogu promeniti oni su defaultni za određenu tablicu limit offset itd
  // uzima prvi view iz liste view-ova
  let tmp = user.account.views?.[viewCategory];
  if (!tmp) {
    return staticFilters;
  }

  if (!tmp[0]?.filters || !Array.isArray(tmp[0]?.filters)) {
    // provjera za niz jer su prije verzije imale objekte
    return staticFilters;
  }

  return [...tmp[0].filters, ...staticFilters];
};

export function setColumns<TableRenders, ColumnType>(
  _c: CustomTableColumn[],
  viewIndex: number,
  _filters: Filter[],
  viewCategory: TableViewCategories,
  modelPropertyCustomFieldsNames: ModelPropertyCustomFieldsNames,
  render: (key: TableRenders) => any,
  user: IUser,
  onChangeFilter: (value: Filter[], filterType: ColumnFilterType, columnKey: string) => void,
  companyCustomFieldsV2: CompanyCustomFieldsV2,
  set_COLUMNS: (columns: Array<CustomTableColumn & TableColumnType<ColumnType>>) => any,
) {
  // ostaje unutar komponente za koju se prave views-ovi iz razloga jer će trebati filtere okidati
  let tmp: Array<CustomTableColumn & TableColumnType<ColumnType>> = [];
  _c.forEach((c) => {
    tmp.push({
      ...c,
      title: t(c.title),
      render: c.columnRenderComponent
        ? render(c.columnRenderComponent as TableRenders)
        : customTableColumnRender(
            c.filterComponent as ColumnFilterType,
            c.dataIndex,
            user,
            modelPropertyCustomFieldsNames,
          ),
      onCell: (record: ColumnType) => {
        return { rowSpan: 1 };
      },
      filterDropdown: c.filterComponent
        ? (a) =>
            getFilterComponent(
              c,
              a,
              viewIndex,
              _filters,
              onChangeFilter,
              companyCustomFieldsV2[viewCategory],
              viewCategory,
            )
        : undefined,
    });
  });
  set_COLUMNS(tmp);
}

export const mergeFilters = (tmp_filters: Filter[], value: Filter[], filterType) => {
  //tmp_filters su filteri koji su već postavljeni u view-u
  //value su novi filteri koji se biraju
  let cfIdsToRemove = new Set(value.map((item) => item.cf_id));

  console.log(value);
  tmp_filters = tmp_filters.filter((item) => !cfIdsToRemove.has(item.cf_id)); // da ovog nema duplao bi se isti filter

  if (value && (filterType === "DateTimeFilter" || filterType === "NumberFilter")) {
    tmp_filters = [...tmp_filters, ...value];
  } else if (value && filterType === "SelectFilter") {
    tmp_filters = [...tmp_filters, ...value];
  } else if (value && filterType === "SearchFilter") {
    tmp_filters = [...tmp_filters, ...value];
  } else if (value && filterType === "BooleanFilter") {
    tmp_filters = [...tmp_filters, ...value];
  }

  return tmp_filters;
};

export const clearFilters = (filters: Filter[]) => {
  //f.value || f.value === 0 || f.value === false
  // if value is undefined or null or empty string or empty array clear filters also if cf_id or if cf_id then use name are dupicate in filter clear them too
  return filters.filter(
    (f) =>
      f.value !== undefined &&
      f.value !== null &&
      f.value !== "" &&
      (Array.isArray(f.value) ? f.value.length > 0 : true),
  );
};

export const generateFilter = (
  value: string | number | string[] | number[] | null | boolean | undefined,
  cf_id: string | null,
  title: string,
  objectPrefix: FormType,
  columnFilterType: ColumnFilterType | null,
  lookap: string | undefined,
  calendarType: CalendarTypes | undefined = undefined,
): Filter => {
  let tmpFilter: Filter = {
    dynamicColumn: true,
    lookap,
    value: value,
    cf_id: cf_id || null,
    name: title,
    objectPrefix: objectPrefix,
    columnFilterType: columnFilterType,
    calendarType: calendarType,
  };

  return tmpFilter;
};

interface P {
  columnDefinition: CustomTableColumn;
  viewCategory: TableViewCategories;
  filterComponent: ColumnFilterType;
  onChange: (value: Filter[], filterType: ColumnFilterType, columnKey: string) => void;
  customFieldsDefinitions?: CustomFieldV2[];
}

const DateTimeFilterBridge: React.FC<P> = ({
  columnDefinition,
  viewCategory,
  filterComponent,
  onChange,
}) => {
  const { globalFiltersBucket } = useAppSelector((state) => state.userReducer);

  const [from, setFrom] = React.useState<string | undefined>(undefined);
  const [to, setTo] = React.useState<string | undefined>(undefined);
  const [type, setType] = React.useState<CalendarTypes>("month");
  useEffect(() => {
    let tmp = globalFiltersBucket.filter((x) => x.cf_id === columnDefinition.dataIndex);
    let from = tmp.filter((x) => x.lookap === "gte")[0];
    let to = tmp.filter((x) => x.lookap === "lte")[0];

    setType(from?.calendarType || "month");
    setFrom((from?.value as string) || undefined);
    setTo((to?.value as string) || undefined);
  }, [globalFiltersBucket]);

  return (
    <div style={{ padding: 6 }}>
      <DatePickerCustom
        allowClear={true}
        skipInitial={true}
        start={from}
        end={to}
        type={type}
        onChange={(_start, _end, _type) => {
          if (_start === from && _end === to) {
            return;
          }

          let tmp_from = generateFilter(
            _start,
            columnDefinition.dataIndex,
            columnDefinition.title,
            viewCategory,
            filterComponent,
            "gte",
            _type,
          );
          let tmp_to = generateFilter(
            _end,
            columnDefinition.dataIndex,
            columnDefinition.title,
            viewCategory,
            filterComponent,
            "lte",
            _type,
          );
          onChange([tmp_from, tmp_to], filterComponent, columnDefinition.dataIndex);
        }}
      />
    </div>
  );
};

const NumberFilterBridge: React.FC<P> = ({
  columnDefinition,
  viewCategory,
  filterComponent,
  onChange,
}) => {
  const { globalFiltersBucket } = useAppSelector((state) => state.userReducer);

  const [from, setFrom] = React.useState<number | undefined>(undefined);
  const [to, setTo] = React.useState<number | undefined>(undefined);
  useEffect(() => {
    let tmp = globalFiltersBucket.filter((x) => x.cf_id === columnDefinition.dataIndex);
    let from = tmp.filter((x) => x.lookap === "gte")[0];
    let to = tmp.filter((x) => x.lookap === "lte")[0];
    setFrom((from?.value as number) || undefined);
    setTo((to?.value as number) || undefined);
  }, [globalFiltersBucket]);

  return (
    <div style={{ padding: 6 }}>
      <NumberFilter
        column_key={columnDefinition.dataIndex}
        opened={new Date().getTime().toString()}
        value={{
          from: Number(from) || undefined,
          to: Number(to) || undefined,
        }}
        onSave={(fromTo: SearchValue, column_key: string) => {
          if (fromTo.from === from && fromTo.to === to) {
            return;
          }

          let tmp_from = generateFilter(
            fromTo.from,
            columnDefinition.dataIndex,
            columnDefinition.title,
            viewCategory,
            filterComponent,
            "gte",
            undefined,
          );
          let tmp_to = generateFilter(
            fromTo.to,
            columnDefinition.dataIndex,
            columnDefinition.title,
            viewCategory,
            filterComponent,
            "lte",
            undefined,
          );
          onChange([tmp_from, tmp_to], filterComponent, columnDefinition.dataIndex);
        }}
      />
    </div>
  );
};

const SelectFilterBridge: React.FC<P> = ({
  columnDefinition,
  viewCategory,
  filterComponent,
  onChange,
  customFieldsDefinitions,
}) => {
  const [value, set_value] = useState<string[]>([]);

  const { globalFiltersBucket } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    let tmp = globalFiltersBucket.filter((x) => x.cf_id === columnDefinition.dataIndex);
    let v = tmp[0]?.value as string[];
    set_value(v || []);
  }, [globalFiltersBucket]);

  let tmpCompanyCustomField: undefined | CustomFieldV2 = undefined;
  tmpCompanyCustomField = customFieldsDefinitions?.find(
    (x) => x.cf_id === columnDefinition.dataIndex,
  );
  return (
    <Select
      value={value}
      onChange={(v: string[]) => {
        let tmp_value: Filter = generateFilter(
          v,
          columnDefinition.dataIndex,
          columnDefinition.title,
          viewCategory,
          filterComponent,
          "contains",
          undefined,
        );
        set_value(v);
        onChange([tmp_value], filterComponent, columnDefinition.dataIndex);
      }}
      allowClear
      defaultValue={value as string[]}
      mode="multiple"
      style={{ minWidth: 200 }}
    >
      {tmpCompanyCustomField?.data?.map((v, i) => (
        <Select.Option key={i} value={v}>
          {v}
        </Select.Option>
      ))}
    </Select>
  );
};

const BooleanFilterBridge: React.FC<P> = ({
  columnDefinition,
  viewCategory,
  filterComponent,
  onChange,
  customFieldsDefinitions,
}) => {
  const [value, set_value] = useState<string | undefined>();

  const { globalFiltersBucket } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    let tmp = globalFiltersBucket.filter((x) => x.cf_id === columnDefinition.dataIndex);
    let v = tmp[0]?.value as string;
    set_value(v || undefined);
  }, [globalFiltersBucket]);

  return (
    <Select
      value={value || "true,false"}
      onChange={(v: string) => {
        let tmp_value: Filter = generateFilter(
          v === "true,false" ? undefined : v,
          columnDefinition.dataIndex,
          columnDefinition.title,
          viewCategory,
          filterComponent,
          undefined,
          undefined,
        );
        set_value(v);
        onChange([tmp_value], filterComponent, columnDefinition.dataIndex);
      }}
      allowClear
      style={{ minWidth: 200 }}
    >
      {[
        {
          name: t(TRANSLATION_KEY.all),
          id: "true,false",
        },
        {
          name: t(TRANSLATION_KEY.yes),
          id: "true",
        },
        {
          name: t(TRANSLATION_KEY.no),
          id: "false",
        },
      ].map((v, i) => (
        <Select.Option key={i} value={v.id}>
          {v.name}
        </Select.Option>
      ))}
    </Select>
  );
};

const SearchFilterBridge: React.FC<P> = ({
  columnDefinition,
  viewCategory,
  filterComponent,
  onChange,
  customFieldsDefinitions,
}) => {
  const [value, set_value] = useState<string>("");

  return (
    <Input.Search
      value={value}
      onChange={(e) => {
        set_value(e.target.value);
        if (e.target.value === "") {
          onChange(
            [
              generateFilter(
                undefined,
                columnDefinition.dataIndex,
                columnDefinition.title,
                viewCategory,
                filterComponent,
                "icontains",
                undefined,
              ),
            ],
            "SearchFilter",
            columnDefinition.dataIndex,
          );
        }
      }}
      onSearch={(val) => {
        let tmp_search_value: Filter = generateFilter(
          val,
          columnDefinition.dataIndex,
          columnDefinition.title,
          viewCategory,
          filterComponent,
          "icontains",
          undefined,
        );
        onChange([tmp_search_value], "SearchFilter", columnDefinition.dataIndex);
      }}
    />
  );
};
