import { Button, Drawer, message, Modal, PageHeader, Space, Table, Typography } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Link, useLocation } from "react-router-dom";
import Columns from "../../components/Columns";
import PreviewButton from "../../components/PreviewButton";
import TemplateFrom from "../../componentsform/TemplateForm";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { sortTableColumns } from "../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ILoadMoreParams, ITableColumn } from "../../models";
import { ITemplate } from "../../models/maintenances";
import { getTemplatesXHR } from "../../store/reducers/maintenance/actionCreator";
import AssetModalPreview from "../assetdetails/components/AssetModalPreview";
import Filters, { IFilters } from "./Components/Filters";
import moment from "moment";
import { RRuleContextProvider } from "../../components/RRule/useRRule";
import { RRule } from "rrule";
import { parseRRuleString } from "../../components/RRule/utils";

const STORAGE_COLUMNS_NAME = "TemplateListTable";
export const LOCAL_STORAGE_FILTERS_NAME = "TemplatesFilters" + window.location.pathname;

interface IProps {}

const ARRAY_SIZE_LIMIT = 60;

const Templates: React.FC<IProps> = ({}) => {
  const { user } = useAppSelector((state) => state.userReducer);

  const location = useLocation();
  const [addTemplateVisible, set_addTemplateVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<number>(-1);
  const [loadMoreParams, set_loadMoreParams] = useState<ILoadMoreParams>({
    limit: ARRAY_SIZE_LIMIT,
    offset: 0,
  });
  const [hasMoreData, set_hasMoreData] = useState<boolean>(true);

  function parseRRuleString(rruleString: string): string[] {
    return rruleString.split("\n").map((rule) => rule.replace(/^RRULE:/, ""));
  }

  let _c: ITableColumn<ITemplate>[] = [
    {
      title: t(TRANSLATION_KEY.templateNumber),
      dataIndex: "number",
      visible: true,
      render: (text: string, value: ITemplate) => <Link to={`${value.id}`}>{value.number}</Link>,
    },
    {
      title: t(TRANSLATION_KEY.description),
      dataIndex: "description",
      visible: true,
      render(text, value, index) {
        return (
          <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: text }}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.next_run),
      dataIndex: "next_run",
      visible: true,
      render: (text: string, value: ITemplate) => {
        return value.next_run
          ? moment(value.next_run).format(user.account.date_format || "LLL")
          : "-";
      },
    },
    {
      title: t(TRANSLATION_KEY.repeatEvery),
      dataIndex: "repeat_every",
      visible: true,
      width: "270px",
      render: (text: string, value: ITemplate) => {
        if (!value.recurrence_rule || !value.recurrence_enabled) {
          return <Typography.Text>-</Typography.Text>;
        }
        return (
          <Space direction="vertical" size={0}>
            {parseRRuleString(value.recurrence_rule).map((rule, index) => (
              <Typography.Text key={index}>{RRule.fromString(rule)?.toText()}</Typography.Text>
            ))}
          </Space>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.asset),
      dataIndex: "asset",
      visible: true,
      render: (text: string, value: ITemplate) => {
        return (
          <Space direction="vertical" size={0}>
            <PreviewButton
              isActive={value.asset.is_active}
              title={value.asset?.name}
              id={value.id}
              url={`/app/asset-details/${value.asset?.id}`}
              permissionsRequired={["view_asset"]}
              onClick={() => {
                unstable_batchedUpdates(() => {
                  set_selectedAsset(value.asset?.id);
                }, []);
              }}
            />
            {value.asset?.path && (
              <Typography.Text
                type="secondary"
                style={{ maxWidth: 320 }}
                ellipsis={{ tooltip: value.asset.path }}
              >
                {value.asset?.path}
              </Typography.Text>
            )}
          </Space>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.location),
      dataIndex: "location",
      visible: true,
      render: (text: string, value: ITemplate) => <span>{value.location?.name || "-"}</span>,
    },
  ];

  const dispatch = useAppDispatch();
  const { getTemplatesStatus, templates } = useAppSelector((state) => state.maintenanceReducer);
  const [COLUMNS, set_COLUMNS] = useState(_c);
  useEffect(() => {
    sortTableColumns(COLUMNS, STORAGE_COLUMNS_NAME + location.pathname, set_COLUMNS);
  }, []);

  const getTemplates = async (
    filters: IFilters | undefined,
    loadMoreParams: ILoadMoreParams,
    mergeData: boolean,
    setFilters?: (f: IFilters) => void,
  ) => {
    let tmp = await localStorage.getItem(LOCAL_STORAGE_FILTERS_NAME);
    let _filters: IFilters | null = JSON.parse(tmp || "null");

    if (filters) {
      _filters = filters;
    }

    getTemplatesXHR(
      {
        mergeData,
        queryParams: {
          limit: loadMoreParams.limit,
          offset: loadMoreParams.offset,
          types: _filters?.types.join(","),
          categories: _filters?.categories.join(","),
          locations: _filters?.locations.join(","),
          executor_suppliers: _filters?.suppliers.join(","),
          executor_accounts: _filters?.accounts.join(","),
          statuses: _filters?.statuses.join(","),
          search: _filters?.search,
        },
        successCallback: (res) => {
          if (!res.results) {
            return;
          }
          console.log(res.results.length < loadMoreParams.limit);
          if (res.results.length < loadMoreParams.limit) {
            set_hasMoreData(false);
          }
        },
        errorCallback: (e) => {
          console.log("ERROR", e);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );

    if (!mergeData) {
      set_hasMoreData(true);
    }
    setFilters && _filters && setFilters(_filters);
    localStorage.setItem(LOCAL_STORAGE_FILTERS_NAME, JSON.stringify(_filters));
  };

  return (
    <>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10, marginBottom: 20 }}
        title={t(TRANSLATION_KEY.templates)}
        extra={[
          <Button
            onClick={() => {
              set_addTemplateVisible(true);
            }}
            type="primary"
          >
            {t(TRANSLATION_KEY.add)}
          </Button>,
        ]}
      />

      {/* Columns */}

      <Columns
        columns={COLUMNS}
        memorizedName={STORAGE_COLUMNS_NAME + location.pathname}
        onChange={set_COLUMNS}
      />

      <div>
        <Filters
          getData={(f: IFilters | undefined, setFilters: (f: IFilters) => void) => {
            getTemplates(f, loadMoreParams, false, setFilters);
          }}
        />
      </div>

      <div style={{ height: 20 }} />

      {/* Table */}
      <Table
        columns={COLUMNS.filter((x) => x.visible)}
        loading={getTemplatesStatus === "loading"}
        dataSource={templates}
        pagination={false}
        size="small"
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {hasMoreData && (
          <Button
            onClick={async () => {
              let tmp = await localStorage.getItem(LOCAL_STORAGE_FILTERS_NAME);
              let _filters: IFilters | null = JSON.parse(tmp || "null");

              getTemplates(
                {
                  search: _filters?.search || "",
                  types: _filters?.types || [],
                  categories: _filters?.categories || [],
                  locations: _filters?.locations || [],
                  suppliers: _filters?.suppliers || [],
                  accounts: _filters?.accounts || [],
                  statuses: _filters?.statuses || [],
                },
                {
                  offset: templates.length,
                  limit: loadMoreParams.limit,
                },
                true,
                undefined,
              );
            }}
            type="link"
            disabled={getTemplatesStatus === "loading"}
          >
            Učitaj još
          </Button>
        )}
      </div>

      {/* Drawer */}
      <Drawer
        title={t(TRANSLATION_KEY.newTemplate)}
        width={680}
        visible={addTemplateVisible}
        onClose={() => set_addTemplateVisible(false)}
      >
        <RRuleContextProvider template={undefined}>
          <TemplateFrom close={() => set_addTemplateVisible(false)} />
        </RRuleContextProvider>
      </Drawer>

      {/* Preview */}

      <Modal
        visible={selectedAsset !== -1}
        onCancel={() => {
          set_selectedAsset(-1);
        }}
        footer={null}
        centered
        width="800px"
        closable={false}
        destroyOnClose
      >
        {selectedAsset !== -1 && <AssetModalPreview id={selectedAsset} />}
      </Modal>
    </>
  );
};

export default Templates;
