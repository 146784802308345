import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { IMaintenance, MaintenanceEvent } from "../../../models/maintenances";
import { maintenanceSlice } from "../../../store/reducers/maintenance";

// UI
import { Drawer, message, Spin, Typography } from "antd";
import { motion } from "framer-motion";
import styles from "./styles.module.css";

// Helpers
import { hasPermission } from "../../../helpers/functions";
import {
  parseDate,
  parseEndDate,
  parseStartDate,
  transformFiltersIntoQueryParams,
  transformMaintenanceIntoEvent,
} from "./helpers";

// Calendar
import FullCalendar, {
  DatesSetArg,
  DateSelectArg,
  EventInput,
  EventDropArg,
  EventClickArg,
  LocaleInput,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import hr from "@fullcalendar/core/locales/hr";
import en from "@fullcalendar/core/locales/en-gb";
import de from "@fullcalendar/core/locales/de";
import rs from "@fullcalendar/core/locales/sr";
import interactionPlugin, { DropArg, EventResizeDoneArg } from "@fullcalendar/interaction";
import moment from "moment";

// Config
import { useDispatch } from "react-redux";
import {
  getCalendarEventsXHR,
  getDroppableCalendarMaintenancesXHR,
  updateMaintenanceXHR,
} from "../../../store/reducers/maintenance/actionCreator";

// Components
import MaintenanceForm, { IMaintenanceForm } from "../../../componentsform/MaintenanceForm";
import DropEvents from "./components/DropEvents";
import Filters from "./components/Filters";
import RenderEvent from "./components/RenderEvent";

// Translation
import { t } from "i18next";
import { Languages, TRANSLATION_KEY } from "../../../helpers/consts";
import { accountsXHR } from "../../../store/reducers/accounts/actionCreators";
import { getAssetXHR } from "../../../store/reducers/asstes/actionCreators";
import { dashboardSlice } from "../../../store/reducers/dashboard";
import { getSuppliersXHR } from "../../../store/reducers/supplier/actionCreator";
import { useUpdateEffect } from "react-use";

export interface IFilters {
  asset: number[];
  maintenance_type: number[];
  maintenance_category: number[];
  maintenance_location: number[];
  executor_account: number[];
  executor_supplier: number[];
  statuses?: string[];
}

interface TimeNeeded {
  hh: number;
  mm: number;
}
interface IProps {
  asset?: number;
  executor_accounts?: number[];
  executor_suppliers?: number[];
  fromModal?: boolean;
}

// ! Check this issue: https://github.com/fullcalendar/fullcalendar-react/issues/47

const CalendarMaintenances: React.FC<IProps> = ({
  fromModal,
  asset,
  executor_accounts,
  executor_suppliers,
}) => {
  // Hooks
  const dispatch = useDispatch();
  const calendarRef = useRef<FullCalendar>(null!);

  // Default state
  const initialFilters: IFilters = {
    asset: asset ? [asset] : [],
    maintenance_type: [],
    maintenance_category: [],
    maintenance_location: [],
    executor_account: executor_accounts || [],
    executor_supplier: executor_suppliers || [],
    statuses: [],
  };

  // State
  const {
    calendarEvents,
    getCalendarEventsStatus,
    droppableCalendarMaintenances,
    getDroppableCalendarMaintenancesStatus,
    getMaintenanceListStatus,
    updateMaintenanceStatus,
    liveMaintenances,
  } = useAppSelector((state) => state.maintenanceReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  // Variables
  const [interval, setInterval] = useState({
    start: moment().startOf("month"),
    end: moment().endOf("month"),
  });
  const [filtersDisabled, setFiltersDisabled] = useState<boolean>(true);
  const [addMaintenance, set_addMaintenance] = useState<IMaintenanceForm>();
  const [loading, set_loading] = useState<boolean>(false);
  const [initialLoading, set_initialLoading] = useState<boolean>(true);
  const [dropLoading, setDropLoading] = useState<boolean>(false);
  const [filters, set_filters] = useState<IFilters>(initialFilters);
  const [dropEvents, set_dropEvents] = useState<IMaintenance[]>(droppableCalendarMaintenances);
  const [dropEventsVisible, set_dropEventsVisible] = useState<boolean>(false);
  const [aspectRatio, set_aspectRatio] = useState<number>(2.8);
  const [timeNeeded, set_timeNeeded] = useState<TimeNeeded>({ hh: 0, mm: 0 });
  const from = asset
    ? "asset"
    : executor_accounts
      ? "executor_accounts"
      : executor_suppliers
        ? "executor_suppliers"
        : undefined;

  //! Important
  //! This function is called when the component is mounted and when the month is changed
  //* From documentation:
  //    Called after the calendar’s date range has been initially set or changed in some way and the DOM has been updated.
  //    https://fullcalendar.io/docs/datesSet
  async function handleDatesSet(payload: DatesSetArg) {
    let view = payload.view.type;
    let startOffset = 0;
    let endOffset = view === "timeGridWeek" ? -1 : 0;
    const planned_start_from = parseStartDate(payload.startStr, startOffset);
    const planned_start_to = parseEndDate(payload.endStr, endOffset);
    setInterval({ start: moment(payload.start), end: moment(payload.end) });
    fetchMaintenances(planned_start_from, planned_start_to);
  }

  useEffect(() => {
    getDroppableCalendarMaintenancesXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback(data) {
          if (data.results) {
            set_dropEvents(data.results);
          }
        },
      },
      dispatch,
    );
    // Fetching filter data
    getAssetXHR({ errorCallback: () => message.error(t("errorOnFetchData")) }, dispatch);
    getSuppliersXHR({}, dispatch);
    accountsXHR({}, dispatch);
    setTimeout(() => {
      set_initialLoading(false);
    }, 500);
  }, []);

  // Fetching data on filters change
  useUpdateEffect(() => {
    const planned_start_from = parseStartDate(interval.start);
    const planned_start_to = parseEndDate(interval.end);
    fetchMaintenances(planned_start_from, planned_start_to);
  }, [filters, asset]);

  //? Fetching maintenances based on from/to planned start time
  function fetchMaintenances(planned_start_from: string, planned_start_to: string): void {
    getCalendarEventsXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          planned_start_from,
          planned_start_to,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...transformFiltersIntoQueryParams(filters),
        },
        successCallback(data) {
          setFiltersDisabled(false);
        },
      },
      dispatch,
    );
  }

  //* Dropping maintenances
  //*
  //? Changing view to 'weeks view' when 'Add Maintenances' modal is visible
  //? Cannot add maintenances from 'months view' - unable to select hours
  useEffect(() => {
    let calendarApi = calendarRef?.current?.getApi();
    if (dropEventsVisible) {
      if (calendarApi?.view?.type !== "timeGridWeek" && calendarApi?.view?.type !== "timeGridDay") {
        // calendarApi?.changeView('timeGridWeek')
      }
    }
    // Wait for animations to end
    setTimeout(() => calendarApi?.updateSize(), 1000);
  }, [dropEventsVisible]);

  //? If current view is 'months view' - close 'Add Maintenances' modal
  useEffect(() => {
    if (calendarRef?.current?.getApi()?.view?.type === "dayGridMonth") {
      set_dropEventsVisible(false);
    }
    // Wait for animations to end
    setTimeout(() => calendarRef?.current?.getApi()?.updateSize(), 750);
  }, [calendarRef?.current?.getApi()?.view]);
  //*
  //* Dropping maintenances

  //? Changing aspect ratio on dropEventsVisible change
  useEffect(() => {
    if (dropEventsVisible) set_aspectRatio(2);
    else set_aspectRatio(2.8);
  }, [dropEventsVisible]);

  useEffect(() => {
    if (!!addMaintenance) {
      set_dropEventsVisible(false);
    }
  }, [addMaintenance]);

  function onCalendarLoad(isLoading: boolean) {
    set_loading(isLoading);
  }

  function toggleDrawer(payload: DateSelectArg) {
    //* Creating clicked date
    let clickedDate = moment(payload.startStr).format("YYYY-MM-DD HH:mm");

    //* Creating time_needed
    const end = moment(payload.endStr);
    const start = moment(payload.startStr);

    // Calculate the duration between end and start
    const duration = moment.duration(end.diff(start));

    // Get the hours and minutes from the duration
    const hours = duration.hours();
    const minutes = duration.minutes();

    // Setting timeNeeded
    set_timeNeeded({ hh: hours, mm: minutes });

    if (payload)
      set_addMaintenance({
        work_request: null,
        subassets: [],
        checklist: null,
        custom_fields: [],
        description: "",
        asset: null,
        maintenance_type: null,
        maintenance_categories: [],
        location: null,
        planned_start: moment(clickedDate),
        time_needed: "",
        deadline: null,
        executors: null,
        executor_type: "employee",
        status: null,
        client: null,
        orderconfirm_custom_fields_v2: {},
        workorder_custom_fields_v2: {},
      });
  }

  // Events data model
  const events = calendarEvents?.map((item: MaintenanceEvent) => {
    return {
      id: item.id?.toString(),
      title: `${t(TRANSLATION_KEY.maintenance)} ${item?.order_number}`,
      start: parseDate(item?.planned_start),
      borderColor: item?.maintenance_type ? item?.maintenance_type.color : undefined,
      backgroundColor: item?.maintenance_type ? item?.maintenance_type.color : undefined,
      editable: !dropLoading && item?.event_type === "actual_order",
      end: item?.planned_end ? parseDate(item?.planned_end) : undefined,
      durationEditable: !dropLoading && item?.event_type === "actual_order",
      extendedProps: {
        asset_id: item?.asset && item?.asset.id,
        maintenance: item,
      },
    };
  });

  // Update maintenance on drop - moving event
  const onEventDrop = useCallback(
    (eventInfo: EventDropArg) => {
      let droppedMaintenance: MaintenanceEvent = calendarEvents?.find(
        (item: MaintenanceEvent) => item.id === Number(eventInfo.event.id),
      )!;
      if (!droppedMaintenance) return;
      setDropLoading(true);
      let planned_end =
        eventInfo.event.endStr && eventInfo.event.endStr !== "" ? eventInfo.event.endStr : null;
      updateMaintenanceXHR(
        {
          body: { planned_start: eventInfo.event.startStr, planned_end },
          id: droppedMaintenance.id as number,
          errorCallback: () => {
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
            setTimeout(() => {
              setDropLoading(false);
            }, 3000);
          },
          successCallback: (data) => {
            if (data.results) {
              // Update calendar maintenances
              let newCalendarMaintenances = [...calendarEvents];
              let index = newCalendarMaintenances.findIndex(
                (item) => item.id === droppedMaintenance.id,
              );
              newCalendarMaintenances.splice(index, 1, transformMaintenanceIntoEvent(data.results));
              dispatch(maintenanceSlice.actions.getCalendarEventsSuccess(newCalendarMaintenances));
              // Update live maintenances
              let maintenances = [...liveMaintenances];
              let _index = maintenances.findIndex((m) => m.id === data.results?.id);
              maintenances.splice(_index, 1, data.results);
              dispatch(
                maintenanceSlice.actions.getLiveMaintenancesSuccess({
                  message: "",
                  results: maintenances,
                }),
              );
              dispatch(dashboardSlice.actions.set_categoriesTypes(maintenances));
              setTimeout(() => {
                setDropLoading(false);
              }, 3000);
            }
          },
        },
        dispatch,
      );
    },
    [calendarEvents, dispatch, liveMaintenances, t],
  );

  function updateCalendarEvents(maintenance: IMaintenance | undefined) {
    if (!maintenance) return;
    // Update calendar maintenances
    let newCalendarMaintenances = [...calendarEvents];
    newCalendarMaintenances.push({
      ...transformMaintenanceIntoEvent(maintenance),
      planned_start: maintenance.planned_start,
      planned_end:
        maintenance.planned_end && maintenance.planned_end !== "" ? maintenance.planned_end : null,
    });
    dispatch(maintenanceSlice.actions.getCalendarEventsSuccess(newCalendarMaintenances));
    // Update live maintenances
    let maintenances = [...liveMaintenances];
    maintenances.push(maintenance);
    dispatch(
      maintenanceSlice.actions.getLiveMaintenancesSuccess({
        message: "",
        results: maintenances,
      }),
    );
  }
  const onEventResize = useCallback(
    (eventInfo: EventResizeDoneArg) => {
      let eventId = eventInfo.event._def.extendedProps?.maintenance?.id || -1;
      let reseizedEvent: MaintenanceEvent = calendarEvents.find(
        (item: MaintenanceEvent) => item.id === Number(eventId),
      )!;
      if (reseizedEvent) {
        const duration = moment.duration(
          moment(eventInfo?.event?.endStr)?.diff(moment(eventInfo?.event?.startStr)),
        );
        const hours = duration?.hours();
        const minutes = duration?.minutes();
        const seconds = duration?.seconds();
        const time_needed = `${hours}:${minutes}:${seconds}`;

        updateMaintenanceXHR(
          {
            body: {
              planned_start: eventInfo.event.startStr,
              planned_end: eventInfo.event.endStr,
              time_needed,
            },
            id: reseizedEvent.id as number,
            errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
            successCallback: (data) => {
              if (data.results) {
                // Update calendar maintenances
                let newCalendarMaintenances = [...calendarEvents];
                let index = newCalendarMaintenances.findIndex(
                  (item) => item.id === reseizedEvent.id,
                );
                newCalendarMaintenances.splice(
                  index,
                  1,
                  transformMaintenanceIntoEvent(data.results),
                );
                dispatch(
                  maintenanceSlice.actions.getCalendarEventsSuccess(newCalendarMaintenances),
                );
                // Update live maintenances
                let maintenances = [...liveMaintenances];
                let _index = maintenances.findIndex((m) => m.id === data.results?.id);
                maintenances.splice(_index, 1, data.results);
                dispatch(
                  maintenanceSlice.actions.getLiveMaintenancesSuccess({
                    message: "",
                    results: maintenances,
                  }),
                );
                dispatch(dashboardSlice.actions.set_categoriesTypes(maintenances));
              }
            },
          },
          dispatch,
        );
      }
    },
    [calendarEvents, dispatch, liveMaintenances, t],
  );

  // Dropping external events
  function onDrop(eventInfo: DropArg) {
    let droppedMaintenance: IMaintenance = dropEvents.find(
      (item: IMaintenance) => item?.id === Number(eventInfo?.draggedEl?.id),
    )!;

    if (!droppedMaintenance) return;
    setDropLoading(true);
    updateMaintenanceXHR(
      {
        body: { planned_start: moment(eventInfo.dateStr).toISOString(true) },
        id: droppedMaintenance.id,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          setTimeout(() => {
            setDropLoading(false);
          }, 3000);
        },
        successCallback: (data) => {
          if (data.results) {
            // Update droppable maintenances
            let index = droppableCalendarMaintenances.findIndex(
              (item: IMaintenance) => item.id === droppedMaintenance.id,
            );
            let newDroppableCalendarMaintenances = [...droppableCalendarMaintenances];
            newDroppableCalendarMaintenances.splice(index, 1);
            set_dropEvents(newDroppableCalendarMaintenances);
            dispatch(
              maintenanceSlice.actions.getDroppableCalendarMaintenancesSuccess({
                message: "",
                results: newDroppableCalendarMaintenances,
              }),
            );
            // Update calendar maintenances
            let newCalendarMaintenances = [...calendarEvents];
            newCalendarMaintenances.push({
              ...transformMaintenanceIntoEvent(droppedMaintenance),
              planned_start: eventInfo.dateStr,
            });
            dispatch(maintenanceSlice.actions.getCalendarEventsSuccess(newCalendarMaintenances));
            // Update live maintenances
            let maintenances = [...liveMaintenances];
            let _index = maintenances.findIndex((m) => m.id === data.results?.id);
            maintenances.splice(_index, 1, data.results);
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: maintenances,
              }),
            );
            dispatch(dashboardSlice.actions.set_categoriesTypes(maintenances));
            setTimeout(() => {
              setDropLoading(false);
            }, 3000);
          }
        },
      },
      dispatch,
    );
  }

  function onEventClick(eventInfo: EventClickArg) {
    // message.info(`${eventInfo.event.title} was clicked`)
    // eventInfo.event.remove()
  }

  function capitalize(text: string): string {
    if (!text || text == null) {
      return "";
    }
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }

  // Options
  const headerToolbar = {
    left: `prev,next today dayGridMonth,timeGridWeek${
      hasPermission(user.account.permissions, ["manage_wo"]) ? " addMaintenances" : ""
    }`,
    center: "",
    right: "title",
  };

  const localeMap: { [language in Languages]: LocaleInput } = {
    bs: hr,
    de,
    en,
    hr,
    rs,
  };

  if (initialLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spin spinning={initialLoading} />
      </div>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          position: "relative",
          height: "100%",
          minHeight: fromModal ? "80vh" : "unset",
          maxHeight: fromModal ? "80vh" : "unset",
        }}
      >
        {/* Filters */}
        <Filters
          key={`filters-${asset}`}
          filters={filters}
          set_filters={set_filters}
          from={from}
          filtersDisabled={filtersDisabled}
          setFiltersDisabled={setFiltersDisabled}
        />

        {/* Calendar */}
        <Spin
          spinning={
            getCalendarEventsStatus === "loading" ||
            getDroppableCalendarMaintenancesStatus === "loading" ||
            getMaintenanceListStatus === "loading" ||
            updateMaintenanceStatus === "loading" ||
            loading
          }
        >
          <motion.div
            initial={{ width: "100%" }}
            animate={dropEventsVisible ? { width: "calc(100% - 380px)" } : { width: "100%" }}
            transition={{ ease: "anticipate", duration: 0.3 }}
          >
            {/* Calendar */}
            <FullCalendar
              ref={calendarRef}
              loading={(isLoading) => onCalendarLoad(isLoading)}
              locale={localeMap[user.account.language as keyof typeof localeMap]}
              aspectRatio={aspectRatio}
              dayMaxEvents={4}
              selectable={hasPermission(user.account.permissions, ["manage_wo"])}
              selectMirror={true}
              moreLinkClick="popover"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              datesSet={handleDatesSet}
              select={toggleDrawer}
              expandRows
              eventContent={RenderEvent}
              events={events}
              eventResize={onEventResize}
              eventDrop={onEventDrop} // Moving event
              drop={onDrop} // Dropping external event
              eventClick={onEventClick}
              headerToolbar={headerToolbar}
              buttonText={{
                today: capitalize(
                  calendarRef.current?.getApi()?.currentDataManager?.getCurrentData()
                    ?.localeDefaults?.buttonText?.today || "Today",
                ),
                month: capitalize(
                  calendarRef.current?.getApi()?.currentDataManager?.getCurrentData()
                    ?.localeDefaults?.buttonText?.month || "Month",
                ),
                week: capitalize(
                  calendarRef.current?.getApi()?.currentDataManager?.getCurrentData()
                    ?.localeDefaults?.buttonText?.week || "Week",
                ),
              }}
              customButtons={{
                addMaintenances: {
                  text: t(TRANSLATION_KEY.maintenances),
                  click: () => set_dropEventsVisible((previousState) => !previousState),
                },
              }}
            />
          </motion.div>

          {/* Drawer */}
          <Drawer
            title={t(TRANSLATION_KEY.add)}
            placement="right"
            closable={false}
            destroyOnClose={true}
            visible={!!addMaintenance}
            onClose={() => set_addMaintenance(undefined)}
            getContainer={false}
            width={520}
          >
            {/* Drawer component => New maintenance */}
            <MaintenanceForm
              maintenance={addMaintenance}
              onClose={(results) => {
                set_addMaintenance(undefined);
                updateCalendarEvents(results);
              }}
              hh={timeNeeded.hh}
              mm={timeNeeded.mm}
            />
          </Drawer>
        </Spin>
      </div>

      <Drawer
        title={t(TRANSLATION_KEY.scheduleMaintenance)}
        placement="right"
        onClose={() => set_dropEventsVisible(false)}
        visible={dropEventsVisible}
        width={380}
        style={{ position: "absolute", right: -20, height: "100%" }}
        bodyStyle={{ padding: 0 }}
        getContainer={false}
        mask={false}
        closable
      >
        <DropEvents dropEvents={dropEvents} set_dropEventsVisible={set_dropEventsVisible} />
      </Drawer>
    </div>
  );
};

export default CalendarMaintenances;
