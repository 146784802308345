import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../../../hooks";
import Loader from "../../components/Loader";
import { Col, Modal, Row, Table, Typography, message } from "antd";
import AnalyticsCard from "../../../../components/Cards/AnalyticsCard";
import { parseAnalyticsInfo, parseCosts } from "../../../../helpers/functions";
import { Datum, Line, LineConfig } from "@ant-design/charts";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { IApiResponse, ITableColumn } from "../../../../models";
import PreviewButton from "../../../../components/PreviewButton";
import { unstable_batchedUpdates } from "react-dom";
import FiltersWithCustomDatePicker, { IFilters } from "./components/FiltersWithCustomDatePicker";
import api, { failedQueue, isRefreshing } from "../../../../services";
import PartModalPreview from "../../../part/components/PartModalPreview";

type Props = {};

const PartPurchase = (props: Props) => {
  // Variables
  const [loading, set_loading] = useState(false);
  const [selectedPart, set_selectedPart] = useState<number | undefined>(undefined);
  const { user } = useAppSelector((state) => state.userReducer);
  const [graphData, setGraphData] = useState<IPartBuyGraph[]>([]);
  const [data, set_data] = useState<IPartBuyAnalytics>({
    base_price: 0,
    percentage: 0,
    qty: 0,
    parts: [],
  });

  // Methods
  async function getGraphData(filters: IFilters) {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await api.get<IApiResponse<IPartBuyGraph[]>>(
        "/warehouse/analytics/part_buy_graph_analytics",
        {
          headers: { Authorization: "Bearer " + token },
          params: {
            // start: filters.start,
            // end: filters.end,
            part_categories: filters.part_categories.join("|"),
            assets: filters.assets.join("|"),
          },
        },
      );
      if (res.data.results) {
        setGraphData(res.data.results);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getGraphData(filters));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_loading(false);
  }

  async function getData(filters: IFilters) {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await api.get<IApiResponse<IPartBuyAnalytics>>(
        "/warehouse/analytics/part_buy_analytics",
        {
          headers: { Authorization: "Bearer " + token },
          params: {
            start: filters.start,
            end: filters.end,
            part_categories: filters.part_categories.join("|"),
            assets: filters.assets.join("|"),
          },
        },
      );
      if (res.data.results) {
        set_data(res.data.results);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getData(filters));
        }
        return;
      }
      console.log(error);
      message.error(t(TRANSLATION_KEY.errorOnGetData));
    }
    set_loading(false);
  }

  // Chart config
  const lineConfig: LineConfig = {
    data: graphData.map((x) => ({
      ...x,
      date: `${t(x.date || TRANSLATION_KEY.other)}`,
    })),
    xField: "date",
    yField: "price",
    xAxis: {
      type: "category",
    },
    yAxis: {
      label: {
        formatter: (v: string) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    lineStyle: {
      lineWidth: 5,
    },
    tooltip: {
      formatter: (datum: Datum) => {
        return {
          name: t(TRANSLATION_KEY.price),
          value: `${parseCosts(datum.price)} ${user.account.company.currency}`,
        };
      },
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 2000,
      },
      update: {
        animation: "path-in",
        duration: 750,
      },
    },
  };

  let lineChart = useMemo(
    () => (
      <>
        {/* Title */}
        <Typography.Title level={5} style={{ paddingTop: 12 }}>
          {t(TRANSLATION_KEY.purchasedQuantityGraph)}
        </Typography.Title>
        {/* Divider */}
        <div style={{ marginTop: 12, marginBottom: 12 }} />
        {/* Chart */}
        <div style={{ maxHeight: 572 }}>
          <Line {...lineConfig} />
        </div>
      </>
    ),
    [graphData],
  );

  // Columns
  const COLUMNS: ITableColumn<IPartTable>[] = [
    {
      title: t(TRANSLATION_KEY.code),
      dataIndex: "code",
      visible: true,
    },
    {
      title: t(TRANSLATION_KEY.name),
      dataIndex: "name",
      visible: true,
      render: (text: string, value: IPartTable) => {
        return (
          <PreviewButton
            isActive={value.is_active}
            containerStyle={{ minWidth: "unset" }}
            title={value.name}
            id={value.id}
            url={`/app/item-details/${value.id}`}
            onClick={() => {
              unstable_batchedUpdates(() => {
                set_selectedPart(value.id);
              }, []);
            }}
          />
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.qty),
      dataIndex: "qty",
      visible: true,
    },
    {
      title: t(TRANSLATION_KEY.price),
      dataIndex: "base_price",
      visible: true,
      render: (text: string, value: IPartTable) =>
        `${parseCosts(value.base_price.toString()) || 0} ${user.account.company.currency}`,
    },
  ];

  return (
    <div>
      {/* Spinner */}
      <Loader isLoading={loading} />

      {/* Filters */}
      <FiltersWithCustomDatePicker
        onChange={(filters: IFilters) => (getData(filters), getGraphData(filters))}
      />

      {/* Cards */}
      <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }}>
        <Col xs={12} xxl={8}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.totalPurchasedQuantity)}
            value={data?.qty || "0"}
          />
        </Col>
        <Col xs={12} xxl={8}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.totalPurchasedQuantityValue)}
            value={`${parseCosts(data?.base_price?.toString()) || 0} ${
              user.account.company.currency
            }`}
          />
        </Col>
        <Col xs={12} xxl={8}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.purchasedQuantityAgainstTotalQuantity)}
            value={`${parseAnalyticsInfo(data?.percentage?.toString() || "0", 2, true)}%`}
          />
        </Col>
      </Row>

      {/* Chart */}
      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col span={24}>
          <div className="white-container" style={{ height: "100%", maxHeight: 480 }}>
            {lineChart}
          </div>
        </Col>
      </Row>

      {/* Table */}
      <div style={{ marginTop: 24 }}>
        <Table dataSource={data.parts} columns={COLUMNS} />
      </div>

      <Modal
        visible={!!selectedPart}
        onCancel={() => {
          set_selectedPart(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <PartModalPreview user={user} id={selectedPart} />
      </Modal>
    </div>
  );
};

export default PartPurchase;

interface IPartBuyAnalytics {
  qty: number;
  base_price: number;
  percentage: number;
  parts: IPartTable[];
}

interface IPartTable {
  id: number;
  code: string;
  barcode: string | null;
  name: string;
  qty: number;
  is_active: boolean;
  base_price: number;
}

interface IPartBuyGraph {
  date: string;
  price: number;
}
