import { RRuleContextType } from "../useRRule";
import DayFrequency from "./DayFrequency";
import MonthFrequency from "./MonthFrequency";
import WeekFrequency from "./WeekFrequency";
import YearFrequency from "./YearFrequency";

type Props = {
  freq: number;
  rrule: RRuleContextType;
};

const FrequencyHandler = ({ freq, rrule }: Props) => {
  // Handler

  //? 0: "years",
  //? 1: "months",
  //? 2: "weeks",
  //? 3: "days",

  const frequencyHandler = {
    0: <YearFrequency rrule={rrule} />,
    1: <MonthFrequency rrule={rrule} />,
    2: <WeekFrequency rrule={rrule} />,
    3: <DayFrequency rrule={rrule} />,
  };

  return frequencyHandler[freq];
};

export default FrequencyHandler;
