import React, { useEffect, useState } from "react";

// Antd
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  List,
  Modal,
  Skeleton,
  Typography,
  message,
} from "antd";

// Rest
import { IFile } from "../models/user";
import { IMAGE_TYPES, MATERIAL_EXTENSIONS, TRANSLATION_KEY } from "../helpers/consts";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../hooks";
import Upload, { RcFile } from "antd/lib/upload";
import { UploadOutlined } from "@ant-design/icons";
import FilesComponent from "../components/Files";
import { compressImage, debounce } from "../helpers/functions";
import { IMaintenance } from "../models/maintenances";
import Search from "antd/lib/input/Search";
import MaintenanceModalPreview from "../pages/maintenance/components/ModalPreview";
import MaintenanceListItem from "../pages/maintenance/components/MaintenanceListItem";
import {
  confirmOrderXHR,
  getMaintenanceListXHR,
  writeReportXHR,
} from "../store/reducers/maintenance/actionCreator";
import { maintenanceSlice } from "../store/reducers/maintenance";
import { dashboardSlice } from "../store/reducers/dashboard";
import { MAINTENANCES_LIST_LIMIT } from "../pages/maintenances/table";
import { unstable_batchedUpdates } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";

interface IConfirmOrder {
  text: string;
  success: boolean;
  order_info: number;
}

interface IProps {
  close: () => void;
}

interface ILocalFilters {
  limit: number;
  offset: number;
  order_by: string;
  search: string;
}

const AddReportToOrder: React.FC<IProps> = ({ close }) => {
  // Hook
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  // Variables
  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const [modalVisible, set_modalVisible] = useState<number | undefined>(undefined);
  const [orders, set_orders] = useState<IMaintenance[]>([]);
  const [showLoadMore, set_showLoadMore] = useState<boolean>(true);
  const [filters, set_filters] = useState<ILocalFilters>({
    limit: MAINTENANCES_LIST_LIMIT,
    offset: 0,
    order_by: "-created_at",
    search: "",
  });
  const [maintenanecPreviewModalVisible, set_maintenanecPreviewModalVisible] =
    useState<boolean>(false);
  const [selectedMaintenance, set_selectedMaintenance] = useState<string | number | undefined>(
    undefined,
  );
  const { writeReportStatus, getMaintenanceListStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  const getMaintenanceList = (filters: ILocalFilters, mergeData: boolean) => {
    if (!mergeData) {
      set_showLoadMore(true);
    }

    getMaintenanceListXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        loading: "loadingWithoutReports",
        queryParams: {
          order_by: filters.order_by,
          limit: filters.limit,
          offset: filters.offset,
          order__order_number__icontains: filters.search,
          has_report__in: 0,
          description__icontains: filters.search,
          status: "completed,closed",
        },
        mergeData,
        successCallback: (data) => {
          if (data.results && data.results.length < MAINTENANCES_LIST_LIMIT) {
            set_showLoadMore(false);
          }
          if (data.results) {
            unstable_batchedUpdates(() => {
              set_filters({ ...filters, offset: filters.offset + (data.results?.length || 0) });
              if (mergeData) {
                set_orders((prev) => [...prev, ...(data.results || [])]);
              } else {
                set_orders(data.results || []);
              }
            }, []);
          }
        },
      },
      dispatch,
    );
  };

  useEffect(() => {
    getMaintenanceList(filters, false);
  }, []);

  // Methods
  const onFinish = async (values: IConfirmOrder) => {
    if (!modalVisible) return;

    // Form object
    const _form: IConfirmOrder = {
      ...values,
      success: true,
      order_info: +modalVisible || -1,
    };

    // Form data
    let formData = new FormData();
    formData.append("data", JSON.stringify(_form));

    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      // Resolving promise
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    // Call axios after all requests

    writeReportXHR(
      {
        successCallback: (data) => {
          if (data.results) {
            getMaintenanceList({ ...filters, offset: 0 }, false);
            set_modalVisible(undefined);
            form.resetFields();
            set_files([]);
            set_previewFiles([]);
            // close(); ukonjeno da se moze vise naloga dodati izvjestaj
          }
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        body: formData,
      },
      dispatch,
    );
  };

  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  const handleSearch = (value: string) => {
    getMaintenanceList({ ...filters, search: value.toLowerCase(), offset: 0 }, false);
  };

  const setSearch = debounce<typeof handleSearch>(handleSearch, 600);

  return (
    <div id="scrollableDiv" style={{ height: "calc(100vh - 164px)", overflowY: "auto" }}>
      <InfiniteScroll
        dataLength={orders.length}
        next={() => {
          getMaintenanceList(filters, true);
        }}
        hasMore={showLoadMore}
        loader={<Skeleton paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
      >
        <List
          itemLayout="horizontal"
          dataSource={orders}
          loading={getMaintenanceListStatus === "loadingWithoutReports"}
          header={
            <>
              <Search
                placeholder={t(TRANSLATION_KEY.searchOrders)}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: 10, marginTop: -12 }}
                allowClear
                enterButton
              />
            </>
          }
          renderItem={(item) => (
            <MaintenanceListItem
              item={item}
              containerStyle={{ padding: "10px 4px" }}
              onClick={() => set_modalVisible(item.id)}
              buttonText={t(TRANSLATION_KEY.enterReport)}
              onPreview={() => {
                set_maintenanecPreviewModalVisible(true);
                set_selectedMaintenance(item.id);
              }}
            />
          )}
        />
      </InfiniteScroll>

      {/* Modal */}
      <Modal
        visible={!!modalVisible}
        onCancel={() =>{
          
          set_modalVisible(undefined)
          set_files([]);
          set_previewFiles([]);
          form.resetFields();
        }}
        footer={null}
        centered
        width="600px"
        closable={false}
        destroyOnClose
        title={t(TRANSLATION_KEY.addReports)}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="text">
            <Input.TextArea rows={6} />
          </Form.Item>

          <Divider plain>
            <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
          </Divider>

          <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
            <Typography.Title level={5}>{t(TRANSLATION_KEY.uploadedFiles)}</Typography.Title>
            <Upload
              multiple={true}
              accept={MATERIAL_EXTENSIONS}
              beforeUpload={beforeUpload}
              showUploadList={false}
            >
              <Button type="primary" shape="circle" icon={<UploadOutlined />} />
            </Upload>
          </div>

          {/* Files list */}
          <FilesComponent
            files={previewFiles}
            onDelete={onRemove}
            hideEditButton
            size="small"
            layout="list"
          />

          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginTop: 24, float: "right" }}
              loading={writeReportStatus === "loading"}
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Maintenance preview */}
      <Modal
        visible={maintenanecPreviewModalVisible}
        onCancel={() => {
          set_maintenanecPreviewModalVisible(false);
          set_selectedMaintenance(undefined);
        }}
        footer={null}
        centered
        width="800px"
        closable={false}
        destroyOnClose
      >
        <MaintenanceModalPreview id={selectedMaintenance} />
      </Modal>
    </div>
  );
};

export default AddReportToOrder;
