import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  TreeSelect,
  Typography,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { AnimatePresence, motion } from "framer-motion";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import FilesComponent from "../components/Files";
import { UpdateTemplateRRule } from "../components/RRule/types";
import { RRuleContext, RRuleContextProvider, useRRule } from "../components/RRule/useRRule";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../components/SelectTagAdd";
import {
  CUSTOM_FIELD_HALF_KEY,
  IMAGE_TYPES,
  MATERIAL_EXTENSIONS,
  STATUSES,
  TRANSLATION_KEY,
} from "../helpers/consts";
import { compressImage, createTreeSelect, hasPermission } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { EXECUTOR_TYPES, IApiResponse, ILocation } from "../models";
import { ISubAsset } from "../models/asset";
import { PeriodTypes } from "../models/maintenances";
import { ICompanyCustomFields, ICustomValue } from "../models/settings";
import { IFile } from "../models/user";
import { getAssetXHR, getSubAssetsXHR } from "../store/reducers/asstes/actionCreators";
import {
  createTemplateXHR,
  getPotentialExecutorsXHR,
} from "../store/reducers/maintenance/actionCreator";
import { settingsSlice } from "../store/reducers/settings";
import {
  getChecklistsXHR,
  getCompanyCusstomFieldsXHR,
} from "../store/reducers/settings/actionCreator";
import GenerateForCustomFields from "./GenerateForCustomFields";
import { createExecutorsObject, IFormExecutors, IMaintenanceForm } from "./MaintenanceForm";
import SubAssetForm from "./SubAssetForm";
import TemplateRRuleForm from "./TemplateRRuleForm";
import { calculateSecondsFromEveryAndPeriod } from "../components/RRule/utils";
import { DefaultOptionType } from "antd/lib/select";

const { Option, OptGroup } = Select;

export type ITemplateForm = Omit<
  IMaintenanceForm,
  "deadline" | "plannedStart" | "executor_type"
> & {
  template_status: string;
  period: PeriodTypes;
  every: number;
  next_run: string;
  subassets: number[];
} & Partial<UpdateTemplateRRule["recurrence"]>; // TODO: Add RRULE

interface IProps {
  close: () => void;
}

const TemplateFrom: React.FC<IProps> = ({ close }) => {
  const dispatch = useAppDispatch();
  const [addLocationVisible, set_addLocationVisible] = useState<boolean>(false);
  const [files, set_files] = useState<RcFile[]>([]);
  const [previewFiles, set_previewFiles] = useState<IFile[]>([]);
  const { locations, getLocationsStatus, checklists, getChecklistsStatus } = useAppSelector(
    (state) => state.settingsReducer,
  );
  const { getPartnersStatus } = useAppSelector((state) => state.partnerReducer);
  const { getSuppliersStatus } = useAppSelector((state) => state.supplierReducer);
  const { assetList, getAssetStatus, subAssets, getSubAssetsStatus } = useAppSelector(
    (state) => state.assetReducer,
  );
  const [formState, setFormState] = useState<"form" | "rrule">("form");
  const { companyCustomFields } = useAppSelector((state) => state.settingsReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const [addSubAssetVisible, set_addSubAssetVisible] = useState<ISubAsset>();
  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [selectedExecutor, set_selectedExecutor] = useState<string>(); // kada se odabere executor ukoliko je drugog tipa mora se resetirat input i postavit zadnji odabrani
  const [executorType, set_executorType] = useState<EXECUTOR_TYPES>("employee");
  const [assetSelected, set_assetSelected] = useState<number>(0);
  const [supplierEmailVisible, set_supplierEmailVisible] = useState<boolean>(false);
  const [visibleAditionalFields, set_visibleAditionalFields] = useState<boolean>(false);
  const { handleRRuleOnNewTemplate } = useRRule();

  useEffect(() => {
    if (!selectedExecutor) {
      return;
    }
    if (executorType === "employee") {
      form.setFieldsValue({ executors: [selectedExecutor] });
    } else {
      form.setFieldsValue({ executors: selectedExecutor });
    }
  }, [executorType]);

  const {
    maintenancesTypes,
    maintenanceCategories,
    getMaintenancesCategoriesStatus,
    getMaintenancesTypesStatus,
    getPotentialExecutorsStatus,
    potentialExecutors,
    createTemplateStatus,
  } = useAppSelector((state) => state.maintenanceReducer);

  const setPotentialExecutors = (asset_id?: number) => {
    if (!asset_id) {
      return;
    }
    form.setFieldsValue({ executor_type: "employee" });
    getPotentialExecutorsXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          asset: asset_id,
        },
      },
      dispatch,
    );
  };
  useEffect(() => {
    setPotentialExecutors();

    getCompanyCusstomFieldsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data: IApiResponse<ICompanyCustomFields>) => {},
      },
      dispatch,
    );
    getAssetXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        successCallback: (data) => {
          if (!data.results) {
            return;
          }
        },
      },
      dispatch,
    );

    getChecklistsXHR({}, dispatch);
  }, []);

  const onFinish = (
    values: ITemplateForm & { [key: string]: ICustomValue[] } & {
      hh: string | null;
      mm: string | null;
    },
  ) => {
    let formData = new FormData();
    let custom_fields: ICustomValue[] = [];
    Object.keys(values).map((key) => {
      if (key.includes(CUSTOM_FIELD_HALF_KEY)) {
        let tmp = companyCustomFields?.order_custom_fields.find((x) => x.key === key);
        custom_fields.push({
          key: key,
          name: tmp?.name || "",
          value: values[key]?.toString() || "",
        });
      }
    });

    let time_needed: string = `${values.hh || "0"}:${values.mm || "0"}:00`;

    if (createTemplateStatus === "loading") {
      return;
    }

    let formatedData: Omit<ITemplateForm, "executors"> & {
      executors: IFormExecutors | null;
      recurrence: UpdateTemplateRRule["recurrence"] | undefined;
    } = {
      // ako neki property je undefined json stringfy ga ne ubaci u json string stoga moramo provjeriti vrijednosti i postaviti im prazne vrijednosti
      ...values,
      time_needed: !values.mm && !values.hh ? null : time_needed,
      maintenance_type: values.maintenance_type || null,
      maintenance_categories: values.maintenance_categories || [],
      planned_start: values.planned_start || null,
      custom_fields,
      status: values.executors ? "assigned" : values.status === "assigned" ? "open" : values.status,
      subassets: values.subassets || [],
      executors:
        createExecutorsObject(
          executorType,
          values.executors || "",
          emailForm.getFieldValue("sendEmail"),
          emailForm.getFieldValue("emailNote"),
        ) || null,
      location:
        values.location ||
        assetList.find((x) => x.id === +form.getFieldValue("asset"))?.location?.id ||
        null,
      checklist: values.checklist || null,
      recurrence: handleRRuleOnNewTemplate().recurrence || undefined,
    };

    formData.append("data", JSON.stringify(formatedData));

    // Appending materials
    // Image compression function
    async function imageCompress(file: RcFile, callback: () => void) {
      let image: any = file;
      if (IMAGE_TYPES.includes(file.type)) {
        image = await compressImage(file);
      }
      formData.append("files", image);
      callback();
    }

    // Appending and compressing files
    let requests = files.reduce((promiseChain, item) => {
      return promiseChain.then(
        () =>
          new Promise((resolve) => {
            imageCompress(item, resolve);
          }),
      );
    }, Promise.resolve());

    requests.then(() =>
      createTemplateXHR(
        {
          body: formData,
          successCallback: (data) => {
            form.resetFields();
            set_files([]);
            set_previewFiles([]);
            if (data.results) {
              message.success(t(TRANSLATION_KEY.successOnSaveData), 1.5);
            }
          },
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        },
        dispatch,
      ),
    );
  };

  const onRemove = (id: any): void => {
    // Find index
    let index = files.findIndex((x) => x.lastModified === id);
    // Remove from files
    let files_tmp = [...files];
    files_tmp.splice(index, 1);
    set_files(files_tmp);
    // Remove from preview files
    let pfiles_tmp = [...files];
    pfiles_tmp.splice(index, 1);
    const tmpfiles: IFile[] = [];
    pfiles_tmp.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);
  };

  // Materials
  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    // Setting files
    set_files(files);
    // Setting preview files
    const tmpfiles: IFile[] = [];
    files.map((item) => {
      const _file = URL.createObjectURL(item);
      tmpfiles.push({
        id: +item.lastModified,
        name: item.name,
        file: _file,
        extension: item.type.split("/")[1],
      });
    });
    set_previewFiles(tmpfiles);

    return false;
  };

  let showLocationInput = !assetList.find((x) => x.id === +form.getFieldValue("asset"))?.location;

  if (
    getLocationsStatus === "loading" ||
    getMaintenancesCategoriesStatus === "loading" ||
    getMaintenancesTypesStatus === "loading" ||
    getAssetStatus === "loading"
  ) {
    return <Spin />;
  }

  let _hasClients = user?.account?.company?.modules?.filter((x) => x.name === "clients").length > 0;

  const initialValues: any = {
    status: "open",
  };

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {formState === "form" ? (
        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -10 }}
          transition={{ duration: 0.15 }}
          key="form"
        >
          <Modal
            title={t(TRANSLATION_KEY.emailForSendOrderToSupplier)}
            footer={null}
            visible={supplierEmailVisible}
            closable
            onCancel={() => {
              unstable_batchedUpdates(() => {
                set_supplierEmailVisible(false);
              }, []);
            }}
          >
            <Form
              form={emailForm}
              layout="vertical"
              onFinish={() => {
                set_supplierEmailVisible(false);
              }}
            >
              <Form.Item name="sendEmail" label={t(TRANSLATION_KEY.email)}>
                <Input />
              </Form.Item>
              <Form.Item name="emailNote" label={t(TRANSLATION_KEY.note)}>
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={createTemplateStatus === "loading"}
                  htmlType="submit"
                  type="primary"
                >
                  {t(TRANSLATION_KEY.save)}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Form onFinish={onFinish} layout="vertical" form={form} initialValues={initialValues}>
            <Form.Item
              rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
              label={t(TRANSLATION_KEY.asset)}
              name={"asset"}
            >
              <TreeSelect
                showSearch
                filterTreeNode={(search, item: any) => {
                  return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
                onChange={(id: string) => {
                  if (id) {
                    getSubAssetsXHR(
                      {
                        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
                        queryParams: {
                          asset: id,
                        },
                      },
                      dispatch,
                    );
                    let asset = assetList.find((x) => x.id === +id);
                    form.setFieldsValue({
                      location: asset?.location?.id || null,
                    });

                    set_assetSelected(+id);

                    setPotentialExecutors(+form.getFieldValue("asset"));
                  }
                }}
                treeData={createTreeSelect(
                  assetList.map((x) => ({
                    title: x.path,
                    value: x.id.toString(),
                    parent_id: x.parent_id?.toString() || null,
                    disabled: !x.can_report_order,
                  })),
                  null,
                )}
              />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
              label={t(TRANSLATION_KEY.description)}
              name={"description"}
            >
              <Input.TextArea disabled={!assetSelected} rows={3} />
            </Form.Item>

            {/* Removed from the form until further notice */}
            {/* <Row style={{ display: "none" }}>
              <Col span={12} style={{ paddingRight: 3 }}>
                <FormItem label={t(TRANSLATION_KEY.repeatEvery)} style={{ width: "100%" }}>
                  <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                    <Form.Item name={"every"} style={{ width: "100%" }}>
                      <InputNumber
                        addonAfter={
                          <Form.Item noStyle name="period">
                            <Select style={{ minWidth: 90 }}>
                              {TEMPLATE_PERIODS.map((x, index) => (
                                <Select.Option key={index} value={x}>
                                  {t(x)}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        style={{ width: "100%", maxWidth: "180px" }}
                        {...INPUT_NUMBER_FORMATTER}
                      />
                    </Form.Item>
                  </div>
                </FormItem>
              </Col>
              <Col span={12} style={{ paddingLeft: 3 }}>
                <Form.Item label={t(TRANSLATION_KEY.plannedStart)} name={"planned_start"}>
                  <DatePicker
                    format={user.account.date_format || "DD.MM.YYYY - HH:mm"}
                    showTime={true}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row> */}

            <Row>
              <Col span={12} style={{ paddingRight: 3 }}>
                <Form.Item label={t(TRANSLATION_KEY.maintenanceTypes)} name={"maintenance_type"}>
                  <Select
                    disabled={!assetSelected}
                    showSearch
                    filterOption={(input, option: DefaultOptionType | any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {maintenancesTypes.map((x) => (
                      <Select.Option key={x.id} value={x.id}>
                        {t(x.trans_key || x.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t(TRANSLATION_KEY.maintenanceCategory)}
                  name="maintenance_categories"
                >
                  <Select
                    disabled={!assetSelected}
                    mode="multiple"
                    showSearch
                    filterOption={(input, option: DefaultOptionType | any) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {maintenanceCategories.map((x) => (
                      <Select.Option key={x.id} value={x.id}>
                        {t(x.trans_key || x.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={t(TRANSLATION_KEY.timeNeeded)}>
                  <Space>
                    <Form.Item name="hh">
                      <InputNumber style={{ width: 99 }} addonAfter="h" min={0} />
                    </Form.Item>
                    <Form.Item name="mm">
                      <InputNumber style={{ width: 99 }} addonAfter="m" max={59} min={0} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
              <Col style={{ paddingLeft: 3 }} span={12}>
                {showLocationInput && (
                  <Form.Item name={"location"} label={t(TRANSLATION_KEY.location)}>
                    <Select
                      disabled={!assetSelected}
                      allowClear={true}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        if (typeof option?.children === "string") {
                          const str: string = option.children;
                          return str.toLowerCase().includes(input.toLowerCase());
                        }
                        return false;
                      }}
                      showSearch={true}
                      notFoundContent={
                        <ButtonAddSelectTag
                          addingVisible={addLocationVisible}
                          set_addingVisible={set_addLocationVisible}
                        />
                      }
                    >
                      {locations.map((x) => (
                        <Select.Option value={x.id} key={x.id}>
                          {x.name}
                        </Select.Option>
                      ))}
                      <Select.OptGroup
                        label={
                          <ButtonAddSelectTag
                            addingVisible={addLocationVisible}
                            set_addingVisible={set_addLocationVisible}
                          />
                        }
                      />
                    </Select>
                  </Form.Item>
                )}
                <Form.Item name="subassets" label={t(TRANSLATION_KEY.assetMarks)}>
                  <Select
                    allowClear
                    mode="multiple"
                    disabled={!assetSelected}
                    loading={getSubAssetsStatus === "loading"}
                  >
                    {subAssets.map((x) => (
                      <Option key={x.id} value={x.id}>
                        {x.name}
                      </Option>
                    ))}

                    {hasPermission(user?.account?.permissions || [], ["manage_asset"]) && (
                      <Select.OptGroup
                        label={
                          <ButtonAddSelectTag
                            addingVisible={!!addSubAssetVisible}
                            set_addingVisible={() =>
                              set_addSubAssetVisible({
                                id: 0,
                                name: "",
                                asset: { id: 0, name: "" },
                              })
                            }
                          />
                        }
                      />
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label={t(TRANSLATION_KEY.checklist)} name={"checklist"}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (typeof option?.children === "string") {
                        const str: string = option.children;
                        return str.toLowerCase().includes(input.toLowerCase());
                      }
                      return false;
                    }}
                  >
                    {checklists.map((x) => (
                      <Select.Option value={x.id} key={x.id}>
                        {x.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <GenerateForCustomFields
              customFields={companyCustomFields?.order_custom_fields || []}
            />

            <div style={{ display: visibleAditionalFields ? "block" : "none" }}>
              <Spin
                spinning={
                  getPotentialExecutorsStatus === "loading" ||
                  getPartnersStatus === "loading" ||
                  getSuppliersStatus === "loading"
                }
              >
                <Form.Item label={t(TRANSLATION_KEY.executors)} name={"executors"}>
                  <Select
                    allowClear
                    disabled={!assetSelected}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (typeof option?.children === "string") {
                        const str: string = option.children;
                        return str.toLowerCase().includes(input.toLowerCase());
                      }
                      return false;
                    }}
                    showSearch={true}
                    mode={executorType === "employee" ? "multiple" : undefined}
                    onSelect={(val: string) => {
                      let type = val.split("|").pop();
                      unstable_batchedUpdates(() => {
                        set_selectedExecutor(val);
                        if (type === "e") {
                          set_executorType("employee");
                        } else if (type === "s") {
                          set_supplierEmailVisible(true);
                          let email: string =
                            potentialExecutors.suppliers.find((x) => x.id === +val.split("|")[0])
                              ?.email || "";
                          emailForm.setFieldsValue({ sendEmail: email });
                          set_executorType("supplier");
                        } else {
                          set_executorType("partner");
                        }
                      }, []);
                    }}
                  >
                    <OptGroup label={t(TRANSLATION_KEY.employee)}>
                      {potentialExecutors.employees.map((x) => (
                        <Option key={x.id + "|e"} value={x.id + "|e"}>
                          {x.name}
                        </Option>
                      ))}
                    </OptGroup>
                    <OptGroup label={t(TRANSLATION_KEY.supplier)}>
                      {potentialExecutors.suppliers.map((x) => (
                        <Option key={x.id + "|s"} value={x.id + "|s"}>
                          {x.name}
                        </Option>
                      ))}
                    </OptGroup>
                    {/*   <OptGroup label={TRANSLATION_KEY.partner}>
                   {potentialExecutors.partners.map((x) => (
                       <Option key={x.id + "|p"} value={x.id + "|p"}>
                         {x.name}
                       </Option>
                     ))}
                     </OptGroup> */}
                  </Select>
                </Form.Item>
              </Spin>

              <Form.Item name="status" label={t(TRANSLATION_KEY.status)}>
                <Select>
                  {STATUSES.filter((status) => status !== "assigned").map((x) => (
                    <Option key={x} value={x}>
                      {t(x)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <div style={{ height: 24 }}></div>

              <Divider plain>
                <div style={{ opacity: 0.65, fontSize: 12 }}>{t(TRANSLATION_KEY.files)}</div>
              </Divider>

              <div className="spaceBetweenRow" style={{ marginBottom: 24 }}>
                <Typography.Title level={5}>{t(TRANSLATION_KEY.documents)}</Typography.Title>
                <Upload
                  multiple={true}
                  accept={MATERIAL_EXTENSIONS}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                >
                  <Button type="primary" shape="circle" icon={<UploadOutlined />} />
                </Upload>
              </div>

              {/* Files list */}
              <FilesComponent
                files={previewFiles}
                onDelete={onRemove}
                hideEditButton
                size="small"
                layout="list"
              />

              <div style={{ height: 24 }}></div>
            </div>
            <Space>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  loading={createTemplateStatus === "loading"}
                  htmlType="submit"
                  type="primary"
                >
                  {t(TRANSLATION_KEY.save)}
                </Button>
              </Form.Item>
              <Button
                type="default"
                onClick={() => {
                  setFormState("rrule");
                }}
              >
                Dodajte okidač
              </Button>
            </Space>
          </Form>
          <Modal
            visible={!!addSubAssetVisible}
            onCancel={() => {
              set_addSubAssetVisible(undefined);
            }}
            footer={null}
            centered
            width="600px"
            closable
            destroyOnClose
            title={t(TRANSLATION_KEY.addAssetMark)}
          >
            <SubAssetForm
              callback={(id: number) => {
                let tmp: ISubAsset[] = [];
                if (form.getFieldValue("subassets")) {
                  tmp = [...form.getFieldValue("subassets"), id];
                }
                console.log(tmp, "KOVO");
                form.setFieldsValue({ subassets: tmp });
              }}
              asset_id={form.getFieldValue("asset")}
              close={() => set_addSubAssetVisible(undefined)}
              subAsset={addSubAssetVisible}
            />
          </Modal>
          <SelectTagAdd
            title={t(TRANSLATION_KEY.addLocation)}
            url="settings/locations/"
            set_visible={set_addLocationVisible}
            visible={addLocationVisible}
            successCallback={(tag: Tag) => {
              const data = [...locations];
              data.push({ id: tag.id, name: tag.name });
              const resFake: IApiResponse<ILocation[]> = {
                message: "",
                results: data,
              };

              dispatch(settingsSlice.actions.getLocationsSuccess(resFake));

              form.setFieldsValue({ location: tag.id });
            }}
          />
        </motion.div>
      ) : (
        <TemplateRRuleForm setFormState={setFormState} />
      )}
    </AnimatePresence>
  );
};

export default TemplateFrom;
