import { DatePicker, List, message, Space, Typography } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { IMaintenanceDetails, IUpdateMaintenance } from "../../../models/maintenances";
import moment from "moment";
import { hasPermission, sumWorkingTimes } from "../../../helpers/functions";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { updateMaintenanceXHR } from "../../../store/reducers/maintenance/actionCreator";
import DurationInput from "../components/DurationInput";
import { maintenanceSlice } from "../../../store/reducers/maintenance";
import { dashboardSlice } from "../../../store/reducers/dashboard";
import RequirePermission from "../../../components/RequirePermission";

type IProps = {
  maintenanceDetails: IMaintenanceDetails;
  fromModal?: boolean;
};

type Data = {
  label: string;
  value: any;
};

const Times = ({ maintenanceDetails, fromModal = false }: IProps) => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const { user } = useAppSelector((state) => state.userReducer);
  const { liveMaintenances } = useAppSelector((state) => state.maintenanceReducer);
  const [hasManageWOPermission, set_hasManageWOPermission] = useState<boolean>(
    hasPermission(user?.account.permissions || [], ["manage_wo"]),
  );

  const [wt, set_wt] = useState<moment.Duration>(
    sumWorkingTimes(maintenanceDetails.flows.map((x) => x.duration || "0:0:0")),
  );

  useEffect(() => {
    set_wt(sumWorkingTimes(maintenanceDetails.flows.map((x) => x.duration || "0:0:0")));
  }, [maintenanceDetails]);

  // Methods
  function updateMaintenance(data: IUpdateMaintenance) {
    updateMaintenanceXHR(
      {
        body: data,
        id: maintenanceDetails.id,
        errorCallback: (err) => {
          if (err.response.data?.message?.custom_field) {
            message.error(
              t(err.response.data.message.message || "").replace(
                "$_dynamic_column",
                err.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
        successCallback: (data) => {
          if (data.results) {
            let maintenances = [...liveMaintenances];
            let index = maintenances.findIndex((m) => m.id === data.results?.id);
            maintenances.splice(index, 1, data.results);
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: maintenances,
              }),
            );
            dispatch(dashboardSlice.actions.set_categoriesTypes(maintenances));
          }
        },
      },
      dispatch,
    );
  }

  // Data
  const data: Data[] = [
    {
      label: t(TRANSLATION_KEY.plannedStart),
      value: hasManageWOPermission ? (
        <DatePicker
          bordered={false}
          key={`planned_start:${maintenanceDetails.planned_start}`}
          style={{ width: 180 }}
          format={user?.account.date_format || "DD.MM.YYYY - HH:mm"}
          showTime
          allowClear
          onChange={(date, planned_start) => {
            updateMaintenance({
              planned_start: date?.toISOString(true) || null,
            });
          }}
          {...(maintenanceDetails.planned_start && {
            defaultValue: moment(maintenanceDetails.planned_start),
          })}
        />
      ) : maintenanceDetails.planned_start ? (
        moment(maintenanceDetails.planned_start).format()
      ) : (
        "-"
      ),
    },
    {
      label: t(TRANSLATION_KEY.timeNeeded),
      value: (
        <div style={{ padding: "6px 0" }}>
          <DurationInput
            disabled={!hasPermission(user.account.permissions, ["manage_wo", "work_wo"])}
            style={{ maxWidth: 180 }}
            onClick={(val) => {
              updateMaintenance({
                time_needed: val,
              });
            }}
            defaultValue={moment.duration(maintenanceDetails.time_needed)}
          />
        </div>
      ),
    },
    {
      label: t(TRANSLATION_KEY.deadline),
      value: hasManageWOPermission ? (
        <DatePicker
          bordered={false}
          key={`deadline:${maintenanceDetails.deadline}`}
          value={maintenanceDetails.deadline ? moment(maintenanceDetails.deadline) : undefined}
          style={{ width: 180 }}
          format={user?.account.date_format || "DD.MM.YYYY - HH:mm"}
          showTime
          onChange={(date, deadline) => {
            updateMaintenance({
              deadline: date?.toISOString(true),
            });
          }}
        />
      ) : maintenanceDetails.deadline ? (
        moment(maintenanceDetails.deadline).format(
          user?.account.date_format || "DD.MM.YYYY - HH:mm",
        )
      ) : (
        ""
      ),
    },
    {
      label: t(TRANSLATION_KEY.workingTime),
      value: <DurationInput style={{ maxWidth: 180 }} disabled={true} defaultValue={wt} />,
    },
    {
      label: t(TRANSLATION_KEY.createdBy),
      value: (
        <Space>
          <div>{maintenanceDetails.reported_by.name}</div>
          {hasManageWOPermission ? (
            <DatePicker
              bordered={false}
              key={`created_at:${maintenanceDetails.created_at}`}
              defaultValue={moment(maintenanceDetails.created_at)}
              style={{ width: 180 }}
              format={user?.account.date_format || "DD.MM.YYYY - HH:mm"}
              showTime
              allowClear={false}
              onChange={(date, created_at) => {
                updateMaintenance({
                  created_at: date?.toISOString(true),
                });
              }}
            />
          ) : maintenanceDetails.created_at ? (
            moment(maintenanceDetails.created_at).format()
          ) : (
            "-"
          )}
        </Space>
      ),
    },
    {
      label: t(TRANSLATION_KEY.confirmedBy),
      value: (
        <Space style={{ padding: "6px 0" }}>
          <div style={{ marginRight: 0 }}>{maintenanceDetails.closed_by?.name || "-"}</div>
          {maintenanceDetails.closed_at ? (
            <DatePicker
              disabled={!hasPermission(user.account.permissions, ["manage_wo"])}
              bordered={false}
              key={`closed_at:${maintenanceDetails.closed_at}`}
              defaultValue={moment(maintenanceDetails.closed_at)}
              style={{ width: 180 }}
              format={user?.account.date_format || "DD.MM.YYYY - HH:mm"}
              showTime
              allowClear={false}
              onChange={(date, closed_at) => {
                updateMaintenance({
                  closed_at: date?.toISOString(true),
                });
              }}
            />
          ) : (
            "-"
          )}
        </Space>
      ),
    },
  ];

  return (
    <List
      dataSource={data}
      size="small"
      header={
        !fromModal && (
          <Typography.Title level={5} style={{ paddingTop: 4 }}>
            {t(TRANSLATION_KEY.time)}
          </Typography.Title>
        )
      }
      renderItem={(item, index) => (
        <List.Item key={index}>
          {/* Label */}
          <List.Item.Meta title={item.label} />
          {/* Value */}
          <div>{item.value}</div>
        </List.Item>
      )}
    />
  );
};

export default Times;
