import React from "react";
import { Button, Divider, List, message, Space, Tag, Typography } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { IPartStorage } from "../../../models/parts";
import { openBase64PDF, parseCosts, parseDecimalDots } from "../../../helpers/functions";
import { IUser } from "../../../models/user";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { useAppSelector } from "../../../hooks";
import { PrinterOutlined } from "@ant-design/icons";
import { IApiResponse } from "../../../models";
import api from "../../../services";
import { useParams } from "react-router-dom";

type IProps = {
  data: IPartStorage[];
  onClick: (item: IPartStorage) => void;
  user: IUser | null;
};

const WarehouseList = ({ data, onClick }: IProps) => {
  const { user } = useAppSelector((state: RootState) => state.userReducer);
  const { id } = useParams();
  const [pdfLoading, setPdfLoading] = React.useState<number>();

  async function handleGetPDF(storage_id: number, part_id: string) {
    const token = await localStorage.getItem("token");
    try {
      setPdfLoading(storage_id);
      let response = await api.post<{ uid: string }>(
        `/warehouse/part_card_export/`,
        {
          storage: storage_id,
          part: part_id,
        },
        { headers: { Authorization: "Bearer " + token } },
      );
      if (response.data) {
        try {
          let res2 = await api.get<IApiResponse<{ file_content: string }>>(`/settings/get_file/`, {
            params: {
              uid: response.data.uid,
            },
            headers: { Authorization: "Bearer " + token },
          });

          openBase64PDF(res2.data.results?.file_content, "", () => setPdfLoading(undefined));
        } catch (error) {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
          setPdfLoading(undefined);
        }
      }
    } catch (error: any) {
      message.error(t(TRANSLATION_KEY.errorOnSaveData));
      setPdfLoading(undefined);
    }
  }

  return (
    <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
      {/* Title */}
      <Typography.Title level={5} style={{ paddingTop: 14 }}>
        {t(TRANSLATION_KEY.warehouse)}
      </Typography.Title>

      {/* Divider */}
      <Divider />

      {/* List */}
      <List
        dataSource={data}
        renderItem={(item: IPartStorage) => (
          <List.Item
            style={{ padding: 6 }}
            extra={
              <Space>
                <Button type="link" onClick={() => onClick(item)}>
                  {t(TRANSLATION_KEY.spend)}
                </Button>
                <Button
                  loading={pdfLoading === item.storage.id}
                  type="link"
                  onClick={() => {
                    if (!id) {
                      message.error(t(TRANSLATION_KEY.errorOnSaveData));
                      return;
                    }
                    handleGetPDF(item.storage.id, id);
                  }}
                >
                  <PrinterOutlined
                    style={{
                      fontSize: 20,
                    }}
                  />
                </Button>
              </Space>
            }
          >
            <List.Item.Meta
              title={
                <Space direction="vertical">
                  <Space>
                    <Typography.Text strong>#{item.storage?.custom_id || ""}</Typography.Text>
                    <Typography.Text type="secondary">{item.storage.path}</Typography.Text>
                  </Space>
                  <Space size={32}>
                    <Typography.Text strong>{item.storage.name}</Typography.Text>
                    <Space>
                      <Tag className="tag-chip">
                        {`${parseDecimalDots(item.qty)} ${t(item.uom)}`}
                      </Tag>
                      <Tag className="tag-chip">
                        {`${parseCosts(item.storage_total_price)} ${
                          user?.account.company.currency
                        }`}
                      </Tag>
                    </Space>
                  </Space>
                </Space>
              }
              description={item.storage.location?.name || undefined}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default WarehouseList;
