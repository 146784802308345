import { Radio, RadioChangeEvent, Select, Space, TimePicker } from "antd";
import moment from "moment";
import { useState } from "react";
import { DAYS_IN_MONTH, INITIAL_TIMESTAMP, RRULE_TIME_FORMAT } from "../constants";
import { calculateHoursFromTimeString, convertHoursToMoment } from "../utils";
import { RRuleContextType } from "../useRRule";

export type MonthlyRuleType = "SpecificDate" | "WeeklyDate";

interface IProps {
  rrule: RRuleContextType;
}

const MonthFrequency = ({ rrule }: IProps) => {
  // Constants
  const pickerWidth: number = 90;
  const pickerHeight: number = 32;

  // Variables
  // const initialLocalRule =
  //   (meta.rule_type as MonthlyRuleType) || rrule.rulesets[0].options.bynweekday
  //     ? "WeeklyDate"
  //     : "SpecificDate";
  const [localRule, setLocalRule] = useState<MonthlyRuleType>("SpecificDate");
  const [localTimestamps, setLocalTimestamps] = useState<{ [Rule in MonthlyRuleType]: string }>({
    SpecificDate:
      rrule.timestamps && rrule.timestamps.length > 0
        ? convertHoursToMoment(rrule.timestamps[0] as number).format(RRULE_TIME_FORMAT)
        : INITIAL_TIMESTAMP,
    WeeklyDate: INITIAL_TIMESTAMP,
  });

  const handleTimepickerChange = (
    date: moment.Moment | null,
    dateString: string,
    rule: MonthlyRuleType,
  ) => {
    if (date) {
      rrule.setTime(dateString, 0);
      rrule.monthlySetup({ type: rule });
      setLocalRule(rule);
      updateTimestamps(rule, dateString);
    }
  };

  const updateTimestamps = (rule: MonthlyRuleType, value?: string) => {
    if (rule === "SpecificDate") {
      setLocalTimestamps((prevValue) => ({
        SpecificDate: value || prevValue.SpecificDate,
        WeeklyDate: INITIAL_TIMESTAMP,
      }));
    } else {
      setLocalTimestamps((prevValue) => ({
        SpecificDate: INITIAL_TIMESTAMP,
        WeeklyDate: value || prevValue.WeeklyDate,
      }));
    }
  };

  const onRuleChange = (e: RadioChangeEvent) => {
    let newValue: MonthlyRuleType = e.target.value;
    setLocalTimestamps({
      SpecificDate: INITIAL_TIMESTAMP,
      WeeklyDate: INITIAL_TIMESTAMP,
    });
    rrule.monthlySetup({
      type: newValue,
      byhour: calculateHoursFromTimeString(INITIAL_TIMESTAMP),
    });
    handleRuleChange(newValue);
  };

  const handleRuleChange = (rule: MonthlyRuleType) => {
    setLocalRule(rule);
  };

  return (
    <Space
      align="center"
      direction="vertical"
      style={{ paddingInline: 8, paddingBottom: 8, paddingTop: 4 }}
    >
      <Radio.Group onChange={onRuleChange} value={localRule}>
        <Space direction="vertical">
          {/* Top local rule */}
          <Space align="center" style={{ height: pickerHeight }}>
            {/* Current rule indicator - //! Deprecated */}
            {/* <Radio value="SpecificDate"></Radio> */}
            {/* Days in month picker */}
            <Select
              onChange={(bymonthday) => {
                rrule.monthlySetup({
                  type: "SpecificDate",
                  bymonthday,
                  byhour: calculateHoursFromTimeString(localTimestamps.SpecificDate),
                });
                handleRuleChange("SpecificDate");
                updateTimestamps("SpecificDate");
              }}
              value={rrule.rulesets[0].options.bymonthday[0] || DAYS_IN_MONTH[0]}
              defaultValue={DAYS_IN_MONTH[0]}
              style={{ width: 72 }}
            >
              {DAYS_IN_MONTH.map((day) => (
                <Select.Option key={day} value={day}>
                  {day}
                </Select.Option>
              ))}
            </Select>
            {/* Timestamp picker */}
            <TimePicker
              format={RRULE_TIME_FORMAT}
              allowClear={false}
              showMinute={false}
              value={convertHoursToMoment(
                calculateHoursFromTimeString(localTimestamps.SpecificDate),
              )}
              onChange={(date, dateString) =>
                handleTimepickerChange(date, dateString, "SpecificDate")
              }
              style={{
                minWidth: pickerWidth,
                maxWidth: pickerWidth,
                height: pickerHeight,
                backgroundColor: "#f0f0f0",
                borderRadius: "40px",
              }}
            />
          </Space>

          {/* Bottom local rule -//! Deprecated */}
          {/* <Space align="center" style={{ height: pickerHeight }}>
            <Radio value="WeeklyDate"></Radio>
            <Select
              onChange={(nth) => {
                rrule.setWeekdayNth(nth);
                rrule.monthlySetup({
                  type: "WeeklyDate",
                  nth,
                  byhour: calculateHoursFromTimeString(localTimestamps.WeeklyDate),
                });
                handleRuleChange("WeeklyDate");
                updateTimestamps("WeeklyDate");
              }}
              value={rrule.weekdayNth}
              style={{ width: 72 }}
            >
              {WEEKS_IN_MONTH.map((week) => (
                <Select.Option key={week} value={week}>
                  {week}
                </Select.Option>
              ))}
            </Select>
            <Select
              onChange={(byweekday) => {
                rrule.setWeekdayDay(byweekday);
                rrule.monthlySetup({
                  type: "WeeklyDate",
                  byweekday,
                  byhour: calculateHoursFromTimeString(localTimestamps.WeeklyDate),
                });
                handleRuleChange("WeeklyDate");
                updateTimestamps("WeeklyDate");
              }}
              value={rrule.weekdayDay}
              style={{ width: 140 }}
            >
              {moment.weekdays(true).map((day, index) => (
                <Select.Option key={day} value={index}>
                  {day}
                </Select.Option>
              ))}
            </Select>
            <TimePicker
              format={RRULE_TIME_FORMAT}
              allowClear={false}
              showMinute={false}
              value={convertHoursToMoment(calculateHoursFromTimeString(localTimestamps.WeeklyDate))}
              onChange={(date, dateString) =>
                handleTimepickerChange(date, dateString, "WeeklyDate")
              }
              style={{
                minWidth: pickerWidth,
                maxWidth: pickerWidth,
                height: pickerHeight,
                backgroundColor: "#f0f0f0",
                borderRadius: "40px",
              }}
            />
          </Space> */}
        </Space>
      </Radio.Group>
    </Space>
  );
};

export default MonthFrequency;
