import { TimePicker, TimePickerProps } from "antd";
import { motion } from "framer-motion";
import { RRULE_TIME_FORMAT } from "../constants";
import { convertHoursToMoment } from "../utils";

type Props = {
  value: number | null | undefined;
  onChange: (date: moment.Moment | null, dateString: string) => void;
  timePickerProps?: TimePickerProps;
};

const TimePickerChip = ({ value, onChange, timePickerProps }: Props) => {
  // Constants
  const pickerWidth: number = 90;
  const pickerHeight: number = 32;

  const timePickerValue = value != undefined ? convertHoursToMoment(value) : null;

  return (
    <motion.div
      initial={{ opacity: 0, width: 0 }}
      animate={{ opacity: 1, width: pickerWidth + 12 }}
      exit={{ opacity: 0, scale: 0, width: 0 }}
      transition={{ ease: "anticipate" }}
      style={{
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
        height: pickerHeight + 12,
      }}
    >
      <TimePicker
        format={RRULE_TIME_FORMAT}
        showMinute={false}
        value={timePickerValue}
        onChange={onChange}
        style={{
          minWidth: pickerWidth,
          maxWidth: pickerWidth,
          height: pickerHeight,
          backgroundColor: "#f0f0f0",
          borderRadius: "40px",
        }}
        {...timePickerProps}
      />
    </motion.div>
  );
};

export default TimePickerChip;
