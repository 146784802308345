import { Button, Space } from "antd";
import { motion } from "framer-motion";
import React from "react";
import RRuleComponent from "../components/RRule";

type Props = {
  setFormState: React.Dispatch<React.SetStateAction<"form" | "rrule">>;
};

const TemplateRRuleForm = ({ setFormState }: Props) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 10 }}
      transition={{ duration: 0.15 }}
    >
      <Space direction="vertical">
        <RRuleComponent template={undefined} />
        <Button style={{ marginTop: 24 }} onClick={() => setFormState("form")}>
          Natrag na formu
        </Button>
      </Space>
    </motion.div>
  );
};

export default TemplateRRuleForm;
