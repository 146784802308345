import React from "react";
import { Button, Space, Typography } from "antd";
import { motion } from "framer-motion";
import { t } from "i18next";
import { IMaintenance } from "../../../../models/maintenances";
import PreviewButton from "../../../../components/PreviewButton";

type IProps = {
  item: IMaintenance;
  containerStyle?: React.CSSProperties;
  buttonText?: string;
  onClick?: () => any;
  onPreview?: (id: number) => any;
};

const MaintenanceListItem = ({ item, containerStyle, buttonText = "Click", onPreview }: IProps) => {
  return (
    <motion.div
      whileHover={{ backgroundColor: "#efefef" }}
      style={{
        backgroundColor: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        borderRadius: 4,
        ...containerStyle,
      }}
    >
      <div className="spaceBetweenRow" style={{ alignItems: "flex-start" }}>
        <div>
          {/* Order number and maintenance type */}
          <Space>
            <Typography.Text strong style={{ width: 32 }}>
              #{item.order_number}
            </Typography.Text>
            {item.maintenance_type ? (
              <Typography.Text type="secondary">
                {t(item.maintenance_type.name) || ""}
              </Typography.Text>
            ) : null}
            {item.location ? (
              <Typography.Text type="secondary">{item.location.name}</Typography.Text>
            ) : null}
          </Space>
          <div className="spaceBetweenRow" style={{ minWidth: 450 }}>
            {/* Name */}
            {onPreview ? (
              <PreviewButton
                isActive={item.is_active}
                title={item.description}
                id={item.id}
                url={`/app/maintenances/${item.id}/`}
                onClick={() => onPreview(item.id)}
                containerStyle={{ minWidth: "unset", maxWidth: "70%" }}
                linkStyle={{
                  fontSize: 16,
                  fontWeight: 500,
                  whiteSpace: "wrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
            ) : (
              <Typography.Text strong style={{ marginBottom: 12, maxWidth: "70%" }}>
                {item.description}
              </Typography.Text>
            )}
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
          }} >
            {/* <SettingTwoTone  twoToneColor="#2d3b51" style={{ marginTop: 4 }} /> */}
            <Space direction="vertical" size={0}>
              <Typography.Text style={{ fontSize: 14, marginBottom: -6 }}>
                {item.asset?.path || item.asset?.name}
              </Typography.Text>
            </Space>
          </div>
        </div>
        {/* Additional informations */}
        <Space
          direction="vertical"
          align="center"
          style={{
            maxWidth: 200,
          }}
          size={0}
        >
          <Typography.Title
            level={5}
            style={{ fontSize: 14, marginBottom: 0, marginRight: 16, textAlign: "right" }}
          >
            {t(item.held_reason?.trans_key || item.held_reason?.name || "")}
          </Typography.Title>
   
            <Typography.Text
              style={{
                fontSize: 14,
                marginBottom: 0,
                marginRight: 16,
                textAlign: "right",
                maxWidth: 200,
              }}
            >
              {item.held_reason?.reason_text  || ""}
            </Typography.Text>
        </Space>
      </div>
    </motion.div>
  );
};

export default MaintenanceListItem;
