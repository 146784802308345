import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";

export type SortValue =  "-created_at" | "planned_start" | "-status_change_ts"

interface IProps {
  onChange: ({ order_by }: { order_by: SortValue }) => void;
  value: string | undefined;
  ifValueUndefined: SortValue;
}

const DATA = [
  {
    text: "createdDateNewest",
    order_by: "-created_at" as SortValue,
  },
  {
    text: "plannedStartDateOldest",
    order_by: "planned_start" as SortValue,
  },
  {
    text: "lastUpdatedNewest",
    order_by: "-status_change_ts" as SortValue,
  },
];

let FONT_SIZE = 21;
let BOTTOM = 4;
let RIGHT = 3;

const SorterButton: React.FC<IProps> = ({ onChange, value, ifValueUndefined }) => {
  let tmpLocalVal = DATA.find((x) => x.order_by === value);

  useEffect(() => {
    set_localVal({
      order_by: tmpLocalVal?.order_by || ifValueUndefined,
    });
  }, [value]);

  const [localVal, set_localVal] = React.useState<{
    order_by: string;
  }>({
    order_by: tmpLocalVal?.order_by || ifValueUndefined,
  });

  return (
    <Select
      suffixIcon={
        localVal.order_by.includes("-") ? (
          <SortDescendingOutlined
            style={{
              fontSize: FONT_SIZE,
              color: "black",
              position: "relative",
              bottom: BOTTOM,
              right: RIGHT,
            }}
          />
        ) : (
          <SortAscendingOutlined
            style={{
              fontSize: FONT_SIZE,
              color: "black",
              position: "relative",
              bottom: BOTTOM,
              right: RIGHT,
            }}
          />
        )
      }
      value={localVal.order_by || "-created_at"}
      style={{ width: 240, marginLeft: 9, marginRight: 9 }}
      onChange={(val) => {
        let tmp = DATA.find((x) => x.order_by === val);
        set_localVal({
          order_by: tmp?.order_by || ifValueUndefined,
        });
        onChange({
          order_by: tmp?.order_by || ifValueUndefined,
        });
      }}
    >
      {DATA.map((item) => {
        return (
          <Select.Option key={item.order_by} value={item.order_by}>
            {t(item.text)}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default SorterButton;
