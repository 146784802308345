import { message, Spin, Typography } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { parseCosts } from "../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { IUser } from "../../../../models/user";
import { RootState } from "../../../../store";
import { getWorkCostsXHR } from "../../../../store/reducers/maintenance/actionCreator";

interface IProps {
  sumMaterialCosts: number;
  user: IUser | null;
}

const WorkCosts: React.FC<IProps> = ({ sumMaterialCosts }) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { id } = useParams();

  // Variables
  const { getWorkCostsStatus, workCosts } = useAppSelector((state) => state.maintenanceReducer);
  const { user } = useAppSelector((state: RootState) => state.userReducer);

  // Methods
  useEffect(() => {
    getWorkCostsXHR(
      {
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        id: id,
      },
      dispatch,
    );
  }, [id]);

  let sumWorkingCosts = 0;

  workCosts.forEach((x) => {
    sumWorkingCosts += x.work_costs ? +x.work_costs : 0;
  });

  return (
    <div className="white-container" style={{ paddingTop: 10, paddingBottom: 10 }}>
      <Spin spinning={getWorkCostsStatus === "loading"}>
        <div className="spaceBetweenRow">
          <div>
            <Typography.Text style={{ opacity: 0.75 }}>{t(TRANSLATION_KEY.sum)}: </Typography.Text>
            <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
              {parseCosts((sumWorkingCosts + sumMaterialCosts).toString())}{" "}
              {user?.account.company.currency}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text style={{ opacity: 0.75 }}>
              {t(TRANSLATION_KEY.materialCosts)}:{" "}
            </Typography.Text>
            <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
              {parseCosts(sumMaterialCosts.toString())} {user?.account.company.currency}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text style={{ opacity: 0.75 }}>
              {t(TRANSLATION_KEY.workingCosts)}:{" "}
            </Typography.Text>
            <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
              {parseCosts(sumWorkingCosts.toString())} {user?.account.company.currency}
            </Typography.Text>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default WorkCosts;
